<template>
  <Common
    :from="type"
    :query1="query1"
    :query2="query2"
    :read-num-list="readNumList"
    @createRandom="createRandom"
    @getAsyncWechatContent="getAsyncWechatContent"
    @handleImageUrl="handleImageUrl"
    @setFixedInfo="setFixedInfo"
  />
</template>

<script>
  import mixins from './mixins'
  import { infoDetail } from '@/api/information'
  export default {
    mixins: [mixins],
    data() {
      return {
        type: 'edit',
      }
    },
    created() {
      if (this.$route.query.content_id) this.getInfoDetail()
    },
    methods: {
      async getInfoDetail() {
        const params = {
          id: this.$route.query.content_id,
        }
        const res = await infoDetail(params)
        if (res.success) {
          // query1
          for (let key of Object.keys(this.query1)) {
            if (key == 'publish_time') {
              this.query1[key] = res.data[key] * 1000
            } else {
              this.query1[key] = res.data[key]
            }
          }
        }
      },
      setFixedInfo(val) {
        this.query2.fixedInfo = val
      },
    },
  }
</script>
