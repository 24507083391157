var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "push-error-list-container" },
    [
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: { handleCurrentChange: _vm.handleCurrentChange },
        scopedSlots: _vm._u([
          {
            key: "create_time",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("dateFormat")(row.create_time, true))),
                ]),
              ]
            },
          },
          {
            key: "error_des",
            fn: function (ref) {
              var row = ref.row
              return [
                row.error_code
                  ? _c("span", [_vm._v(_vm._s(row.error_des))])
                  : _c("span", [_vm._v(_vm._s(row.body))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }