<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <template v-for="(item, index) in list">
      <div v-if="item.isShow" :key="index" class="preview-item">
        <el-image
          class="preview-item-code-img"
          :src="baseURL + previewItem[item.codeUrl]"
        />
        <div class="preview-item-info">
          <p class="preview-item-title">{{ item.title }}</p>
          <p class="preview-item-note">{{ item.note }}</p>
          <div v-if="item.setLink == 'app'">
            <label class="preview-item-label">强制用户登录查看</label>
            <el-radio-group v-model="need_login">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="-1">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="preview-item-btn">
          <el-button type="primary" @click="handleConfirm(item)">
            立即使用
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { baseURL } from '@/config'
  export default {
    data() {
      return {
        title: null,
        dialogVisible: false,
        defaultActive: '',
        baseURL: baseURL,
        need_login: 1,
        previewItem: {},
      }
    },
    computed: {
      list() {
        return [
          {
            codeUrl: 'link_app_qrcode',
            setLink: 'app',
            linkName: 'link_app',
            title: '原生页面（客户端使用）',
            note: '4.3+版本支持，使用客户端扫码预览',
            isShow:
              this.defaultActive != 'component-video-column' ||
              (this.defaultActive == 'component-video-column' &&
                this.previewItem.type == 2),
          },
          {
            codeUrl: 'link_app_qrcode',
            setLink: 'app',
            linkName: 'link_app',
            title: '原生页面（客户端使用，单列样式）',
            note: '4.3+版本支持，使用客户端扫码预览',
            isShow:
              this.defaultActive == 'component-video-column' &&
              this.previewItem.type != 2 &&
              this.previewItem.link_app_qrcode,
          },
          {
            codeUrl: 'link_app_qrcode_two',
            setLink: 'app_two',
            linkName: 'link_app_two',
            title: '原生页面（客户端使用，双列样式）',
            note: '4.3+版本支持，使用客户端扫码预览',
            isShow:
              this.defaultActive == 'component-video-column' &&
              this.previewItem.type != 2 &&
              this.previewItem.link_app_qrcode_two,
          },
          {
            codeUrl: 'link_wx_qrcode',
            setLink: 'wx',
            linkName: 'link_wx',
            title: 'WAP页面（微信或者浏览器中使用）',
            note: '使用微信扫码预览，完整WAP功能需要授权使用',
            isShow: this.previewItem.link_wx_qrcode,
          },
        ]
      },
    },
    methods: {
      handleOpen(title, item, defaultActive) {
        this.title = title
        this.defaultActive = defaultActive
        this.previewItem = item
        this.dialogVisible = true
      },
      handleClose(done) {
        done()
        setTimeout(() => {
          this.need_login = 1
        }, 500)
      },
      handleConfirm(item) {
        let link = this.previewItem[item.linkName]
        if (this.need_login == 1) {
          link = `${link}${link.indexOf('?') == -1 ? '?' : '&'}needlogin=1`
        }
        this.$emit('returnAddress', link)
        this.dialogVisible = false
        setTimeout(() => {
          this.need_login = 1
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.preview-item';
  ::v-deep .el-dialog__title {
    width: 95%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #{$base} {
    display: flex;
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid #dcdfe6;
    &-info {
      margin-left: 20px;
      flex: 1;
    }
    &-code-img {
      width: 150px;
    }
    &-title {
      line-height: 24px;
      font-weight: bold;
      font-size: 16px;
    }
    &-note {
      color: $base-color-text-secondary;
    }
    &-btn {
      line-height: 150px;
    }
    &-label {
      margin-right: 20px;
    }
  }
</style>
