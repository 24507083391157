var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-show-column-container" },
    [
      _c("div", { staticClass: "add-show-column-tips" }, [
        _vm._v("配置内容的来源和规则。"),
      ]),
      _c("div", { staticClass: "add-show-column-title" }, [_vm._v("基础配置")]),
      _c("Form", {
        attrs: { column: _vm.column1, query: _vm.query1, "ref-name": "basic" },
      }),
      _c("el-divider"),
      _c("div", { staticClass: "add-show-column-title" }, [_vm._v("内容对接")]),
      _c("Form", {
        attrs: { column: _vm.column2, query: _vm.query2, "ref-name": "basic" },
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "add-show-column-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { attrs: { size: "medium" } }, [_vm._v("取消")]),
          _vm.$route.query.id
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "danger" },
                  on: { click: _vm.onDelete },
                },
                [_vm._v(" 删除 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "信息", visible: _vm.dialogVisible, width: "20%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否确认删除该信息流？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }