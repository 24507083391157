<template>
  <div class="tab-info">
    <el-button class="call" @click="viewCall">调用链接</el-button>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="内容管理" name="1">
        <Manage :detail-info="detailInfo" />
      </el-tab-pane>
      <!-- <el-tab-pane label="栏目配置" name="2">
        <Common :query1="query1" :query2="query2" />
      </el-tab-pane> -->
    </el-tabs>
    <ViewCallDialog ref="viewCall" />
  </div>
</template>

<script>
  import mixins from './mixins'
  import { mapActions } from 'vuex'
  import { getConfigDetail } from '@/api/information'
  export default {
    mixins: [mixins],
    created() {
      this.handleQuery()
      if (this.$route.query.id) this.getConfigDetail()
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        changeMenuMeta: 'routes/changeMenuMeta',
      }),
      handleQuery() {
        let title = this.$route.query.title
        this.$nextTick(() => {
          this.changeTabsMeta({
            title: 'Show',
            meta: {
              title: title,
            },
          })
          this.changeMenuMeta({
            name: 'Show',
            meta: {
              title: title,
            },
          })
        })
      },
      // 获取信息数据
      getConfigDetail() {
        let params = { id: this.$route.query.id }
        getConfigDetail(params).then((res) => {
          // query1
          this.query1.name = res.data.name
          this.query1.type = res.data.type
          this.query1.show_style = res.data.config.show_style
          this.query1.pintu_type = res.data.config.pintu_type
          this.query1.comment_type = res.data.config.comment_type
          this.query1.sort_type = res.data.sort_type
          this.query1.sort_max_size = res.data.sort_max_size
          // query2
          for (let key of Object.keys(res.data.config)) {
            for (let key2 of Object.keys(this.query2)) {
              if (key2 === key) {
                this.query2[key2] = res.data.config[key]
              }
            }
          }
          this.query2.show_from = res.data.config.show_from
            ? res.data.config.show_from.split(',').map((item) => Number(item))
            : []
        })
      },
    },
  }
</script>
