const config = {
  dialogVisible: false,
  forumList: [],
  showList: [],
  // 付费模式
  paymentOption: Object.freeze([
    {
      id: 1,
      label: '免费',
    },
    {
      id: 2,
      label: '付费（会员免费）',
    },
    {
      id: 3,
      label: '付费（全部用户都需要付费）',
    },
    {
      id: 4,
      label: '付费（仅限会员观看）',
    },
  ]),
  // 视频上传形式
  videoUploadOption: Object.freeze([
    {
      id: 2,
      label: 'MP4',
    },
    {
      id: 6,
      label: '本地上传',
    },
    {
      id: 3,
      label: 'm3u8',
    },
    {
      id: 4,
      label: 'MP3音频',
      status: '1',
    },
    {
      id: 1,
      label: 'iframe',
      status: '1',
    },
    {
      id: 5,
      label: '自定义',
      status: '1',
    },
  ]),
  // 视频上传形式
  videoUploadOption1: Object.freeze([
    {
      id: 2,
      label: 'MP4',
    },
    {
      id: 6,
      label: '本地上传',
    },
  ]),
  // 视频板式
  videoStyleOption: Object.freeze([
    {
      id: 1,
      label: '横版',
    },
    {
      id: 2,
      label: '竖版',
      status: '1',
    },
  ]),
  // 专辑
  albumOption: [],
  // 栏目
  columnOption: [],
  // 是否
  whetherList1: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 2,
      label: '否',
    },
  ]),
  // 是否
  whetherList2: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  // 是否
  whetherList3: Object.freeze([
    {
      id: 1,
      label: '开启',
    },
    {
      id: -1,
      label: '关闭',
    },
  ]),
}

export default config
