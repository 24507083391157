var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.translateTitle("回复"),
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("Form", {
        ref: "replyForm",
        attrs: { column: _vm.column, query: _vm.query, "ref-name": "reply" },
        scopedSlots: _vm._u([
          {
            key: "vestUser",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "vest", on: { click: _vm.handleVestList } },
                  [
                    _c("img", {
                      staticClass: "head",
                      attrs: { alt: "", src: _vm.userInfo.head },
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.userInfo.name))]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveReply } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("确定")) + " ")]
          ),
        ],
        1
      ),
      _c("VestList", { ref: "vestList", on: { setUserInfo: _vm.setUserInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }