import Layout from '@/vab/layouts'

const operate = {
  path: '/operate',
  name: 'Operate',
  component: Layout,
  meta: {
    title: '运营',
    icon: 'funds-box-line',
  },
  children: [
    {
      path: 'activity',
      name: 'activity',
      meta: {
        title: '活动',
        // icon: 'computer-fill',
      },
      children: [
        {
          path: 'activityList',
          name: 'ActivityList',
          component: () => import('@/views/operate/activityList'),
          meta: {
            title: '活动列表',
          },
        },
        {
          path: 'addActivity',
          name: 'AddActivity',
          component: () =>
            import('@/views/operate/activityList/components/add'),
          meta: {
            hidden: true,
            title: '发布活动',
          },
        },
        {
          path: 'editActivity',
          name: 'EditActivity',
          component: () =>
            import('@/views/operate/activityList/components/edit'),
          meta: {
            hidden: true,
            title: '编辑活动',
          },
        },
        {
          path: 'manageActivity',
          name: 'ManageActivity',
          component: () => import('@/views/operate/activityList/manage'),
          meta: {
            hidden: true,
            title: '活动管理',
          },
        },
      ],
    },
    {
      path: 'advertisingCenter',
      name: 'AdvertisingCenter',
      meta: {
        title: '广告中心',
        // icon: 'computer-fill',
      },
      children: [
        {
          path: 'systemAdvertising',
          name: 'SystemAdvertising',
          component: () => import('@/views/operate/systemAdvertising'),
          meta: {
            title: '系统广告',
          },
        },
        {
          path: 'customAdvertising',
          name: 'CustomAdvertising',
          component: () => import('@/views/operate/customAdvertising'),
          meta: {
            hidden: true,
            title: '自定义广告',
          },
        },
      ],
    },
    {
      path: 'messagePush',
      name: 'MessagePush',
      meta: {
        title: '消息/推送',
        hidden: true,
        // icon: 'computer-fill',
      },
      children: [
        {
          path: 'massPush',
          name: 'MassPush',
          component: () => import('@/views/operate/messagePush/massPush'),
          meta: {
            hidden: true,
            title: '推送中心',
          },
        },
        {
          path: 'communityAssistant',
          name: 'CommunityAssistant',
          component: () =>
            import('@/views/operate/messagePush/communityAssistant'),
          meta: {
            title: '社区助手',
          },
        },
        {
          path: 'assistantManage',
          name: 'AssistantManage',
          component: () =>
            import('@/views/operate/messagePush/communityAssistant/manage'),
          meta: {
            hidden: true,
            title: '社区助手-管理',
          },
        },
      ],
    },
    {
      path: 'version',
      name: 'Version',
      component: () => import('@/views/operate/customAdvertising/version'),
      meta: {
        hidden: true,
        title: '图片轮播-4.3版本之前首页',
      },
    },
    {
      path: 'nearbyChannels',
      name: 'NearbyChannels',
      component: () =>
        import('@/views/operate/customAdvertising/nearbyChannels'),
      meta: {
        hidden: true,
        title: '图片轮播-频道页：附近频道',
      },
    },
    {
      path: 'recommend',
      name: 'Recommend',
      component: () => import('@/views/operate/customAdvertising/recommend'),
      meta: {
        hidden: true,
        title: '图片轮播-频道页：精彩推荐',
      },
    },
    {
      path: 'content',
      name: 'Content',
      component: () => import('@/views/operate/customAdvertising/content'),
      meta: {
        hidden: true,
        title: '图片轮播-频道页：内容',
      },
    },
    {
      path: 'find',
      name: 'Find',
      component: () => import('@/views/operate/customAdvertising/find'),
      meta: {
        hidden: true,
        title: '天气模块：发现页',
      },
    },
    {
      path: 'openScreen',
      name: 'OpenScreen',
      component: () => import('@/views/operate/systemAdvertising/openScreen'),
      meta: {
        hidden: true,
        title: '开屏广告',
      },
    },
    {
      path: 'informationList',
      name: 'InformationList',
      component: () =>
        import('@/views/operate/systemAdvertising/informationList'),
      meta: {
        hidden: true,
        title: '资讯列表广告',
      },
    },
    {
      path: 'homeFloat',
      name: 'HomeFloat',
      component: () => import('@/views/operate/systemAdvertising/homeFloat'),
      meta: {
        hidden: true,
        title: '首页浮动广告',
      },
    },
    {
      path: 'articleDetailsSlide',
      name: 'ArticleDetailsSlide',
      component: () =>
        import('@/views/operate/systemAdvertising/articleDetailsSlide'),
      meta: {
        hidden: true,
        title: '文章详情幻灯',
      },
    },
    {
      path: 'detailsTop',
      name: 'DetailsTop',
      component: () => import('@/views/operate/systemAdvertising/detailsTop'),
      meta: {
        hidden: true,
        title: '主题详情顶部广告（发布者下方）',
      },
    },
    {
      path: 'version/add',
      name: 'AddVersionPost',
      component: () => import('@/views/operate/customAdvertising/version/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-图片轮播——4.3之前版本首页',
      },
    },
    {
      path: 'version/edit',
      name: 'EditVersionPost',
      component: () => import('@/views/operate/customAdvertising/version/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-图片轮播——4.3之前版本首页',
      },
    },
    {
      path: 'nearbyChannels/add',
      name: 'AddNearbyChannelsPost',
      component: () =>
        import('@/views/operate/customAdvertising/nearbyChannels/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-图片轮播——频道页：附近频道',
      },
    },
    {
      path: 'nearbyChannels/edit',
      name: 'EditNearbyChannelsPost',
      component: () =>
        import('@/views/operate/customAdvertising/nearbyChannels/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-图片轮播——频道页：附近频道',
      },
    },
    {
      path: 'recommend/add',
      name: 'AddRecommendPost',
      component: () =>
        import('@/views/operate/customAdvertising/recommend/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-图片轮播——频道页：精彩推荐',
      },
    },
    {
      path: 'recommend/edit',
      name: 'EditRecommendPost',
      component: () =>
        import('@/views/operate/customAdvertising/recommend/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-图片轮播——频道页：精彩推荐',
      },
    },
    {
      path: 'content/add',
      name: 'AddContentPost',
      component: () => import('@/views/operate/customAdvertising/content/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-图片轮播——频道页：内容',
      },
    },
    {
      path: 'content/edit',
      name: 'EditContentPost',
      component: () => import('@/views/operate/customAdvertising/content/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-图片轮播——频道页：内容',
      },
    },
    {
      path: 'find/add',
      name: 'AddFindPost',
      component: () => import('@/views/operate/customAdvertising/find/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-天气模块——发现页',
      },
    },
    {
      path: 'find/edit',
      name: 'EditFindPost',
      component: () => import('@/views/operate/customAdvertising/find/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-天气模块——发现页',
      },
    },
    {
      path: 'openScreen/add',
      name: 'AddOpenScreen',
      component: () =>
        import('@/views/operate/systemAdvertising/openScreen/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-开屏广告',
      },
    },
    {
      path: 'openScreen/edit',
      name: 'EditOpenScreen',
      component: () =>
        import('@/views/operate/systemAdvertising/openScreen/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-开屏广告',
      },
    },
    {
      path: 'informationList/add',
      name: 'AddInformationList',
      component: () =>
        import('@/views/operate/systemAdvertising/informationList/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-资讯列表广告',
      },
    },
    {
      path: 'informationList/edit',
      name: 'EditInformationList',
      component: () =>
        import('@/views/operate/systemAdvertising/informationList/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-资讯列表广告',
      },
    },
    {
      path: 'articleDetailsSlide/add',
      name: 'AddArticleDetailsSlide',
      component: () =>
        import('@/views/operate/systemAdvertising/articleDetailsSlide/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-文章详情幻灯',
      },
    },
    {
      path: 'articleDetailsSlide/edit',
      name: 'EditArticleDetailsSlide',
      component: () =>
        import('@/views/operate/systemAdvertising/articleDetailsSlide/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-文章详情幻灯',
      },
    },
    {
      path: 'detailsTop/add',
      name: 'AddDetailsTop',
      component: () =>
        import('@/views/operate/systemAdvertising/detailsTop/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-主题详情顶部广告（发布者下方）',
      },
    },
    {
      path: 'detailsTop/edit',
      name: 'EditDetailsTop',
      component: () =>
        import('@/views/operate/systemAdvertising/detailsTop/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-主题详情顶部广告（发布者下方）',
      },
    },
    {
      path: 'homeFloat/add',
      name: 'AddHomeFloat',
      component: () =>
        import('@/views/operate/systemAdvertising/homeFloat/add'),
      meta: {
        hidden: true,
        title: '新增-广告信息-首页浮动广告',
      },
    },
    {
      path: 'homeFloat/edit',
      name: 'EditHomeFloat',
      component: () =>
        import('@/views/operate/systemAdvertising/homeFloat/edit'),
      meta: {
        hidden: true,
        title: '修改-广告信息-首页浮动广告',
      },
    },
  ],
}

export default operate
