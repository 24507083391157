<script>
  import Common from '../components/common.vue'
  import createAutoClick from '@/utils/random'
  export default {
    components: {
      Common,
    },
    data() {
      return {
        readNumList: [],
        detailInfo: {},
        query: {
          id: null,
          form: 2,
          pay_type: 1,
          pay_amount: null,
          code_source: 2,
          video_url: null,
          duration_h: null,
          duration_i: null,
          duration_s: null,
          play_style: 1,
          cover_pic: null,
          title: null,
          desc: null,
          source: null,
          album_id: null,
          column_id: [],
          is_see_in_client: 2,
          is_open_try_see: -1,
          is_limit_tourist: -1,
          is_inscreen_record: -1,
          auto_click: null,
          sort_time: null,
          try_see_code_source: 2,
          try_see_video_url: null,
          is_draft: null,
          timing: null,
          now_time: null,
          local_video_url1: null,
          local_video_url2: null,
        },
      }
    },
    methods: {
      // 获取图片上传地址
      handleImageUrl(prop, data) {
        this.query[prop] = data
      },
      // 获取视频上传地址
      handleVideoUrl(prop, query) {
        this.query[prop] = query.video_url
        this.query['video'] = query.video_aid
        this.query['video_url'] = query.video_url
      },
      // 生成随机数
      createRandom() {
        this.query.auto_click = createAutoClick()
        this.readNumList = createAutoClick().split(',')
      },
    },
  }
</script>
