var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "vab-footer" },
    [
      _vm._v(" Copyright "),
      _c("vab-icon", { attrs: { icon: "copyright-line" } }),
      _vm._v(" " + _vm._s(_vm.fullYear) + " " + _vm._s(_vm.title) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }