var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Common", {
    attrs: { query: _vm.query },
    on: {
      createRandom: _vm.createRandom,
      handleImageUrl: _vm.handleImageUrl,
      handleVideoUrl: _vm.handleVideoUrl,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }