var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-source-container" },
    [
      _c("div", { staticClass: "add-source-tips" }, [
        _vm._v("内容可以配置来源和列表显示样式"),
      ]),
      _c("div", { staticClass: "add-source-title" }, [_vm._v("编辑内容")]),
      _c("Form", {
        attrs: { column: _vm.column1, query: _vm.query1, "ref-name": "basic" },
        on: {
          createRandom: _vm.createRandom,
          handleAsyncWechat: _vm.handleAsyncWechat,
          handleImageUrl: _vm.handleImageUrl,
          openFixed: _vm.openFixed,
        },
        scopedSlots: _vm._u([
          {
            key: "user",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "add-source-user",
                    on: { click: _vm.handleVestList },
                  },
                  [
                    _c("img", { attrs: { alt: "", src: _vm.userInfo.head } }),
                    _c("span", [_vm._v(_vm._s(_vm.userInfo.name))]),
                    _c("b", [_vm._v("点击切换用户")]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-divider"),
      _c("LikeContainer", {
        attrs: { id: _vm.$route.query.content_id, themetype: "info" },
      }),
      _c("el-divider"),
      _c("CommentContainer", {
        attrs: {
          id: _vm.$route.query.content_id,
          themetype: "info",
          "user-page-list": _vm.userPageList,
        },
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "add-source-btn" },
        [
          _vm.query1.publish_time < _vm.time && _vm.query1.publish_time
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.hanldeRelease("save")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _vm.isBtn
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.hanldeRelease("now")
                      },
                    },
                  },
                  [_vm._v(" 立即发布 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-pie-chart",
                      size: "medium",
                      type: "primary",
                    },
                    on: { click: _vm.hanldeTiming },
                  },
                  [_vm._v(" 定时发布 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.hanldeRelease("null")
                      },
                    },
                  },
                  [_vm._v(" 存为草稿 ")]
                ),
              ]
            : _vm._e(),
          _vm.query1.publish_time > _vm.time
            ? _c("div", { staticClass: "add-source-tips" }, [
                _vm._v(
                  " 内容已被设置为定时发布，发布时间：" +
                    _vm._s(
                      _vm._f("dateFormat")(_vm.query1.publish_time / 1000, true)
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "固定到信息流",
            visible: _vm.dialogVisible1,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c("Form", {
            attrs: {
              column: _vm.column2,
              query: _vm.query2,
              "ref-name": "basic",
            },
            on: { getFixedTitle: _vm.getFixedTitle },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveFixed } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.cancelInfoFixed },
                },
                [_vm._v("取消固定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "定时发布",
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("Form", {
            ref: "timingForm",
            attrs: {
              column: _vm.timingColumn,
              query: _vm.timingQuery,
              "ref-name": "timing",
              rules: _vm.timingRules,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveTiming("timing")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("VestList", { ref: "vestList", on: { setUserInfo: _vm.setUserInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }