<script>
  import Common from '../components/common.vue'
  import createAutoClick from '@/utils/random'
  export default {
    components: {
      Common,
    },
    data() {
      return {
        readNumList: [],
        query1: {
          id: null,
          type: 1,
          fixed: -1,
          from: '',
          title: '',
          pic_type: 4,
          pics: '',
          label: '',
          content: null,
          publish_time: null,
          create_time: null,
          auto_click: '',
          click_show: null,
          circleId: null,
          link: null,
          is_open_red_packet: -1,
          user_id: null,
          cat_id: this.$route.query.id,
          type_value: '',
          user: {},
        },
        query2: {
          position: 1,
          beg_time: Date.now(),
          end_time: Date.now(),
          // info: {},
          fixedInfo: {},
        },
      }
    },
    methods: {
      // 生成随机数
      createRandom() {
        this.query1.auto_click = createAutoClick()
        this.readNumList = createAutoClick().split(',')
      },
      handleImageUrl(prop, data) {
        this.query1[prop] = data
      },
      // 获取微信同步内容
      getAsyncWechatContent(val) {
        this.query1.title = val.title
        this.query1.pics = val.pics.split(',')[0]
        this.query1.from = val.from
        this.query1.pic_type = val.pic_type
        this.query1.content = val.content
      },
    },
  }
</script>
