<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="50%"
  >
    <Form ref="create" :column="column" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { contentColumnPage, toBatchUpdateColumnRel } from '@/api/video'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: '选择栏目',
        dialogVisible: false,
        loading: false,
        columnOption: [],
        item: {},
        query: {
          column_ids: [],
          ids: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '所属栏目',
            prop: 'column_ids',
            type: 'checkbox',
            optionLabel: 'name',
            list: this.columnOption,
          },
        ]
      },
    },
    methods: {
      handleOpen(ids) {
        this.query.ids = ids
        this.contentColumnPage()
        this.dialogVisible = true
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 获取所属栏目
      async contentColumnPage() {
        const res = await contentColumnPage()
        if (res.success) {
          this.columnOption = res.list
        }
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = Object.assign({}, this.query)
        params.column_ids = params.column_ids.join('#')
        const res = await toBatchUpdateColumnRel(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('移入成功')
        } else {
          this.$message.error('移入失败')
        }
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.create.resetFields()
        setTimeout(() => {
          this.query.column_ids = []
          this.query.ids = null
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
