const config = {
  dialogVisible: false,
  forumList: [],
  // 封面配置
  coverConfigList: Object.freeze([
    {
      id: 1,
      label: '居左',
    },
    {
      id: 2,
      label: '居右',
    },
  ]),
  // 封面样式配置
  coverStyleList: Object.freeze([
    {
      id: 1,
      label: '直角',
    },
    {
      id: 2,
      label: '圆角',
    },
  ]),
  // 排序方式
  sortList: Object.freeze([
    {
      id: 'lastpost',
      label: '按最后回复时间倒序排序',
    },
    {
      id: 'dateline',
      label: '按发布时间倒序排序',
    },
    {
      id: 'replies',
      label: '按回复倒序排序',
    },
    {
      id: 'views',
      label: '按浏览次数倒序排序',
    },
    {
      id: 'heats',
      label: '按热度倒序排序',
    },
  ]),
  // 时间
  timeList: Object.freeze([
    {
      id: 0,
      label: '不限',
    },
    {
      id: 1,
      label: '24小时内',
    },
    {
      id: 3,
      label: '3天内',
    },
    {
      id: 5,
      label: '5天内',
    },
    {
      id: 7,
      label: '7天内',
    },
    {
      id: 15,
      label: '15天内',
    },
    {
      id: 30,
      label: '30天内',
    },
    {
      id: 60,
      label: '60天内',
    },
    {
      id: 180,
      label: '180天内',
    },
    {
      id: 360,
      label: '360天内',
    },
  ]),
  // 样式选择
  styleSelectionList: Object.freeze([
    {
      id: 'info',
      label: '资讯样式',
    },
    {
      id: 'weibo',
      label: '微博样式',
    },
  ]),
  // 内容样式
  contentStyleList: Object.freeze([
    {
      id: 'title',
      label: '显示标题',
    },
    {
      id: 'des',
      label: '显示描述',
    },
    {
      id: 'title_des',
      label: '标题+描述',
    },
  ]),
  // 是否
  whetherList: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 0,
      label: '否',
    },
  ]),
}

export default config
