var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "upload-" + _vm.prop,
      staticClass: "image-uploader",
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px",
      },
      attrs: {
        accept: _vm.imageFiles,
        action: _vm.uploadApi,
        "before-upload": _vm.beforeImageUpload,
        data: _vm.uploadFormData,
        "element-loading-spinner": "el-icon-loading",
        "element-loading-text": "上传中",
        limit: _vm.limit,
        "on-error": _vm.handleImageError,
        "on-success": _vm.handleImageSuccess,
        "show-file-list": false,
      },
    },
    [
      _vm.imageUrl
        ? _c("div", { staticClass: "image-url" }, [
            _c("img", { attrs: { src: _vm.imageLink } }),
            _c("i", {
              staticClass: "el-icon-remove",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleRemoveImage.apply(null, arguments)
                },
              },
            }),
          ])
        : _c("i", { staticClass: "el-icon-picture image-uploader-icon" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }