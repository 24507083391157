<script>
  import Common from '../components/common.vue'
  import Manage from '../manage.vue'
  import ViewCallDialog from '../../components/viewCallDialog'
  export default {
    components: {
      Common,
      Manage,
      ViewCallDialog,
    },
    data() {
      return {
        activeName: this.$route.query.activeName,
        detailInfo: {},
        query1: {
          name: null,
          type: 6,
          show_style: 'pintu',
          pintu_type: 1,
          comment_type: 2,
          sort_type: 2,
          sort_max_size: 1000,
          sort_time_range: 7,
          self_sort_time_range: 0,
          is_first_rand_default: -1,
          info_num_limit: 1000,
          is_forum: 0,
          is_show: true,
        },
        query2: {
          style: 1,
          cat_from_type: 1,
          show_from: [],
          topic_from: [],
          show_publish_time: 0,
          show_interact_time: 0,
          show_read_count: 0,
          show_comment_count: 0,
          show_is_elite: 0,
          show_is_pic: 0,
          show_is_video: 0,
          show_buffer_time: 1,
        },
      }
    },
    methods: {
      handleClick(tab) {
        console.log(tab)
      },
      // 调用
      viewCall() {
        this.$refs.viewCall.openDialog(this.$route.query.id)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-tabs {
    ::v-deep .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px;
      }
      .el-tabs__item {
        height: 60px;
        line-height: 60px;
      }
    }
  }
  .tab-info {
    position: relative;
    .call {
      position: absolute;
      left: 20px;
      top: 14px;
      z-index: 1;
    }
  }
</style>
