<template>
  <el-dialog title="预览" :visible.sync="dialogVisible" width="40%">
    <div align="center">
      <vue-qr :logo-scale="0.2" :size="250" :text="imgUrl" />
    </div>
  </el-dialog>
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    components: {
      VueQr,
    },
    data() {
      return {
        dialogVisible: false,
        imgUrl: null,
      }
    },
    methods: {
      handleOpen(url) {
        this.dialogVisible = true
        this.imgUrl = url
      },
    },
  }
</script>
