<template>
  <el-tabs v-model="activeName">
    <el-tab-pane :label="translateTitle('新增推送')" name="1">
      <CreatePush v-if="activeName == 1" @handleTabActive="handleTabActive" />
    </el-tab-pane>
    <el-tab-pane :label="translateTitle('推送列表')" name="2">
      <PushList v-if="activeName == 2" />
    </el-tab-pane>
    <el-tab-pane :label="translateTitle('错误日志')" name="3">
      <PushErrorList v-if="activeName == 3" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import CreatePush from './components/createPush'
  import PushList from './components/pushList'
  import PushErrorList from './components/pushErrorList'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      CreatePush,
      PushList,
      PushErrorList,
    },
    data() {
      return {
        activeName: '1',
      }
    },
    methods: {
      translateTitle,
      handleTabActive(active) {
        this.activeName = active
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-tabs {
    ::v-deep .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px;
      }
      .el-tabs__item {
        height: 60px;
        line-height: 60px;
      }
    }
  }
</style>
