<script>
  import Common from '../components/common.vue'
  import Manage from '../manage.vue'
  import ViewCallDialog from '../../components/viewCallDialog'
  export default {
    components: {
      Common,
      Manage,
      ViewCallDialog,
    },
    data() {
      return {
        activeName: this.$route.query.activeName,
        detailInfo: {},
        query1: {
          name: null,
          type: 3,
          cover_set: 2,
        },
        query2: {
          forum_portal_id: null,
        },
      }
    },
    methods: {
      handleClick(tab) {
        console.log(tab)
      },
      // 调用
      viewCall() {
        this.$refs.viewCall.openDialog(this.$route.query.id)
      },
    },
  }
</script>
