var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "预览", visible: _vm.dialogVisible, width: "40%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("vue-qr", {
            attrs: { "logo-scale": 0.2, size: 250, text: _vm.imgUrl },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }