<template>
  <el-form
    :ref="refName"
    :label-position="labelPosition"
    :label-width="`${labelWidth}px`"
    :model="query"
    :rules="rules"
  >
    <template v-for="(item, index) in column">
      <div v-if="item.title" :key="index + '-only'" class="title">
        {{ translateTitle(item.title) }}
      </div>
      <template v-if="!item.isHide">
        <!-- 输入框 -->
        <el-form-item
          v-if="item.type === 'input' || !item.type"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-input
            v-model="params[item.prop]"
            :clearable="item.clearable !== false"
            :placeholder="translateTitle(`请输入${item.label || ''}`)"
            :readonly="item.readonly || false"
          />
          <span
            v-if="item.clickText"
            class="click-tips"
            @click="handleEvent(item.clickEventName, $event)"
          >
            {{ translateTitle(item.clickText) }}
          </span>
          <div
            v-if="item.tipsBtn"
            class="click-tips"
            @click="handleEvent(item.tipsEventName, $event)"
          >
            {{ translateTitle(item.tipsBtn) }}
          </div>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <el-form-item
          v-if="item.type === 'text'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :label-width="`${item.labelWidth || labelWidth}px`"
          :prop="item.prop"
        >
          <p class="text">{{ translateTitle(item.text) }}</p>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
          <span
            v-if="item.tipsBtn"
            class="click-tips"
            @click="handleEvent(item.tipsEventName, $event)"
          >
            {{ translateTitle(item.tipsBtn) }}
          </span>
        </el-form-item>
        <!-- 文本域 -->
        <el-form-item
          v-if="item.type === 'textarea'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-input
            v-model="params[item.prop]"
            :autosize="{ minRows: 6 }"
            :clearable="item.clearable !== false"
            :disabled="item.disabled"
            :placeholder="translateTitle(`请输入${item.label || ''}`)"
            type="textarea"
          />
          <span
            class="click-tips"
            @click="handleEvent(item.clickEventName, $event)"
          >
            {{ translateTitle(item.clickText) }}
          </span>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 数字 -->
        <el-form-item
          v-else-if="item.type === 'number'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-input-number
            v-model="params[item.prop]"
            :controls="item.controls || false"
            :max="item.max"
            :min="item.min || 0"
            :placeholder="translateTitle(`请输入${item.label || ''}`)"
            :precision="item.precision || 0"
            :step="item.step || 1"
          />
          <!-- <el-input
            v-model.number="params[item.prop]"
            :clearable="item.clearable !== false"
            :placeholder="`请输入${item.label || ''}`"
            @input="changeInput(item.prop)"
          /> -->
          <span
            v-if="item.clickText"
            @click="handleEvent(item.clickEventName, $event)"
          >
            {{ translateTitle(item.clickText) }}
          </span>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 输入框组 -->
        <el-form-item
          v-else-if="item.type === 'inputGroup'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
        >
          <el-input
            v-model="params[item.prop[0]]"
            class="m-r-10"
            :clearable="item.clearable !== false"
            :placeholder="translateTitle(`请输入${item.placeholder[0]}`)"
          />
          <el-input
            v-model="params[item.prop[1]]"
            :clearable="item.clearable !== false"
            :placeholder="translateTitle(`请输入${item.placeholder[1]}`)"
          />
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 下拉框 -->
        <el-form-item
          v-else-if="item.type === 'select'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-select
            v-model="params[item.prop]"
            :clearable="item.clearable !== false"
            :collapse-tags="item.collapseTags || false"
            :filterable="item.filterable || false"
            :multiple="item.multiple || false"
            :placeholder="translateTitle(`请选择${item.label}`)"
            :remote="item.remote || false"
            value-key="id"
            @change="handleEvent(item.eventName, $event)"
          >
            <el-option
              v-for="(el, i) in item.list"
              :key="i"
              :label="translateTitle(el[item.optionLabel || 'label'])"
              :value="el[item.optionValue || 'id']"
            />
          </el-select>
          <span
            v-if="item.clickText"
            class="click-tips"
            @click="handleEvent(item.clickEventName, $event)"
          >
            {{ translateTitle(item.clickText) }}
          </span>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
          <span
            v-if="item.tipsBtn"
            class="click-tips"
            @click="handleEvent(item.tipsEventName, $event)"
          >
            {{ translateTitle(item.tipsBtn) }}
          </span>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'filterSelect'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <treeselect
            :id="`select-${item.prop}`"
            v-model="params[item.prop]"
            :async="true"
            :clearable="item.clearable !== false"
            :default-options="item.defaultOptions || false"
            :instance-id="item.prop"
            :load-options="loadOptions"
            :multiple="item.multiple || false"
            :options="item.list"
            :placeholder="translateTitle(`请选择${item.label}`)"
            :search-prompt-text="translateTitle('请输入关键词')"
          />
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 单选框 -->
        <el-form-item
          v-else-if="item.type === 'radio'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-radio-group
            v-model="params[item.prop]"
            @change="handleEvent(item.eventName, $event)"
          >
            <el-radio
              v-for="(el, i) in item.list"
              :key="i"
              :label="el[item.optionValue || 'id']"
            >
              {{ translateTitle(el[item.optionLabel || 'label']) }}
            </el-radio>
          </el-radio-group>
          <el-button
            v-if="item.showBtn"
            class="btn"
            type="text"
            @click="handleClick(item.btnEvent)"
          >
            {{ translateTitle(item.btn) }}
          </el-button>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 多选框 -->
        <el-form-item
          v-else-if="item.type === 'checkbox'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-checkbox-group
            v-model="params[item.prop]"
            @change="handleEvent(item.eventName, $event)"
          >
            <el-checkbox
              v-for="(el, i) in item.list"
              :key="i"
              :disabled="el.disabled"
              :label="el[item.optionValue || 'id']"
            >
              {{ translateTitle(el[item.optionLabel || 'label']) }}
            </el-checkbox>
          </el-checkbox-group>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
          <span
            v-if="item.clickText"
            class="click-tips"
            @click="handleEvent(item.clickEventName, $event)"
          >
            {{ translateTitle(item.clickText) }}
          </span>
        </el-form-item>
        <!--开始时间-->
        <el-form-item
          v-else-if="item.type === 'startDaterangePicker'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-date-picker
            v-model="params[item.prop]"
            :clearable="item.clearable !== false"
            :picker-options="pickerStartOptions"
            :placeholder="translateTitle(`${item.label}`)"
            style="width: 100%"
            :type="item.timeType || 'date'"
            :value-format="item.format || 'timestamp'"
            @focus="() => handleFocusDate('start', index)"
          />
        </el-form-item>
        <!--结束时间-->
        <el-form-item
          v-else-if="item.type === 'endDaterangePicker'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-date-picker
            v-model="params[item.prop]"
            :clearable="item.clearable !== false"
            :picker-options="pickerEndOptions"
            :placeholder="translateTitle(`${item.label}`)"
            style="width: 100%"
            :type="item.timeType || 'date'"
            :value-format="item.format || 'timestamp'"
            @focus="() => handleFocusDate('end', index)"
          />
          <p
            v-if="item.tips"
            class="tips"
            :style="`margin-top: ${item.top}`"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 时间 -->
        <el-form-item
          v-else-if="
            item.type === 'date' ||
            item.type === 'month' ||
            item.type === 'datetime'
          "
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <el-date-picker
            v-model="params[item.prop]"
            :clearable="item.clearable !== false"
            :placeholder="translateTitle(`请选择${item.label}`)"
            :type="item.type || 'date'"
            :value-format="item.format || 'timestamp'"
          />
        </el-form-item>
        <!-- 点击按钮 -->
        <el-form-item
          v-else-if="item.type === 'btn'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
        >
          <el-button
            :size="item.size || 'mini'"
            :type="item.btnType"
            @click.stop="handleEvent(item.eventName, $event)"
          >
            {{ translateTitle(item.btnText) }}
          </el-button>
          <p
            v-if="item.tips"
            class="tips"
            :style="`margin-top: ${item.top}`"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 图片上传 -->
        <el-form-item
          v-else-if="item.type === 'uploadImage'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <UploadImage
            :event-name="item.eventName"
            :height="item.height"
            :image-url="params[item.prop]"
            :limit="item.limit"
            :prop="item.prop"
            :width="item.width"
            @handleImageUrl="handleImageUrl"
          />
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 视频上传 -->
        <el-form-item
          v-else-if="item.type === 'uploadVideo'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <UploadVideo
            :event-name="item.eventName"
            :height="item.height"
            :limit="item.limit"
            :prop="item.prop"
            :video-url="params[item.prop]"
            :width="item.width"
            @handleVideoUrl="handleVideoUrl"
          />
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
        <!-- 富文本编辑器 -->
        <el-form-item
          v-else-if="item.type === 'richTextEditor'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :prop="item.prop"
        >
          <vue-ueditor-wrap
            v-model="params[item.prop]"
            :config="myConfig"
            @beforeInit="addCustomDialog"
          />
          <el-upload
            ref="elUpload"
            :accept="imageFiles"
            :action="myConfig.imageUrl"
            :auto-upload="true"
            :before-upload="beforeImageUpload"
            :on-success="handleImageSuccess"
            :show-file-list="false"
          />
        </el-form-item>
        <!-- 自定义区域 -->
        <el-form-item
          v-else-if="item.type === 'slot'"
          :key="index"
          :label="labelShow ? translateTitle(item.label) : ''"
          :label-width="`${item.labelWidth || labelWidth}px`"
          :prop="item.prop"
        >
          <!-- 具名插槽（根据传入的插槽名称，父组件用同样名称来接收row数据） -->
          <slot v-if="item.slotName" :name="item.slotName" />
          <span
            v-if="item.sign"
            class="tips"
            v-html="translateTitle(item.sign)"
          ></span>
          <p
            v-if="item.tips"
            class="tips"
            v-html="translateTitle(item.tips)"
          ></p>
        </el-form-item>
      </template>
    </template>
  </el-form>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapGetters } from 'vuex'
  import UploadImage from '@/components/upload/image.vue'
  import UploadVideo from '@/components/upload/video.vue'
  import VueUeditorWrap from 'vue-ueditor-wrap'
  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { baseURL, imageMaxSize, imageManagerAllowFiles } from '@/config'
  export default {
    components: {
      UploadImage,
      UploadVideo,
      VueUeditorWrap,
      Treeselect,
    },
    props: {
      refName: {
        type: String,
        default: 'form',
      },
      labelPosition: {
        type: String,
        default: 'left',
      },
      // 搜索数据
      query: {
        type: Object,
        default: () => ({}),
      },
      // 验证规则
      rules: {
        type: Object,
        default: () => ({}),
      },
      // 搜索配置
      column: {
        type: Array,
        default: () => [],
      },
      // 标签宽度
      labelWidth: {
        type: [Number, String],
        default: 110,
      },
      // 标题显示
      labelShow: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        pickerStartOptions: {}, // 开始日期配置
        pickerEndOptions: {}, // 结束日期配置
        params: this.query,
        imageFiles: imageManagerAllowFiles,
        myConfig: {
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 700,
          // 初始容器宽度
          initialFrameWidth: '100%',
          // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
          serverUrl: baseURL,
          // 图片最大限制
          imageMaxSize: 51200000,
          // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
          UEDITOR_HOME_URL: '/static/ueditor/',

          /* 上传图片配置项 */
          imageUrl: baseURL + '/core/attachment/Attachment/ueditorUploadPic',
          imageActionName: 'uploadimage',
          imageFieldName: 'file',
          imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
          imageCompressEnable: true,
          imageCompressBorder: 1600,
          imageInsertAlign: 'none',
          imageUrlPrefix: baseURL.slice(0, baseURL.length - 1),
          imagePathFormat:
            '/public/uploads/ueditor/php/upload/image/{yyyy}{mm}{dd}/{time}{rand:6}',

          /* 上传视频配置 */
          videoUrl: baseURL + '/core/attachment/Attachment/ueditorUploadVideo',
          videoActionName: 'uploadvideo',
          videoFieldName: 'upfile',
          videoPathFormat:
            '/ueditor/php/upload/video/{yyyy}{mm}{dd}/{time}{rand:6}',
          videoUrlPrefix: '',
          videoMaxSize: 524288000, //500M
          videoAllowFiles: [
            '.flv',
            '.swf',
            '.mkv',
            '.avi',
            '.rm',
            '.rmvb',
            '.mpeg',
            '.mpg',
            '.ogg',
            '.ogv',
            '.mov',
            '.wmv',
            '.mp4',
            '.webm',
            '.mp3',
            '.wav',
            '.mid',
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        qiniuToken: 'user/qiniuToken',
      }),
    },
    created() {
      if (
        this.column.filter(
          (item) => item.type === 'uploadImage' || item.type === 'uploadVideo'
        ).length &&
        !this.qiniuToken
      ) {
        this.$store.dispatch('user/getQNToken')
      }
    },
    methods: {
      translateTitle,
      handleEvent(eventName, event) {
        if (eventName) this.$emit(eventName, this.query, event)
      },
      handleImageUrl(eventName, prop, id) {
        if (eventName) this.$emit(eventName, prop, id)
      },
      handleVideoUrl(eventName, prop, query) {
        if (eventName) this.$emit(eventName, prop, query)
      },
      handleClick(eventName) {
        if (eventName) this.$emit(eventName, true)
      },
      loadOptions({ action, searchQuery, callback, instanceId }) {
        if (action === 'ASYNC_SEARCH') {
          const filter = this.column.filter((item) => item.prop == instanceId)
          if (filter.length) {
            this.$emit(filter[0].eventName, instanceId, searchQuery, (val) => {
              callback(null, val)
            })
          }
        }
      },
      // 开始结束日期
      handleFocusDate(type, index) {
        const dateProp =
          type === 'start'
            ? this.column[index + 1].prop
            : this.column[index - 1].prop

        const pickerOptions = {
          disabledDate: (time) => {
            return this.disabledDate(time, dateProp, type)
          },
        }
        if (type === 'start') {
          this.pickerStartOptions = pickerOptions
        } else {
          this.pickerEndOptions = pickerOptions
        }
      },
      // 开始结束日期禁用日期
      disabledDate(time, prop, type) {
        if (type === 'end') {
          const startDate = this.params[prop]
          if (startDate) {
            return time.getTime() < new Date(startDate).getTime()
          }
        } else {
          const endDate = this.params[prop]
          if (endDate) {
            return time.getTime() > new Date(endDate).getTime()
          }
        }
      },
      changeInput(prop) {
        const pattern = /^[1-9][0-9]*$/
        if (!pattern.test(this.params[prop])) {
          this.params[prop] = this.params[prop].replace(/[^\d]/g, '')
        }
      },
      // 重置规则
      resetFields() {
        this.$refs[this.refName].resetFields()
      },
      // 添加自定义弹窗
      addCustomDialog(editorId) {
        window.UE.registerUI(
          'simpleupload',
          (editor, uiName) => {
            // 参考http://ueditor.baidu.com/doc/#COMMAND.LIST
            var btn = new window.UE.ui.Button({
              name: 'dialog-button',
              title: '上传图片',
              cssRules: `background-position: -381px 0;`,
              onclick: () => {
                this.$refs.elUpload[0].$refs['upload-inner'].handleClick()
                editor.execCommand(uiName)
              },
            })
            return btn
          },
          56 /* 指定添加到工具栏上的那个位置，默认时追加到最后 */,
          editorId /* 指定这个UI是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
        )
      },
      // 上传成功
      handleImageSuccess(res) {
        let editor = document.querySelector('.edui-default').getAttribute('id')
        window.UE.getEditor(editor).execCommand('insertimage', {
          src: baseURL + res.url,
          width: 'auto',
          height: 'auto',
        })
      },
      // 上传之前
      beforeImageUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < imageMaxSize
        if (!isLt2M) {
          this.$message.error(`上传图片大小不能超过 ${imageMaxSize}MB!`)
        }
        return isLt2M
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-form {
    .el-input,
    .el-select,
    .el-input-number {
      width: 350px !important;
    }
    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }
    ::v-deep.el-radio__label {
      line-height: 32px !important;
    }
  }
  .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .tips {
    margin: 5px 0 !important;
    font-size: 13px;
    // max-width: 640px;
    line-height: 20px;
    color: $base-color-text-secondary;
    ::v-deep span {
      color: $base-color-red;
    }
  }
  .click-tips {
    margin-left: 10px;
    color: $base-color-blue;
    cursor: pointer;
  }
  .text {
    margin-top: -1px;
    font-weight: 600;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .btn {
    display: inline-block;
    margin-left: 50px;
    transform: translateY(1px);
    &:hover {
      text-decoration: underline;
    }
  }
  ::v-deep .vue-treeselect {
    width: 350px !important;
    line-height: 16px;
    .vue-treeselect__control {
      line-height: 16px !important;
      border-radius: 2.5px;
    }
    .vue-treeselect__placeholder {
      font-size: 14px;
      line-height: 32px;
    }
    input {
      font-size: 16px;
    }
  }
</style>
