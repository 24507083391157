<template>
  <div class="add-source-container">
    <div class="add-source-tips">内容可以配置来源和列表显示样式</div>
    <div class="add-source-title">编辑内容</div>
    <Form
      :column="column1"
      :query="query1"
      ref-name="basic"
      @createRandom="createRandom"
      @handleAsyncWechat="handleAsyncWechat"
      @handleImageUrl="handleImageUrl"
      @openFixed="openFixed"
    >
      <template #user>
        <div class="add-source-user" @click="handleVestList">
          <img alt="" :src="userInfo.head" />
          <span>{{ userInfo.name }}</span>
          <b>点击切换用户</b>
        </div>
      </template>
    </Form>
    <el-divider />
    <LikeContainer :id="$route.query.content_id" themetype="info" />
    <el-divider />
    <CommentContainer
      :id="$route.query.content_id"
      themetype="info"
      :user-page-list="userPageList"
    />
    <el-divider />
    <div class="add-source-btn">
      <el-button
        v-if="query1.publish_time < time && query1.publish_time"
        size="medium"
        type="primary"
        @click="hanldeRelease('save')"
      >
        保存
      </el-button>
      <template v-if="isBtn">
        <el-button size="medium" type="primary" @click="hanldeRelease('now')">
          立即发布
        </el-button>
        <el-button
          icon="el-icon-pie-chart"
          size="medium"
          type="primary"
          @click="hanldeTiming"
        >
          定时发布
        </el-button>
        <el-button size="medium" @click="hanldeRelease('null')">
          存为草稿
        </el-button>
      </template>
      <div v-if="query1.publish_time > time" class="add-source-tips">
        内容已被设置为定时发布，发布时间：{{
          (query1.publish_time / 1000) | dateFormat(true)
        }}
      </div>
    </div>
    <!-- 是否固定--对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="固定到信息流"
      :visible.sync="dialogVisible1"
      width="50%"
    >
      <Form
        :column="column2"
        :query="query2"
        ref-name="basic"
        @getFixedTitle="getFixedTitle"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveFixed">保 存</el-button>
        <el-button type="danger" @click="cancelInfoFixed">取消固定</el-button>
      </span>
    </el-dialog>
    <!-- 定时发布--对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="定时发布"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <Form
        ref="timingForm"
        :column="timingColumn"
        :query="timingQuery"
        ref-name="timing"
        :rules="timingRules"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTiming('timing')">
          确 定
        </el-button>
        <el-button type="danger" @click="cancel">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择用户--对话框 -->
    <VestList ref="vestList" @setUserInfo="setUserInfo" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Form from '@/components/Form'
  import VestList from '@/views/audit/components/vestList'
  import LikeContainer from './like'
  import CommentContainer from './comment'
  import config from './config'
  import {
    getFixedPosition,
    getFixedTitle,
    getFixedInfo,
    saveFixed,
    cancelInfoFixed,
    infoSave,
    getItemInfo,
  } from '@/api/information'
  import { getUserPage } from '@/api/user'
  export default {
    components: {
      Form,
      VestList,
      LikeContainer,
      CommentContainer,
    },
    props: {
      query1: {
        type: Object,
        default: () => ({}),
      },
      query2: {
        type: Object,
        default: () => ({}),
      },
      readNumList: {
        type: Array,
        default: () => [],
      },
      from: {
        type: String,
        default: null,
      },
    },
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      isBtn() {
        return (
          !this.query1.publish_time ||
          !this.query1.id ||
          this.query1.publish_time > this.time
        )
      },
      column1() {
        return [
          {
            label: '类型',
            prop: 'type',
            type: 'select',
            clearable: false,
            list: this.typeList,
          },
          // {
          //   label: '是否固定',
          //   prop: 'fixed',
          //   type: 'radio',
          //   list: this.fixedList,
          //   tips: '设置内容固定会显示在信息流中固定的位置，可配置显示时间区间。',
          //   showBtn: this.query1.fixed !== -1,
          //   btn: this.isShowPosition
          //     ? `点击设置(已固定到${this.query2.position})`
          //     : '点击设置',
          //   btnEvent: 'openFixed',
          // },
          {
            label: '微信访问地址',
            prop: 'type_value',
            clickText: '同步微信内容',
            clickEventName: 'handleAsyncWechat',
            isHide: this.query1.type != 5,
            tips: '<span>请登录<a href="http://weixin.sogou.com" target="_blank" style="color: #ff4d4f;">http://weixin.sogou.com</a>搜索微信文章，复制地址栏链接。<br/>特别提醒：请不要使用手机微信复制文章，无法识别。</span>',
          },
          {
            label: '显示来源',
            prop: 'from',
            tips: '来源将在列表页显示',
          },
          {
            label: '发布用户',
            prop: 'user_id',
            type: 'slot',
            slotName: 'user',
          },
          {
            label: '标题',
            prop: 'title',
            tips: '建议标题字数在25字以内',
          },
          {
            label: '列表图片',
            prop: 'pic_type',
            type: 'radio',
            list: this.listPicTypes,
          },
          {
            label: '',
            prop: 'pics',
            type: 'uploadImage',
            width: 178,
            height: 134,
            isHide: this.query1.pic_type == 4,
            eventName: 'handleImageUrl',
            tips: '图片建议尺寸：178x134px',
          },
          {
            label: '标签',
            prop: 'label',
            type: 'radio',
            list: this.labelList,
          },
          {
            label: '详细内容',
            prop: 'content',
            type: 'richTextEditor',
          },
          {
            label: '发布时间',
            prop: 'publish_time',
            type: 'datetime',
            isHide: this.from == 'add',
          },
          {
            label: '虚拟阅读数',
            prop: 'auto_click',
            clickText: '重新生成',
            clickEventName: 'createRandom',
            tips: !this.query1.auto_click
              ? '当前显示阅读数为：'
              : `当前显示阅读数为：</br>第1天自动虚拟阅读${this.readNumList[0]},第2天自动虚拟阅读${this.readNumList[1]},第3天自动虚拟阅读${this.readNumList[2]},第4天自动虚拟阅读${this.readNumList[3]},第5天自动虚拟阅读${this.readNumList[4]}`,
          },
          // {
          //   label: '分享红包是否开启',
          //   prop: 'is_open_red_packet',
          //   type: 'radio',
          //   list: this.openRedPacketList,
          // },
          // {
          //   label: '分享红包用户选择',
          //   prop: 'user_id',
          //   type: 'select',
          //   list: this.userPageList,
          //   optionValue: 'user_id',
          //   optionLabel: 'name',
          //   isHide: this.query1.is_open_red_packet !== 1,
          //   tips: '选择发放红包的用户，必须保持该用户余额充足。',
          // },
        ]
      },
      column2() {
        return [
          {
            label: '固定位置',
            prop: 'position',
            type: 'select',
            list: this.fixedPositionList,
            optionLabel: 'name',
            optionValue: 'value',
            eventName: 'getFixedTitle',
          },
          {
            label: '开始时间',
            prop: 'beg_time',
            type: 'startDaterangePicker',
            timeType: 'datetime',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            type: 'endDaterangePicker',
            timeType: 'datetime',
            tips: '未设置结束时间，固定位置将不会添加',
            top: '15px',
          },
          {
            label: '该位置固定的内容',
            // prop: '',
            type: 'textarea',
            disabled: true,
          },
        ]
      },
    },
    watch: {
      'query1.is_open_red_packet': {
        deep: true,
        handler() {
          if (this.query1.is_open_red_packet === 1) this.getUserPage()
        },
      },
      'query1.user': {
        handler(newName) {
          this.userInfo = newName
        },
      },
    },
    created() {
      this.getLabelList()
      this.getFixedInfo()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      openFixed(flag) {
        this.dialogVisible = flag
        this.getFixedPosition()
        this.getFixedTitle()
      },
      // 获取是否固定列表
      getFixedPosition() {
        const params = { list_type: 1, list_type_value: 82 }
        getFixedPosition(params).then((res) => {
          this.fixedPositionList = res.list
        })
      },
      // 获取固定内容
      getFixedTitle() {
        const params = {
          list_type: 1,
          list_type_value: 82,
          position: this.query2.position,
        }
        getFixedTitle(params).then((res) => {
          if (res.data.length !== 0) {
            // this.query2.info = res.data[0]
            // console.log(this.query2.info)
          }
        })
      },
      // 获取固定信息
      getFixedInfo() {
        const params = {
          list_type: 1,
          content_type: 1,
          list_type_value: this.$route.query.id,
          content_id: this.$route.query.content_id,
        }
        getFixedInfo(params).then((res) => {
          if (res.success) {
            this.$emit('setFixedInfo', res.data)
          }
        })
      },
      // 同步微信内容
      async handleAsyncWechat() {
        const params = {
          type: this.query1.type,
          id: this.query1.type_value,
          cat_id: this.$route.query.id,
          content_id: this.$route.query.content_id || null,
        }
        const res = await getItemInfo(params)
        if (res.success) {
          this.$emit('getAsyncWechatContent', res.data)
        } else {
          this.$message.error(res.msg)
        }
      },
      // 获取元宇宙用户
      handleVestList() {
        this.$refs.vestList.handleOpen()
      },
      setUserInfo(val) {
        this.userInfo = val
      },
      // 保存固定位置
      saveFixed() {
        const params = {
          list_type: 1,
          list_type_value: 82,
          content_type: 1,
          content_id: 3396,
          ...this.query2,
        }
        params.info = { title: '先写固定，再找变量' }
        saveFixed(params).then((res) => {
          if (res.success) {
            this.dialogVisible = false
            this.$message.success('固定成功')
            this.isShowPosition = true
          }
        })
      },
      // 取消固定
      cancelInfoFixed() {
        const params = {
          cat_id: 82,
          id: 3396,
        }
        cancelInfoFixed(params).then((res) => {
          if (res.success) {
            this.dialogVisible = false
            this.$message.success('取消固定')
            this.isShowPosition = false
          }
        })
      },
      // 获取标签列表
      getLabelList() {
        if (this.labelList.length === 0) {
          for (let key of Object.keys(this.config.icons.labels)) {
            const item = { id: key, label: this.config.icons.labels[key] }
            this.labelList.push(item)
          }
          this.labelList.unshift({ id: '', label: '无' })
        }
      },
      // 获取用户列表
      getUserPage() {
        const params = {
          name: '',
          page: 1,
          step: 20,
        }
        getUserPage(params).then((res) => {
          this.userPageList = res.list
        })
      },
      handleImageUrl(prop, data) {
        this.$emit('handleImageUrl', prop, data)
      },
      createRandom() {
        this.$emit('createRandom')
      },
      // 发布
      async hanldeRelease(type) {
        const params = Object.assign({}, this.query1)
        params.user_id = this.userInfo.user_id || ''
        if (!params.id) delete params.id
        if (type === 'save') {
          params.publish_time = params.publish_time / 1000
        } else if (type === 'now') {
          params.publish_time = 1
        } else if (type === 'null') {
          params.publish_time = null
        } else if (type === 'timing') {
          params.publish_time = this.timingQuery.publish_time / 1000
        }
        if (this.query2.fixedInfo) params.fixed = 1
        delete params.user
        const res = await infoSave(params)
        if (res.success) {
          await this.delVisitedRoute(this.$route.path)
          this.$router.go(-1)
        } else {
          this.$message.error(res.msg)
        }
      },
      // 打开定时发布时间
      hanldeTiming() {
        this.dialogVisible = true
      },
      // 定时发布保存
      saveTiming(formName) {
        this.$refs.timingForm.$refs[formName].validate((valid) => {
          if (valid) {
            this.hanldeRelease('timing')
            this.cancel()
          }
        })
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.timingForm.resetFields()
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-source';
  #{$base}-container {
    #{$base}-tips {
      margin: 15px 0;
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-user {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
      span {
        margin: 0 10px;
      }
      b {
        color: $base-color-blue;
      }
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
