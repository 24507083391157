var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        { ref: "viewCall", attrs: { "label-width": "80px", model: _vm.query } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "首页栏目", prop: "client_link" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.query.client_link,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.query,
                      "client_link",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "query.client_link",
                },
              }),
              _c("p", { staticClass: "tips" }, [
                _vm._v("调用此链接可以将该内容列表添加到首页各内容栏目"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "引用链接", prop: "wap_link" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.query.wap_link,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.query,
                      "wap_link",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "query.wap_link",
                },
              }),
              _c("p", { staticClass: "tips" }, [
                _vm._v("调用此链接可以将该内容列表显示为独立页面"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }