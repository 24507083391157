var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Common", {
    attrs: { query1: _vm.query1, query2: _vm.query2, query3: _vm.query3 },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }