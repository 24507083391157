<template>
  <Common :query1="query1" :query2="query2" :query3="query3" />
</template>

<script>
  import mixins from './mixins'
  export default {
    mixins: [mixins],
  }
</script>
