import Layout from '@/vab/layouts'

const information = {
  path: '/info',
  name: 'Information',
  component: Layout,
  meta: {
    title: '发布',
    icon: 'article-fill',
  },
  children: [
    {
      path: 'flowManage',
      name: 'FlowManage',
      component: () => import('@/views/information/flowManage'),
      meta: {
        title: '信息流管理',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'alternative',
      name: 'Alternative',
      component: () => import('@/views/information/alternative'),
      meta: {
        hidden: true,
        title: '首页-混合备选型',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'alternative/addSourceList',
      name: 'AddSourceList',
      component: () => import('@/views/information/alternative/SourceList/add'),
      meta: {
        hidden: true,
        title: '编辑内容',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'alternative/editSourceList',
      name: 'EditSourceList',
      component: () =>
        import('@/views/information/alternative/SourceList/edit'),
      meta: {
        hidden: true,
        title: '编辑内容',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'addInformation',
      name: 'AddInformation',
      component: () => import('@/views/information/flowManage/addInformation'),
      meta: {
        hidden: true,
        title: '新建信息流',
      },
    },
    {
      path: 'addcolumn',
      name: 'AddColumn',
      component: () => import('@/views/information/flowManage/Column/add'),
      meta: {
        hidden: true,
        title: '新建内容-混合备用型',
      },
    },
    {
      path: 'addcommunity',
      name: 'AddCommunity',
      component: () => import('@/views/information/flowManage/Community/add'),
      meta: {
        hidden: true,
        title: '新建内容-论坛聚合型',
      },
    },
    {
      path: 'addshow',
      name: 'AddShow',
      component: () => import('@/views/information/flowManage/Show/add'),
      meta: {
        hidden: true,
        title: '新建内容-轻分享圈聚合型',
      },
    },
    {
      path: 'addportal',
      name: 'AddPortal',
      component: () => import('@/views/information/flowManage/Portal/add'),
      meta: {
        hidden: true,
        title: '新建内容-论坛文章',
      },
    },
    {
      path: 'addShowColumn',
      name: 'AddShowColumn',
      component: () => import('@/views/information/flowManage/ShowColumn/add'),
      meta: {
        hidden: true,
        title: '新建内容-轻分享备选型',
      },
    },
    {
      path: 'column',
      name: 'Column',
      component: () => import('@/views/information/flowManage/Column/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: 'Column',
        // title: '修改内容-混合备用型',
      },
    },
    {
      path: 'community',
      name: 'Community',
      component: () => import('@/views/information/flowManage/Community/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: 'Community',
        // title: '修改内容-论坛聚合型',
      },
    },
    {
      path: 'show',
      name: 'Show',
      component: () => import('@/views/information/flowManage/Show/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: 'Show',
        // title: '修改内容-轻分享圈聚合型',
      },
    },
    {
      path: 'portal',
      name: 'Portal',
      component: () => import('@/views/information/flowManage/Portal/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: 'Portal',
        // title: '修改内容-论坛文章',
      },
    },
    {
      path: 'showColumn',
      name: 'ShowColumn',
      component: () => import('@/views/information/flowManage/ShowColumn/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: 'ShowColumn',
        // title: '修改内容-轻分享备选型',
      },
    },
    {
      path: 'operation/edit',
      name: 'operation',
      component: () =>
        import('@/views/information/flowManage/Portal/operation/edit'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: '编辑-论坛文章',
      },
    },
  ],
}

export default information
