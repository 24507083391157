var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-information-container" },
    [
      _c("div", { staticClass: "add-information-title" }, [
        _vm._v("请选择信息流类型"),
      ]),
      _c("div", { staticClass: "add-information-info" }, [
        _vm._v(
          " 新建频道，可扩展用户的活动范围，我们为您提供了以下频道类型，请选择符合您社区的类型进行创建！ "
        ),
      ]),
      _vm._l(_vm.addItemList, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "div",
              { staticClass: "add-information-item" },
              [
                _c("el-image", {
                  staticClass: "add-information-item-image",
                  attrs: { src: item.image },
                }),
                _c("div", { staticClass: "add-information-item-content" }, [
                  _c(
                    "div",
                    { staticClass: "add-information-item-content-first" },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _c(
                    "div",
                    { staticClass: "add-information-item-content-second" },
                    [_vm._v(" " + _vm._s(item.desc) + " ")]
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClickAdd(item)
                      },
                    },
                  },
                  [_vm._v(" 创建 ")]
                ),
              ],
              1
            ),
            _c("el-divider"),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }