var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-portal-container" },
    [
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          handleCurrentChange: _vm.handleCurrentChange,
          handleEdit: _vm.handleEdit,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "content-info" },
                  [
                    _c("span", [_vm._v(_vm._s(row.title))]),
                    row.pics_arr.length
                      ? _c("el-image", {
                          attrs: {
                            "preview-src-list": [row.pics_arr[0].tburl],
                            src: row.pics_arr[0].tburl,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "publish_time",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("dateFormat")(row.publish_time, true)) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }