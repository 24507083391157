<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('回复')"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form ref="replyForm" :column="column" :query="query" ref-name="reply">
      <template #vestUser>
        <div class="vest" @click="handleVestList">
          <img alt="" class="head" :src="userInfo.head" />
          <span>{{ userInfo.name }}</span>
        </div>
      </template>
    </Form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveReply">
        {{ translateTitle('确定') }}
      </el-button>
    </span>
    <VestList ref="vestList" @setUserInfo="setUserInfo" />
  </el-dialog>
</template>

<script>
  import Form from '@/components/Form'
  import { translateTitle } from '@/utils/i18n'
  import { circleComment, infoComment } from '@/api/audit'
  import VestList from './vestList'
  export default {
    components: {
      Form,
      VestList,
    },
    data() {
      return {
        dialogVisible: false,
        val: {},
        userInfo: {
          head: require('@/assets/noavatar_small.gif'),
          name: '',
        },
        type: '',
        query: {
          content: null,
        },
        column: [
          {
            label: '元宇宙用户',
            type: 'slot',
            slotName: 'vestUser',
          },
          {
            label: '回复内容',
            prop: 'content',
            type: 'textarea',
            tips: '填写评论回复',
          },
        ],
      }
    },
    methods: {
      translateTitle,
      handleOpen(val, list, type) {
        this.val = val
        this.type = type
        if (list.length) this.userInfo = list[0]
        this.dialogVisible = true
      },
      // 获取元宇宙用户
      handleVestList() {
        this.$refs.vestList.handleOpen()
      },
      setUserInfo(val) {
        this.userInfo = val
      },
      // 确定
      async saveReply() {
        if (!this.query.content)
          return this.$message({
            type: 'error',
            message: '请输入评论内容！',
          })
        const params = {
          content_id: this.val.content_id,
          content: this.query.content,
          vest_user_id: this.userInfo.user_id,
          comment_id: this.val.id,
        }
        let res = {}
        if (this.type == 'circle') {
          res = await circleComment(params)
        } else {
          res = await infoComment(params)
        }
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `评论成功` : `评论失败`,
        })
        if (res.success) this.$emit('getTableData')
        this.dialogVisible = false
        this.$refs.replyForm.resetFields()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .vest {
    display: flex;
    align-items: center;
    cursor: pointer;
    .head {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 20px;
    }
  }
</style>
