<template>
  <el-dialog
    :append-to-body="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="translateTitle(title)"
    :visible.sync="dialogVisible"
    width="80%"
  >
    <el-row v-loading="loading">
      <el-col
        v-for="item in vestList"
        :key="item.user_id"
        :span="7"
        @click.native="handleSelectUser(item)"
      >
        <span
          class="head"
          :style="{ backgroundImage: `url(${item.head})` }"
        ></span>
        <div class="info-main">
          <div class="info">
            <span class="username">{{ item.name }}</span>
            <img alt="" class="level" :src="item.sex != 2 ? boy : girl" />
          </div>
          <span v-if="item.wallet_id" class="wallet-id">
            wallet_id: {{ item.wallet_id }}
          </span>
        </div>
        <span v-if="title === '元宇宙用户选择器'">
          <i>{{ translateTitle('发布') }}：{{ item.post }}</i>
          <i>{{ translateTitle('评论') }}：{{ item.post_comment }}</i>
        </span>
      </el-col>
    </el-row>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page="pageInfo.page"
      layout="total, prev, pager, next, jumper"
      :page-count="pageInfo.page_count"
      :page-size="pageInfo.step"
      :total="pageInfo.count"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { listUserVestPage, getVestList } from '@/api/audit'
  export default {
    data() {
      return {
        dialogVisible: false,
        title: '元宇宙用户选择器',
        vestList: [],
        loading: false,
        pageInfo: {
          page: 1,
          step: 20,
          count: 0,
          page_count: 0,
        },
        boy: require('../../../assets/icon_male.png'),
        girl: require('../../../assets/icon_female.png'),
      }
    },
    methods: {
      translateTitle,
      handleOpen(title) {
        this.dialogVisible = true
        if (title) this.title = title
        this.listUserVestPage()
      },
      // 获取元宇宙用户
      async listUserVestPage() {
        const params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        this.loading = true
        let res
        if (this.title === '元宇宙用户选择器') {
          res = await listUserVestPage(params)
        } else {
          res = await getVestList(params)
        }
        this.loading = false
        if (res.success) {
          this.vestList = res.list || []
          this.pageInfo.count = res.total
        }
      },
      // 选择用户
      handleSelectUser(val) {
        this.$emit('setUserInfo', val)
        this.dialogVisible = false
        setTimeout(() => {
          this.pageInfo.page = 1
        }, 500)
      },
      // 关闭
      handleClose(done) {
        done()
        setTimeout(() => {
          this.pageInfo.page = 1
        }, 500)
      },
      // 分页
      handleCurrentChange(value) {
        this.pageInfo.page = value
        this.listUserVestPage()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-col {
    display: flex;
    align-items: center;
    padding: 14px;
    margin: 10px 2%;
    cursor: pointer;
    background-color: #fbfbfb;
    border: 1px solid #e7e7e7;
    .head {
      position: relative;
      display: block;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      background-color: #00000060;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      border-radius: 20px;
    }
    .info-main {
      display: flex;
      flex-direction: column;
      .wallet-id {
        margin-top: 5px;
        font-size: 13px;
      }
    }
    .info {
      display: flex;
      align-items: center;
    }
    .username {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    i {
      margin-right: 8px;
      font-style: normal;
      color: $base-color-text-secondary;
    }
    .level {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 5px;
      vertical-align: middle;
      border-radius: 4px;
    }
  }
</style>
