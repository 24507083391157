const config = {
  labelList: [],
  loading: false,
  query: {
    title: '',
    pic_type: 4,
    pics: null,
    label: '',
    from: '',
    id: null,
    cat_id: null,
    tid: null,
    type: null,
    user_id: null,
  },
  listPicTypes: Object.freeze([
    {
      id: 1,
      label: '单图',
    },
    // {
    //   id: 2,
    //   label: '三图',
    // },
    // {
    //   id: 3,
    //   label: '宽图',
    // },
    {
      id: 4,
      label: '无图',
    },
  ]),
}

export default config
