var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.translateTitle("快捷评论"),
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "m-b-15",
          attrs: { gutter: 20 },
        },
        _vm._l(_vm.allVestList, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 10 } },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { placement: "bottom-start", trigger: "click" },
                  on: {
                    command: function ($event) {
                      return _vm.handleCommand($event, index)
                    },
                    "visible-change": _vm.visibleChange,
                  },
                },
                [
                  _c("span", {
                    staticClass: "head",
                    style: { backgroundImage: "url(" + item.head + ")" },
                  }),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { staticClass: "title", attrs: { disabled: "" } },
                        [_vm._v(" 元宇宙用户选择器 ")]
                      ),
                      _c("el-input", {
                        staticClass: "inputname",
                        attrs: { placeholder: "请输入马甲账号" },
                        on: { input: _vm.handleScreen },
                        model: {
                          value: _vm.downQuery.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.downQuery, "name", $$v)
                          },
                          expression: "downQuery.name",
                        },
                      }),
                      !_vm.screening
                        ? [
                            _c(
                              "el-dropdown-item",
                              {
                                staticClass: "second-title",
                                attrs: { disabled: "", divided: "" },
                              },
                              [_vm._v(" 最近常用马甲 ")]
                            ),
                            _vm._l(_vm.recentVestList, function (el, i) {
                              return _c(
                                "el-dropdown-item",
                                {
                                  key: el.user_id,
                                  attrs: { command: el, divided: i !== 0 },
                                },
                                [
                                  _c("span", {
                                    staticClass: "head",
                                    style: {
                                      backgroundImage: "url(" + el.head + ")",
                                    },
                                  }),
                                  _c("span", { staticClass: "username" }, [
                                    _vm._v(_vm._s(el.name)),
                                  ]),
                                  _c("img", {
                                    staticClass: "level",
                                    attrs: { alt: "", src: el.group_ico_src },
                                  }),
                                  _c("span", [
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(_vm.translateTitle("发布")) +
                                          "：" +
                                          _vm._s(el.post)
                                      ),
                                    ]),
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(_vm.translateTitle("评论")) +
                                          "：" +
                                          _vm._s(el.post_comment)
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                          ]
                        : _vm._e(),
                      !_vm.screening
                        ? _c(
                            "div",
                            { staticClass: "recommended second-title" },
                            [
                              _c("span", [_vm._v("推荐使用")]),
                              _c(
                                "el-link",
                                {
                                  attrs: { underline: false },
                                  on: { click: _vm.exchange },
                                },
                                [
                                  _vm._v(" 换一批 "),
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.vestList, function (el, i) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: el.id,
                            attrs: { command: el, divided: i !== 0 },
                          },
                          [
                            _c("span", {
                              staticClass: "head",
                              style: {
                                backgroundImage: "url(" + el.head + ")",
                              },
                            }),
                            _c("span", { staticClass: "username" }, [
                              _vm._v(_vm._s(el.name)),
                            ]),
                            _c("img", {
                              staticClass: "level",
                              attrs: { alt: "", src: el.group_ico_src },
                            }),
                            _c("span", [
                              _c("i", [
                                _vm._v(
                                  _vm._s(_vm.translateTitle("发布")) +
                                    "：" +
                                    _vm._s(el.post)
                                ),
                              ]),
                              _c("i", [
                                _vm._v(
                                  _vm._s(_vm.translateTitle("评论")) +
                                    "：" +
                                    _vm._s(el.post_comment)
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("el-input", {
                attrs: { placeholder: item.name },
                model: {
                  value: item.content,
                  callback: function ($$v) {
                    _vm.$set(item, "content", $$v)
                  },
                  expression: "item.content",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm.isRecoveryRate
        ? _c(
            "div",
            { staticClass: "recoveryRate" },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.recoveryRate,
                    callback: function ($$v) {
                      _vm.recoveryRate = $$v
                    },
                    expression: "recoveryRate",
                  },
                },
                [_vm._v("指定回复速率")]
              ),
              _vm.recoveryRate
                ? [
                    _c("el-input", {
                      attrs: { placeholder: "请输入分钟数" },
                      model: {
                        value: _vm.public_minute,
                        callback: function ($$v) {
                          _vm.public_minute = $$v
                        },
                        expression: "public_minute",
                      },
                    }),
                    _c("span", [_vm._v("分钟内全部发布")]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("p", { staticClass: "tip" }, [
        _vm._v(
          " 提示：第一条评论1分钟内生效,其他评论会按照半小时到一个半小时间隔发布 "
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveComment } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("确定")) + " ")]
          ),
          _vm.allVestList.length > 4
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.changeBatch } },
                [_vm._v(" " + _vm._s(_vm.translateTitle("换一批")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }