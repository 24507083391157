<template>
  <div class="likes-container">
    <div class="likes-title">点赞</div>
    <div class="likes-random">
      <el-button size="medium" type="primary" @click="handleLikesRandom">
        随机点赞
      </el-button>
      <div class="likes-tips">
        （提交后第一条点赞1分钟内生效，其他点赞会按照半小时到一个
        半小时间隔发布，请务必为您的马甲上传头像，避免列表中出现空头像的情况，
        提示：未通过审核的主题，点赞与评论操作以及加精、置顶、打赏设置将会在审核通过之后生效）
      </div>
    </div>
    <div class="likes-operate">
      <el-link type="primary" :underline="false">
        <span @click="handleChecked('all')">全选</span>
        <i class="bias">/</i>
        <span @click="handleChecked('unAll')">全不选</span>
      </el-link>
      <el-link type="primary" :underline="false">
        <span @click="handleChecked('girl')">全选女性</span>
        <i class="bias">/</i>
        <span @click="handleChecked('boy')">全选男性</span>
      </el-link>
      <el-button
        size="small"
        type="primary"
        @click="handleLikesRandom('multiple')"
      >
        批量点赞
      </el-button>
    </div>
    <el-checkbox-group v-model="checkList">
      <el-checkbox
        v-for="item in vestUserList"
        :key="item.user_id"
        :label="item.user_id"
      >
        <span>{{ item.name }}</span>
        <img alt="" :src="item.sex == 1 ? boy : item.sex == 2 ? girl : ''" />
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>
<script>
  import { randomApplaudTheme, getVestUserAllList } from '@/api/audit'
  export default {
    props: {
      id: {
        type: [Number, String],
        default: 0,
      },
      themetype: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        checkList: [],
        vestUserList: [],
        boy: require('@/assets/icon_male.png'),
        girl: require('@/assets/icon_female.png'),
      }
    },
    created() {
      this.getVestUserAllList()
    },
    methods: {
      // 随机点赞/批量点赞
      async handleLikesRandom(type) {
        const params = {
          themetype: this.themetype,
          theme_id: this.id,
        }
        if (type === 'multiple') {
          if (this.checkList.length) {
            params.vest_list = this.checkList.join(',')
          } else {
            return this.$message.error('请至少选择一个用户进行操作')
          }
        }
        const res = await randomApplaudTheme(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `点赞成功` : `点赞失败`,
        })
      },
      // 获取元宇宙用户
      async getVestUserAllList(params) {
        const res = await getVestUserAllList(params)
        if (res.success) this.vestUserList = res.list
      },
      // 选择
      handleChecked(type) {
        if (type === 'unAll') return (this.checkList = [])
        const checkList = this.vestUserList.filter((item) => {
          if (type === 'all') return item
          if (type === 'boy' && item.sex == 1) return item
          if (type === 'girl' && item.sex == 2) return item
        })
        this.checkList = checkList.map((item) => {
          return item.user_id
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  $base: '.likes';
  #{$base}-container {
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-random {
      display: flex;
      align-items: flex-start;
      button {
        flex-shrink: 0;
      }
      #{$base}-tips {
        margin-left: 20px;
        font-size: 13px;
        line-height: 20px;
        color: #909399;
      }
    }
    #{$base}-operate {
      margin: 15px 0;
      .el-link {
        margin-right: 20px;
        .bias {
          margin: 0 5px;
        }
      }
    }
    .el-checkbox-group {
      .el-checkbox {
        width: calc(20% - 30px);
        margin-bottom: 10px;
        img {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }
  }
</style>
