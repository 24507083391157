var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-column-container" },
    [
      _c("div", { staticClass: "add-column-tips m-b" }, [
        _vm._v("配置内容的来源和规则。"),
      ]),
      _c("Form", {
        attrs: { column: _vm.column, query: _vm.query, "ref-name": "basic" },
        on: { handleImageUrl: _vm.handleImageUrl },
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "add-column-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "medium", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }