<template>
  <div class="tab-info">
    <el-button class="call" @click="viewCall">调用链接</el-button>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="内容管理" name="1">
        <Manage :detail-info="detailInfo" />
      </el-tab-pane>
      <!-- <el-tab-pane label="栏目配置" name="2">
        <Common :query1="query1" :query2="query2" />
      </el-tab-pane> -->
    </el-tabs>
    <ViewCallDialog ref="viewCall" />
  </div>
</template>

<script>
  import mixins from './mixins'
  import { mapActions } from 'vuex'
  import { getConfigDetail } from '@/api/information'
  export default {
    mixins: [mixins],
    created() {
      this.handleQuery()
      if (this.$route.query.id) this.getConfigDetail()
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        changeMenuMeta: 'routes/changeMenuMeta',
      }),
      handleQuery() {
        let title = this.$route.query.title
        this.$nextTick(() => {
          this.changeTabsMeta({
            title: 'Portal',
            meta: {
              title: title,
            },
          })
          this.changeMenuMeta({
            name: 'Portal',
            meta: {
              title: title,
            },
          })
        })
      },
      // 获取信息数据
      getConfigDetail() {
        let params = { id: this.$route.query.id }
        getConfigDetail(params).then((res) => {
          this.type = res.data.type
          // query1
          this.query1.name = res.data.name
          this.query1.type = res.data.type
          this.query1.cover_set = res.data.config.cover_set
          // query2
          this.query2.forum_portal_id = res.data.config.forum_portal_id
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-tabs {
    ::v-deep .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px;
      }
      .el-tabs__item {
        height: 60px;
        line-height: 60px;
      }
    }
  }
  .tab-info {
    position: relative;
    .call {
      position: absolute;
      left: 20px;
      top: 14px;
      z-index: 1;
    }
  }
</style>
