<template>
  <div class="add-video-container">
    <Form
      :column="column"
      :label-width="130"
      :query="params"
      @createRandom="createRandom"
      @handleImageUrl="handleImageUrl"
      @handleVideoUrl="handleVideoUrl"
    >
      <template #videoTime>
        <el-row type="flex">
          <el-col :span="4">
            <el-input
              v-model="params.duration_h"
              clearable
              placeholder="请输入时"
            />
          </el-col>
          <el-col align="center" :span="1">h</el-col>
          <el-col :span="4">
            <el-input
              v-model="params.duration_i"
              clearable
              placeholder="请输入分"
            />
          </el-col>
          <el-col align="center" :span="1">m</el-col>
          <el-col :span="4">
            <el-input
              v-model="params.duration_s"
              clearable
              placeholder="请输入秒"
            />
          </el-col>
          <el-col align="center" :span="1">s</el-col>
        </el-row>
      </template>
    </Form>
    <el-divider />
    <div class="add-video-btn">
      <el-button
        v-if="$route.query.id"
        size="medium"
        type="primary"
        @click="handleRelease('save')"
      >
        保存
      </el-button>
      <template v-if="isBtn">
        <el-button size="medium" type="primary" @click="handleRelease('now')">
          立即发布
        </el-button>
        <el-button size="medium" @click="handleRelease('null')">
          存为草稿
        </el-button>
      </template>
      <el-button
        v-if="!$route.query.id"
        icon="el-icon-pie-chart"
        size="medium"
        type="primary"
        @click="hanldeTiming"
      >
        定时发布
      </el-button>
    </div>
    <!-- 定时发布--对话框 -->
    <ReleaseDialog ref="releaseDialog" @handleRelease="handleRelease" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { mapActions } from 'vuex'
  import Form from '@/components/Form'
  import ReleaseDialog from '@/components/ReleaseDialog'
  import config from './config'
  import {
    allAlbumPage,
    contentColumnPage,
    videoEdit,
    videoAdd,
    checkSensitiveContent,
  } from '@/api/video'
  export default {
    name: 'Column',
    components: {
      Form,
      ReleaseDialog,
    },
    props: {
      query: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        ...config,
        params: this.query,
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      isBtn() {
        return (
          !this.$route.query.id ||
          this.params.is_draft == 1 ||
          this.params.timing / 1000 > this.params.now_time
        )
      },
      column() {
        return [
          {
            label: '付费模式',
            prop: 'pay_type',
            type: 'radio',
            list: this.paymentOption,
          },
          {
            label: '虚拟币数量',
            prop: 'pay_amount',
            clickText: this.config.virtual_currency_unit,
            tips:
              this.params.pay_type == 2
                ? '<span>付费视频仅支持虚拟币支付，会员可免费观看此付费视频</span>'
                : this.params.pay_type == 3
                ? '<span>付费视频仅支持虚拟币支付，会员也需要付费观看此视频</span>'
                : '',
            isHide: this.params.pay_type != 2 && this.params.pay_type != 3,
          },
          {
            label: '视频上传形式',
            prop: 'code_source',
            type: 'radio',
            list: this.videoUploadList(),
          },
          {
            label: '代码地址',
            prop: 'video_url',
            type: 'textarea',
            tips: '格式如：&lt;iframe height=498 width=510 src="http://player.youku.com/embed/XNDAyMjAwODQ4NA==" frameborder=0 "allowfullscreen"&gt;&lt;/iframe&gt;  可在三方平台查看获取',
            isHide: this.params.code_source == 6,
          },
          {
            label: '本地视频',
            prop: 'local_video_url1',
            type: 'uploadVideo',
            eventName: 'handleVideoUrl',
            isHide: this.params.code_source != 6,
            tips: '上传视频仅支持MP4格式',
          },
          {
            label: '视频时间',
            type: 'slot',
            slotName: 'videoTime',
            tips: '格式如:1 h 2 m 22 s，纯数字且最前面无需加0',
          },
          {
            label: '视频板式',
            prop: 'play_style',
            type: 'radio',
            list: this.videoStyleList(),
            tips: '选择竖版视频后，用户点击全屏可以竖屏全屏播放',
          },
          {
            label: '发布形式',
            type: 'slot',
            tips: '代码调用',
          },
          {
            label: '视频封面',
            prop: 'cover_pic',
            type: 'uploadImage',
            width: 200,
            height: 118,
            eventName: 'handleImageUrl',
            tips: '建议尺寸：760x452px',
          },
          {
            label: '视频标题',
            prop: 'title',
            tips: '限32个字内',
          },
          {
            label: '视频描述',
            prop: 'desc',
            type: 'textarea',
            tips: '限500个字内',
          },
          {
            label: '视频来源',
            prop: 'source',
          },
          {
            label: '所属专辑',
            prop: 'album_id',
            type: 'select',
            optionLabel: 'name',
            list: this.albumOption,
          },
          {
            label: '所属栏目',
            prop: 'column_id',
            type: 'checkbox',
            optionLabel: 'name',
            list: this.columnOption,
            // tips: '选择与内容相符的栏目有助于用户找到，没有合适的栏目？',
            // clickText: '点击设置',
            // clickEventName: 'handleSet',
          },
          {
            label: '发布时间',
            prop: 'timing',
            type: 'datetime',
            isHide: this.params.timing / 1000 <= this.params.now_time,
          },
          {
            label: '排序时间',
            prop: 'sort_time',
            type: 'datetime',
            isHide: !this.$route.query.id,
          },
          {
            label: '强制客户端内查看',
            prop: 'is_see_in_client',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '是否开启试看',
            prop: 'is_open_try_see',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '是否限制游客观看',
            prop: 'is_limit_tourist',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '防录屏盗播',
            prop: 'is_inscreen_record',
            type: 'radio',
            list: this.whetherList3,
            isHide: this.params.code_source == 1,
          },
          {
            label: '虚拟播放量',
            prop: 'auto_click',
            clickText: '重新生成',
            clickEventName: 'createRandom',
            tips: '填写每天增加的播放量，格式如：103,31,42,51,121 中间以英文逗号隔开，表示从发布时间开始，第一天增加103播放，</br>第二天增加31播放，第三天增加42播放，第四天增加51播放，第五天增加121播放</br>第1天自动虚拟阅读108,第2天自动虚拟阅读105,第3天自动虚拟阅读110,第4天自动虚拟阅读107,第5天自动虚拟阅读84',
          },
          {
            label: '视频上传形式',
            prop: 'try_see_code_source',
            type: 'radio',
            list: this.videoUploadOption1,
            isHide: this.params.is_open_try_see != 1,
          },
          {
            label: '代码地址',
            prop: 'try_see_video_url',
            type: 'textarea',
            isHide: !(
              this.params.is_open_try_see == 1 &&
              this.params.try_see_code_source == 2
            ),
          },
          {
            label: '本地视频',
            prop: 'local_video_url2',
            type: 'uploadVideo',
            eventName: 'handleVideoUrl',
            isHide: !(
              this.params.is_open_try_see == 1 &&
              this.params.try_see_code_source == 6
            ),
          },
        ]
      },
    },
    created() {
      this.allAlbumPage()
      this.contentColumnPage()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 获取所属专辑
      async allAlbumPage() {
        const res = await allAlbumPage()
        if (res.success) {
          this.albumOption = Object.freeze([
            {
              id: 0,
              name: '请选择所属专辑',
            },
          ])
          this.albumOption = this.albumOption.concat(res.list)
        }
      },
      // 获取所属栏目
      async contentColumnPage() {
        const res = await contentColumnPage()
        if (res.success) {
          this.columnOption = res.list
        }
      },
      // 视频上传方式过滤
      videoUploadList() {
        if (this.params.pay_type != 1) {
          return this.videoUploadOption.filter((item) => item.status != 1)
        }
        return this.videoUploadOption
      },
      // 视频板式过滤
      videoStyleList() {
        if (this.params.code_source == 1) {
          return this.videoStyleOption.filter((item) => item.status != 1)
        }
        return this.videoStyleOption
      },
      createRandom() {
        this.$emit('createRandom')
      },
      // 打开定时发布时间
      hanldeTiming() {
        this.$refs.releaseDialog.handleOpen()
      },
      // 上传图片
      handleImageUrl(prop, data) {
        this.$emit('handleImageUrl', prop, data)
      },
      // 获取视频上传地址
      handleVideoUrl(prop, query) {
        this.$emit('handleVideoUrl', prop, query)
      },
      // 发布
      async handleRelease(type, value) {
        const params = Object.assign({}, this.params)
        if (type === 'save') {
          params.sort_time = params.sort_time / 1000
          params.timing = params.timing / 1000
        }
        params.column_id = params.column_id.join('#')
        params.desc = params.desc.substr(0, 500)
        params.time = value ? value / 1000 : 1
        if (!params.id) delete params.id
        if (params.local_video_url1) params.video_url = params.local_video_url1
        if (params.local_video_url2)
          params.try_see_video_url = params.local_video_url1
        // 校验字段是否为空
        if (!this.verification(params)) return
        // 校验敏感词
        const query = {
          title: params.title,
          content: params.desc,
          source: params.source,
        }
        const rs = await checkSensitiveContent(query)
        if (rs.word == 2) {
          return this.$message.error(
            `当前发布内容存在敏感词：${rs.word_content}'请立即修改`
          )
        }
        // 发起存储数据
        let res
        // 修改
        if (type === 'save') {
          res = await videoEdit(params)
          // 新增
        } else if (type === 'now') {
          if (!params.id) {
            params.time = 1
            res = await videoAdd(params)
          } else {
            params.time = 2
            params.sort_time = params.sort_time / 1000
            params.timing = new Date().getTime() / 1000
            res = await videoEdit(params)
          }
          // 草稿
        } else if (type == 'null') {
          params.time = -1
          if (!params.id) {
            res = await videoAdd(params)
          } else {
            params.sort_time = params.sort_time / 1000
            res = await videoEdit(params)
          }
          // 定时任务
        } else if (type == 'timing') {
          res = await videoAdd(params)
        }
        if (res.success) {
          await this.delVisitedRoute(this.$route.path)
          this.$router.go(-1)
          this.$message.success('保存成功')
        } else {
          this.$message.error(res.msg)
        }
      },
      verification(params) {
        if (!params.title) {
          this.$message.error('请填写标题')
          return false
        }
        if (!params.cover_pic) {
          this.$message.error('请上传封面图片')
          return
        }
        if (!params.desc) {
          this.$message.error('请填写视频描述')
          return
        }
        if (!params.source) {
          this.$message.error('请填写视频来源')
          return
        }
        if (!params.video_url && !params.local_video_url1) {
          this.$message.error('请填写代码地址')
          return
        }
        if (!params.duration_h && !params.duration_i && !params.duration_s) {
          this.$message.error('请填写视频时长长度')
          return
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-video';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
