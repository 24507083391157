const config = {
  dialogVisible: false,
  forumList: [],
  showList: [],
  // 封面配置
  coverConfigList: Object.freeze([
    {
      id: 1,
      label: '居左',
    },
    {
      id: 2,
      label: '居右',
    },
  ]),
  // 封面样式配置
  coverStyleList: Object.freeze([
    {
      id: 1,
      label: '直角',
    },
    {
      id: 2,
      label: '圆角',
    },
  ]),
  // 排序方式
  sortList: Object.freeze([
    {
      id: 1,
      label: '根据排序时间(后台添加内容的时间)排序',
    },
    {
      id: 3,
      label: '根据抓取时间排序',
    },
    {
      id: 4,
      label: '根据发布时间排序',
    },
    {
      id: 2,
      label: '随机排序',
    },
  ]),
  // 内容范围
  contentScopeList: Object.freeze([
    {
      id: 300,
      label: '300',
    },
    {
      id: 500,
      label: '500',
    },
    {
      id: 1000,
      label: '1000',
    },
  ]),
  // 时间范围
  timeScopeList: Object.freeze([
    {
      id: 1,
      label: '过去一天',
    },
    {
      id: 7,
      label: '过去七天',
    },
    {
      id: 0,
      label: '自定义',
    },
  ]),
  // 样式选择
  styleSelectionList: Object.freeze([
    {
      id: 1,
      label: '资讯样式',
    },
    {
      id: 2,
      label: '自适应样式',
    },
  ]),
  // 时间
  timeList: Object.freeze([
    {
      id: 0,
      label: '不限',
    },
    {
      id: 1,
      label: '24小时内',
    },
    {
      id: 3,
      label: '3天内',
    },
    {
      id: 5,
      label: '5天内',
    },
    {
      id: 7,
      label: '7天内',
    },
    {
      id: 15,
      label: '15天内',
    },
    {
      id: 30,
      label: '30天内',
    },
  ]),
  // 是否
  whetherList1: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  // 是否
  whetherList2: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 0,
      label: '否',
    },
  ]),
  // 保存数
  saveContentList: Object.freeze([
    {
      id: 500,
      label: '500',
    },
    {
      id: 800,
      label: '800',
    },
    {
      id: 1000,
      label: '1000',
    },
  ]),
  // 启用项
  enableList: [
    {
      image: require('@/assets/images/information/info_icon_dock_bbs.png'),
      title: '对接论坛',
      desc: '对接论坛特定版块，并且设定相关规则的触发条件。<span>配置规则保存后，不会立即出现内容。需要有用户访问对应的内容详情并且满足条件时才会被触发</span>',
      isEnable: false,
    },
    // {
    //   image: require('@/assets/images/information/info_icon_dock_show.png'),
    //   title: '对接轻分享',
    //   desc: '对接特定的轻分享圈，并且设定相关规则的触发条件',
    //   isEnable: false,
    // },
    // {
    //   image: require('@/assets/images/information/info_icon_dock_wxpublic.png'),
    //   title: '对接公众号',
    //   desc: '对接公众号内容，可设定对接公众号和对接时间.<span>保存后内容不会立即同步，每隔1h会抓取一次文章更新，初次抓取会抓取10天以内的文章。内容抓取需要有用户使用app</span>',
    //   isEnable: false,
    // },
  ],
}

export default config
