var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-community-container" },
    [
      _c("SearchForm", {
        attrs: { query: _vm.query, "search-column": _vm.searchColumn },
        on: {
          getTableData: _vm.getTableData,
          handleChangeStatus: _vm.handleChangeStatus,
          resetForm: _vm.resetForm,
        },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          "is-index": true,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          selection: true,
          "table-data": _vm.list,
        },
        on: {
          handleCurrentChange: _vm.handleCurrentChange,
          handleDelete: _vm.handleDelete,
          handleEdit: _vm.handleEdit,
          handleMove: _vm.handleMove,
          handlePreview: _vm.handlePreview,
          handleRefresh: _vm.handleRefresh,
          handleRelease: _vm.handleRelease,
          handleSetFixed: _vm.handleSetFixed,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  {
                    staticClass: "content-info",
                    style: {
                      display:
                        row.pic_type != 4 && row.pics_arr.length > 1
                          ? "block"
                          : "flex",
                    },
                  },
                  [
                    _c("ul", [
                      _c(
                        "li",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "content-info-title",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.jumpDetail(row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(row.title) + " ")]
                          ),
                          row.type === 1 || row.type === 2 || row.type === 3
                            ? _c("el-tag", { attrs: { effect: "plain" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.itemDataType(row.type)) + " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("span", { staticClass: "content-info-from" }, [
                          _vm._v(_vm._s(row.from)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "text-secondary",
                            staticStyle: { "margin-left": "20px" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(row.publish_time, true)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(_vm._s(row.click_show) + "阅读"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "text-secondary",
                            staticStyle: { "margin-left": "20px" },
                          },
                          [_vm._v(" id: " + _vm._s(row.id) + " ")]
                        ),
                      ]),
                    ]),
                    row.pic_type != 4 && row.pics_arr.length
                      ? _vm._l(row.pics_arr, function (item, index) {
                          return _c("el-image", {
                            key: index,
                            attrs: {
                              "preview-src-list": [
                                item.tburl.slice(0, 1) === "/"
                                  ? _vm.baseURL + item.tburl
                                  : item.tburl,
                              ],
                              src:
                                item.tburl.slice(0, 1) === "/"
                                  ? _vm.baseURL + item.tburl
                                  : item.tburl,
                            },
                          })
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "other",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v("推送者：")]),
                _c("span", [_vm._v(_vm._s(row.referrer_user_name))]),
                _vm.detailInfo.sort_type == 1 || _vm.detailInfo.sort_type == 2
                  ? _c("div", [
                      _c("span", [_vm._v("排序时间：")]),
                      _c("span", { staticClass: "text-secondary" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFormat")(row.rank_time, true)) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                row.fixedInfo.position
                  ? _c("div", [
                      _c("span", [_vm._v("固定位置：")]),
                      _c("div", [
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(_vm._s(row.fixedInfo.position)),
                        ]),
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(_vm._s(row.fixed_count)),
                        ]),
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  row.fixedInfo.end_time,
                                  true
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(_vm._s(row.fixedInfo.user.name)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("PreviewDialog", { ref: "previewDialog" }),
      _c("MoveDialog", { ref: "moveDialog" }),
      _c("SetFixedDialog", {
        ref: "setFixedDialog",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }