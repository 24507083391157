<template>
  <div class="push-list-container">
    <SearchForm
      label-width="60"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCancelPush="handleCancelPush"
      @handleCurrentChange="handleCurrentChange"
      @handleEdit="handleEdit"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <span v-if="row.title">[{{ row.title }}]{{ row.content }}</span>
        <span v-else>{{ row.content }}</span>
      </template>
      <!-- 状态 -->
      <template #type="{ row }">
        <div v-if="row.has_push == 1">
          <el-tag v-if="row.is_successful" type="success">
            {{ translateTitle('成功') }}
          </el-tag>
          <el-tag v-else type="danger">{{ translateTitle('失败') }}</el-tag>
        </div>
        <div v-else-if="row.has_push == -1">--</div>
        <div v-else-if="row.has_push == -2">{{ translateTitle('已撤销') }}</div>
      </template>
      <!-- 定时推送 -->
      <template #is_timing="{ row }">
        <span>
          {{ row.is_timing == 1 ? translateTitle('是') : translateTitle('否') }}
        </span>
      </template>
      <!-- 推送时间 -->
      <template #push_time="{ row }">
        <span>{{ row.push_time | dateFormat(true) }}</span>
      </template>
      <!-- 创建时间 -->
      <template #create_time="{ row }">
        <span>{{ row.create_time | dateFormat(true) }}</span>
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import { getPushList, cancelPush } from '@/api/operate'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      SearchForm,
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        // 表格最大高度
        maxHeight: document.body.clientHeight - 340,
        query: {
          type: 1,
          keywords: null,
        },
        typeOptions: Object.freeze([
          {
            id: 1,
            label: '全部推送',
          },
          {
            id: 2,
            label: '已发布推送',
          },
          {
            id: 3,
            label: '待发布推送',
          },
        ]),
      }
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '状态',
            prop: 'type',
            type: 'select',
            clearable: false,
            selectList: this.typeOptions,
          },
          { label: '关键字', prop: 'keywords' },
        ]
      },
      columnList() {
        return [
          { label: '内容', slotName: 'content' },
          { label: '状态', slotName: 'type' },
          { label: '返回信息', prop: 'phone_information' },
          { label: '定时推送', slotName: 'is_timing' },
          { label: '推送时间', slotName: 'push_time' },
          { label: '创建时间', slotName: 'create_time' },
          {
            label: '操作',
            isButton: true,
            btnList: [
              {
                label: '详情',
                type: 'primary',
                eventName: 'handleEdit',
              },
              {
                label: '撤销推送',
                type: 'danger',
                eventName: 'handleCancelPush',
                showName: 'has_push',
                showStatusList: [-1],
              },
            ],
          },
        ]
      },
    },
    created() {
      this.getTableData()
    },
    methods: {
      translateTitle,
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getPushList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      // 编辑
      handleEdit(val) {
        this.$prompt('跳转链接', '详情', {
          showCancelButton: false,
          showConfirmButton: false,
          inputValue: val.jump_value,
        }).catch(() => {})
      },
      // 撤销推送
      handleCancelPush(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              id: val.id,
            }
            const res = await cancelPush(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.push-list';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
</style>
