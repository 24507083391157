import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA } from '@/config'

const secondUrl = '/metatime/user/v1/'

export async function login(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: `${secondUrl}adminUser/login`,
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: `${secondUrl}adminUser/userInfo`,
    method: 'get',
  })
}
// 获取七牛token
export function getQNToken() {
  return request({
    url: '/core/attachment/attachment/getAdminQiNiuToken',
    method: 'get',
  })
}
// 上传图片到七牛
export function uploadQiNiuPic(params) {
  return request({
    url: '/core/attachment/attachment/uploadQiNiuPic',
    method: 'get',
    params,
  })
}
// 上传视频到七牛
export function uploadQiNiuVideo(params) {
  return request({
    url: '/core/attachment/attachment/uploadQiNiuAdminVideo?',
    method: 'get',
    params,
  })
}
export function logout() {
  return request({
    url: `${secondUrl}user/logout`,
    method: 'get',
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}
// 获取用户列表
export function getUserList(params) {
  return request({
    url: `${secondUrl}adminUser/userManagePage?`,
    method: 'get',
    params,
  })
}
// 获取登录用户配置
export function getConfigs(params) {
  return request({
    url: '/metatime/config/v1/admin/configs',
    method: 'get',
    params,
  })
}
// 获取用户列表
export function getUserPage(params) {
  return request({
    url: '/metatime/user/v1/adminUser/userPage',
    method: 'get',
    params,
  })
}
// 获取用户权限列表
export function getRolelist(params) {
  return request({
    url: '/metatime/user/v1/adminUser/rolelist',
    method: 'get',
    params,
  })
}
// 用户权限列表刷新缓存
export function refreshRole(params) {
  return request({
    url: '/metatime/user/v1/adminUser/refreshRole',
    method: 'get',
    params,
  })
}
// 添加权限
export function roleSave(data) {
  return request({
    url: '/metatime/user/v1/adminUser/roleSave',
    method: 'post',
    data,
  })
}
// 删除权限
export function roleDel(data) {
  return request({
    url: '/metatime/user/v1/adminUser/roleDel',
    method: 'post',
    data,
  })
}
// 获取用户菜单权限
export function getMenuRolelist(params) {
  return request({
    url: '/metatime/user/v1/adminUser/menuRolelist',
    method: 'get',
    params,
  })
}
// 保存菜单
export function menuRoleSave(data) {
  return request({
    url: '/metatime/user/v1/adminUser/menuRoleSave',
    method: 'post',
    data,
  })
}
// 获取用户组列表
export function getGroupListByType(params) {
  return request({
    url: '/metatime/user/v1/GradeScore/groupListByType',
    method: 'get',
    params,
  })
}
// 获取用户信息
export function getUserView(data) {
  return request({
    url: '/metatime/user/v1/adminUser/userView',
    method: 'post',
    data,
  })
}
// 用户禁言
export function userMute(params) {
  return request({
    url: '/metatime/user/v1/user/userAdminManage',
    method: 'get',
    params,
  })
}
// 取消禁言
export function cancleUserMute(data) {
  return request({
    url: '/metatime/user/v1/user/cancleBannedUserPost',
    method: 'post',
    data,
  })
}
// 用户禁止私聊
export function bannedPrivate(params) {
  return request({
    url: '/metatime/user/v1/user/bannedPrivateChat',
    method: 'get',
    params,
  })
}
// 查询是否被禁止私聊
export function isbannedPrivateChat(data) {
  return request({
    url: '/metatime/user/v1/user/isbannedPrivateChat',
    method: 'post',
    data,
  })
}
// 恢复私聊
export function cancleBatchUserBanned(data) {
  return request({
    url: '/metatime/user/v1/user/cancleBannedPrivateChat',
    method: 'post',
    data,
  })
}
// 批量禁言
export function batchUserBanned(data) {
  return request({
    url: '/metatime/user/v1/adminUser/batchUserBanned',
    method: 'post',
    data,
  })
}
// 设为马甲
export function batchVerstUser(data) {
  return request({
    url: '/metatime/user/v1/adminUser/batchVerstUserAdd',
    method: 'post',
    data,
  })
}
// 获取管理员身份
export function getRoleList(params) {
  return request({
    url: '/metatime/user/v1/adminUser/rolelist',
    method: 'get',
    params,
  })
}
// 设为管理员
export function adminUserAdd(data) {
  return request({
    url: '/metatime/user/v1/adminUser/adminUserAdd',
    method: 'post',
    data,
  })
}
// 取消管理员
export function adminUserDel(params) {
  return request({
    url: '/metatime/user/v1/adminUser/adminUserDel',
    method: 'get',
    params,
  })
}
// 删除
export function batchUserDelete(data) {
  return request({
    url: '/metatime/user/v1/adminUser/batchUserDelete',
    method: 'post',
    data,
  })
}
// 保存菜单
export function groupsSave(data) {
  return request({
    url: '/metatime/user/v1/GradeScore/groupsSave',
    method: 'post',
    data,
  })
}
// 查询用户组配置
export function getUserGroup(params) {
  return request({
    url: '/metatime/user/v1/GradeScore/getUserGroup',
    method: 'get',
    params,
  })
}
// 保存配置
export function saveUserGroup(data) {
  return request({
    url: '/metatime/user/v1/GradeScore/saveUserGroup',
    method: 'post',
    data,
  })
}
