import request from '@/utils/request'
// 获取信息流列表
export function getInforMationList(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catgroupPage',
    method: 'get',
    params,
  })
}
// 设置常驻/取消
export function editCatPermanent(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/editCatPermanent',
    method: 'post',
    data,
  })
}
// 获取来源板块
export function getForumList(params) {
  return request({
    url: '/metatime/circle/v1/adminForum/forumList',
    method: 'get',
    params,
  })
}
// 获取来源圈子
export function getShowList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/showList',
    method: 'get',
    params,
  })
}
// 获取来源话题
export function getAllTopicPageList(params) {
  return request({
    url: '/metatime/circle/v1/adminTopic/allTopicPage',
    method: 'get',
    params,
  })
}
// 保存信息流
export function catSave(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catSave',
    method: 'post',
    data,
  })
}
// 删除信息流
export function catDelete(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catDelete',
    method: 'post',
    data,
  })
}
export function catSyncThreadInfo(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catSyncThreadInfo',
    method: 'get',
    params,
  })
}
export function catSyncShowInfo(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catSyncShowInfo',
    method: 'get',
    params,
  })
}
// 修改排序
export function updateSort(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catUpdateSort',
    method: 'get',
    params,
  })
}
// 配置获取详情
export function getConfigDetail(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catDetail',
    method: 'get',
    params,
  })
}
// 获取内容状态
export function getContentStatusOptions(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/getContentStatusOptions',
    method: 'get',
    params,
  })
}
// 获取混合备选型内容列表
export function getInfoPage(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/infoPage',
    method: 'get',
    params,
  })
}
// 获取论坛聚合型内容列表
export function getCommunityPage(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/communityPage',
    method: 'get',
    params,
  })
}
// 获取轻分享聚合内容列表
export function getCommunityShowPage(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/communityShowPage',
    method: 'get',
    params,
  })
}
// 获取论坛文章列表
export function getPortalPage(params) {
  return request({
    url: 'mag/info/v1/infoAdmin/portalPage',
    method: 'get',
    params,
  })
}
// 获取用户
export function getAdminUserPage(params) {
  return request({
    url: '/metatime/user/v1/adminUser/adminUserPage',
    method: 'get',
    params,
  })
}
// 获取是否固定列表
export function getFixedPosition(params) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/fixedSelectPage',
    method: 'get',
    params,
  })
}
// 获取固定位置
export function getFixedPage(params) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/fixedPage',
    method: 'get',
    params,
  })
}
// 获取固定内容
export function getFixedTitle(params) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/getFixedTitle',
    method: 'get',
    params,
  })
}
// 获取固定信息
export function getFixedInfo(params) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/getFixedInfo',
    method: 'get',
    params,
  })
}
// 刷新列表缓存
export function refreshIndexCache(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/refreshIndexCache',
    method: 'post',
    data,
  })
}
// 保存固定位置
export function saveFixed(data) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/saveFixed',
    method: 'post',
    data,
  })
}
// 取消固定
export function cancelInfoFixed(data) {
  return request({
    url: '/metatime/info/v1/fixedAdmin/cancelInfoFixed',
    method: 'post',
    data,
  })
}
// 获取内容
export function getThemeInfo(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/getThemeInfo',
    method: 'get',
    params,
  })
}
export function checkSensitiveContent(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/checkSensitiveContent',
    method: 'post',
    data,
  })
}
// 保存提交内容
export function themeInfoSave(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/themeInfoSave',
    method: 'post',
    data,
  })
}
// 发布内容
export function infoSave(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/infoSave',
    method: 'post',
    data,
  })
}
// 获取发布内容详情
export function infoDetail(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/infoDetail',
    method: 'get',
    params,
  })
}
// 发布列表屏蔽
export function infoDelete(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/infoDelete',
    method: 'get',
    params,
  })
}
// 发布列表取消屏蔽
export function infoDeleteRecover(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/infoDeleteRecover',
    method: 'get',
    params,
  })
}
// （论坛）发布列表屏蔽
export function communityDelete(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/communityDeleteV2',
    method: 'get',
    params,
  })
}
// （论坛）发布列表取消屏蔽
export function showDeleteRecover(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/showDeleteRecover',
    method: 'get',
    params,
  })
}
// 删除发布内容
export function deleteInfoReal(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/deleteInfoReal',
    method: 'post',
    data,
  })
}
// 同步微信内容
export function getItemInfo(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/getItemInfo',
    method: 'get',
    params,
  })
}
