<template>
  <div class="manage-community-container">
    <SearchForm
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @handleChangeStatus="handleChangeStatus"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :is-index="true"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :selection="true"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
      @handleDelete="handleDelete"
      @handleEdit="handleEdit"
      @handleMove="handleMove"
      @handlePreview="handlePreview"
      @handleRefresh="handleRefresh"
      @handleRelease="handleRelease"
      @handleSetFixed="handleSetFixed"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <div
          class="content-info"
          :style="{
            display:
              row.pic_type != 4 && row.pics_arr.length > 1 ? 'block' : 'flex',
          }"
        >
          <ul>
            <li>
              <span class="content-info-title" @click.stop="jumpDetail(row)">
                {{ row.title }}
              </span>
              <el-tag
                v-if="row.type === 1 || row.type === 2 || row.type === 3"
                effect="plain"
              >
                {{ itemDataType(row.type) }}
              </el-tag>
            </li>
            <li>
              <span class="content-info-from">{{ row.from }}</span>
              <span class="text-secondary" style="margin-left: 20px">
                {{ row.publish_time | dateFormat(true) }}
              </span>
            </li>
            <li>
              <span class="text-secondary">{{ row.click_show }}阅读</span>
              <span class="text-secondary" style="margin-left: 20px">
                id: {{ row.id }}
              </span>
            </li>
          </ul>
          <template v-if="row.pic_type != 4 && row.pics_arr.length">
            <el-image
              v-for="(item, index) in row.pics_arr"
              :key="index"
              :preview-src-list="[
                item.tburl.slice(0, 1) === '/'
                  ? baseURL + item.tburl
                  : item.tburl,
              ]"
              :src="
                item.tburl.slice(0, 1) === '/'
                  ? baseURL + item.tburl
                  : item.tburl
              "
            />
          </template>
        </div>
      </template>
      <!-- 其他信息 -->
      <template #other="{ row }">
        <span>推送者：</span>
        <span>{{ row.referrer_user_name }}</span>
        <div v-if="detailInfo.sort_type == 1 || detailInfo.sort_type == 2">
          <span>排序时间：</span>
          <span class="text-secondary">
            {{ row.rank_time | dateFormat(true) }}
          </span>
        </div>
        <div v-if="row.fixedInfo.position">
          <span>固定位置：</span>
          <div>
            <span class="text-secondary">{{ row.fixedInfo.position }}</span>
            <span class="text-secondary">{{ row.fixed_count }}</span>
            <span class="text-secondary">
              {{ row.fixedInfo.end_time | dateFormat(true) }}
            </span>
            <span class="text-secondary">{{ row.fixedInfo.user.name }}</span>
          </div>
        </div>
      </template>
    </TablePaging>
    <PreviewDialog ref="previewDialog" />
    <MoveDialog ref="moveDialog" />
    <SetFixedDialog ref="setFixedDialog" @getTableData="getTableData" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import {
    getAdminUserPage,
    getInfoPage,
    getFixedPage,
    refreshIndexCache,
    infoDelete,
    infoDeleteRecover,
    deleteInfoReal,
  } from '@/api/information'
  import { baseURL } from '@/config'
  import PreviewDialog from '@/components/QRcode/previewDialog.vue'
  import MoveDialog from '../components/moveDialog.vue'
  import SetFixedDialog from '../components/setFixedDialog'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      SearchForm,
      TablePaging,
      PreviewDialog,
      MoveDialog,
      SetFixedDialog,
    },
    mixins: [tableMixins],
    props: {
      detailInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        query: {
          type: 0,
          position: 0,
          keywords: null,
          from: null,
          fixed: false,
          referrer_user_id: null,
          pushTime: null,
          alertnative_beg_time: null,
          alertnative_end_time: null,
          cat_id: this.$route.query.id,
        },
        // 表格最大高度
        maxHeight: document.body.clientHeight - 450,
        statusList: Object.freeze([
          {
            id: 0,
            label: '已发布',
          },
          {
            id: 4,
            label: '已固定',
          },
          {
            id: 1,
            label: '待发布',
          },
          {
            id: 2,
            label: '草稿箱',
          },
          {
            id: 3,
            label: '已屏蔽',
          },
        ]),
        adminUser: [],
        positionList: [],
        buttonList: Object.freeze([
          {
            label: '刷新列表缓存',
            type: 'primary',
            icon: 'el-icon-refresh',
            eventName: 'handleRefresh',
          },
          {
            label: '发布内容',
            type: 'primary',
            icon: 'el-icon-s-release',
            eventName: 'handleRelease',
          },
        ]),
        pushTimeType: [
          {
            id: 1,
            label: '一天',
          },
          {
            id: 2,
            label: '一周',
          },
          {
            id: 3,
            label: '一个月',
          },
        ],
        // 数据类型
        dataTypeList: Object.freeze([
          {
            id: 1,
            label: '后台发布',
          },
          {
            id: 2,
            label: '创作者',
          },
          {
            id: 3,
            label: '轻分享',
          },
          {
            id: 9,
            label: '视频',
          },
          {
            id: 11,
            label: '引用咨询',
          },
        ]),
        baseURL,
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      searchColumn() {
        return [
          {
            label: '内容类型',
            prop: 'type',
            type: 'select',
            clearable: false,
            eventName: 'handleChangeStatus',
            selectList: this.statusList,
          },
          {
            label: '固定位置',
            prop: 'position',
            type: 'select',
            clearable: false,
            optionLabel: 'name',
            optionValue: 'value',
            eventName: 'handleChangeStatus',
            selectList: this.positionList,
            isHide: this.query.type != 4,
          },
          {
            label: '标题关键字',
            prop: 'keywords',
          },
          {
            label: '发帖人',
            prop: 'from',
          },
          {
            label: '推送者',
            prop: 'referrer_user_id',
            type: 'select',
            filterable: true,
            optionLabel: 'name',
            eventName: 'handleChangeStatus',
            selectList: this.adminUser,
          },
          {
            label: '推送时间',
            prop: 'pushTime',
            type: 'select',
            eventName: 'handleChangeStatus',
            selectList: this.pushTimeType,
          },
          {
            label: '开始时间',
            prop: 'alertnative_beg_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '结束时间',
            prop: 'alertnative_end_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
        ]
      },
      columnList() {
        return [
          { label: '内容', slotName: 'content', align: 'left' },
          { label: '其他信息', slotName: 'other', align: 'left' },
          {
            label: '操作',
            isButton: true,
            width: 350,
            btnList: [
              {
                label: '编辑',
                type: 'primary',
                eventName: 'handleEdit',
              },
              {
                label: '移动',
                type: 'primary',
                eventName: 'handleMove',
              },
              {
                label: '固定位置',
                type: 'primary',
                eventName: 'handleSetFixed',
              },
              {
                label: '屏蔽',
                type: 'danger',
                showName: 'delete_time',
                showStatusList: [null],
                eventName: 'handleDelete',
              },
              {
                label: '取消屏蔽',
                type: 'danger',
                showName: 'delete_time',
                notEmpty: true,
                eventName: 'handleDelete',
              },
              {
                label: '预览',
                type: 'primary',
                isHide: this.query.type == 2,
                eventName: 'handlePreview',
              },
              {
                label: '删除',
                type: 'danger',
                isHide: this.query.type == 3,
                eventName: 'handleDelete',
              },
            ],
          },
        ]
      },
    },
    created() {
      this.getAdminUserPage()
      this.getFixedPage()
      this.getTableData()
    },
    methods: {
      translateTitle,
      // 获取状态列表
      async getAdminUserPage() {
        const data = await getAdminUserPage()
        if (data.success) {
          this.adminUser = data.list
        }
      },
      // 获取固定位置列表
      async getFixedPage() {
        const data = await getFixedPage()
        if (data.success) {
          this.positionList = data.list
        }
      },
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        params.alertnative_beg_time = params.alertnative_beg_time
          ? params.alertnative_beg_time / 1000
          : null
        params.alertnative_end_time = params.alertnative_end_time
          ? params.alertnative_end_time / 1000
          : null
        params.fixed = params.position
          ? this.positionList.find((item) => item.value == params.position)
              .is_fixed
          : null
        delete params.pushTime
        getInfoPage(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = Number(res.total)
        })
      },
      // 改变选择条件
      handleChangeStatus() {
        let day = 1000 * 60 * 60 * 24
        let time = new Date()
        if (this.query.pushTime == 1) {
          this.query.alertnative_end_time = time
          this.query.alertnative_beg_time = time - day
        } else if (this.query.pushTime == 2) {
          this.query.alertnative_end_time = time
          this.query.alertnative_beg_time = time - day * 7
        } else if (this.query.pushTime == 3) {
          this.query.alertnative_end_time = time
          this.query.alertnative_beg_time = time - day * 30
        }
        this.pageInfo.page = 1
        this.getTableData()
      },
      // 遍历类型
      itemDataType(type) {
        return this.dataTypeList.filter((item) => item.id == type)[0].label
      },
      // 刷新列表缓存
      async handleRefresh() {
        const params = {
          id: this.$route.query.id,
        }
        this.loading = true
        const data = await refreshIndexCache(params)
        this.loading = false
        if (data.success) {
          this.$message.success('刷新成功')
        } else {
          this.$message.error('刷新失败')
        }
      },
      // 跳转地址
      jumpDetail(row) {
        window.open(this.returnLink(row))
      },
      // 发布内容
      handleRelease() {
        this.$router.push({
          path: 'alternative/addSourceList',
          query: {
            id: this.$route.query.id,
          },
        })
      },
      // 编辑
      handleEdit(val) {
        this.$router.push({
          path: 'alternative/editSourceList',
          query: {
            id: this.$route.query.id,
            content_id: val.id,
          },
        })
      },
      // 移动
      handleMove(val) {
        this.$refs.moveDialog.openDialog(val)
      },
      handleSetFixed(val) {
        this.$refs.setFixedDialog.handleOpen('固定到信息流', val)
      },
      // 屏蔽
      handleDelete(val, index, buttonName) {
        this.$confirm(
          this.translateTitle(`此操作将${buttonName}当前内容, 是否继续?`),
          this.translateTitle('提示'),
          {
            confirmButtonText: this.translateTitle('确定'),
            cancelButtonText: this.translateTitle('取消'),
            type: 'warning',
          }
        )
          .then(async () => {
            let res
            let params = {}
            if (buttonName == this.translateTitle('屏蔽')) {
              params.id = val.id
              res = await infoDelete(params)
            } else if (buttonName == this.translateTitle('取消屏蔽')) {
              params.id = val.id
              res = await infoDeleteRecover(params)
            } else if (buttonName == this.translateTitle('删除')) {
              params.ids = val.id
              res = await deleteInfoReal(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success
                ? this.translateTitle(`${buttonName}成功`)
                : this.translateTitle(`${buttonName}失败`),
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.translateTitle('已取消'),
            })
          })
      },
      // 预览
      handlePreview(val) {
        this.$refs.previewDialog.handleOpen(this.returnLink(val))
      },
      returnLink(row) {
        if (row.type == 2) {
          var bbs_type = this.config.bbs_type
          if (bbs_type == 'dz') {
            return `${baseURL}${this.config.dz_http_url}/forum.php?mod=viewthread&tid=${row.type_value}`
          } else if (bbs_type == 'pw') {
            return `${baseURL}${this.config.pw_http_url}/read.php?tid=${row.type_value}`
          }
        } else if (
          row.type == 1 ||
          row.type == 4 ||
          row.type == 5 ||
          row.type == 12
        ) {
          return `${baseURL}${row.link}`
        } else if (row.type == 3) {
          return `${baseURL}${window.location.origin}/metatime/circle/v1/show/wapShowView?content_id=${row.type_value}`
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.manage-community';
  $table-info: '.content-info';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
  #{$table-info} {
    display: flex;
    justify-content: space-between;
    ul {
      padding: 0;
      margin: 0;
      li {
        margin: 5px 0;
      }
    }
    .el-image {
      flex-shrink: 0;
      width: 160px !important;
      height: 118px !important;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      display: -webkit-box;
      overflow: hidden;
      color: $base-color-black;
      cursor: pointer;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      &:hover {
        color: $base-color-blue;
        text-decoration: underline;
      }
    }
    &-from {
      color: $base-color-blue;
    }
  }
  .text-secondary {
    color: $base-color-text-secondary;
  }
</style>
