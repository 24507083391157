var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flow-manage-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-position": "left",
          "label-width": 60,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          "is-pagination": true,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          configInfo: _vm.configInfo,
          handleCreate: _vm.handleCreate,
          handleCurrentChange: _vm.handleCurrentChange,
          settingPermanent: _vm.settingPermanent,
          viewCall: _vm.viewCall,
        },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._l(_vm.typeList, function (item) {
                  return [
                    row.type == item.id
                      ? _c("span", { key: item.id }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.label.indexOf("论坛") === -1
                                  ? item.label
                                  : "创作者"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                }),
              ]
            },
          },
          {
            key: "sortTime",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-input", {
                  attrs: { type: "number" },
                  on: {
                    input: function ($event) {
                      return _vm.handleInput(row.id, row.sort_time)
                    },
                  },
                  model: {
                    value: row.sort_time,
                    callback: function ($$v) {
                      _vm.$set(row, "sort_time", $$v)
                    },
                    expression: "row.sort_time",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("ViewCallDialog", { ref: "viewCall" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }