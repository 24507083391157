import Layout from '@/vab/layouts'

const user = {
  path: '/user',
  name: 'User',
  component: Layout,
  meta: {
    title: '用户',
    icon: 'user-2-fill',
  },
  children: [
    {
      path: 'personList',
      name: 'PersonList',
      component: () => import('@/views/user/personList'),
      meta: {
        title: '用户列表',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'personGroup',
      name: 'PersonGroup',
      component: () => import('@/views/user/personGroup'),
      meta: {
        title: '用户组',
        hidden: true,
        // icon: 'computer-fill',
      },
    },
    {
      path: 'permissionConfig',
      name: 'PermissionConfig',
      component: () => import('@/views/user/permissionConfig'),
      meta: {
        title: '权限配置',
        hidden: true,
        // icon: 'computer-fill',
      },
    },
  ],
}

export default user
