var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.handleClose,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          item.isShow
            ? _c(
                "div",
                { key: index, staticClass: "preview-item" },
                [
                  _c("el-image", {
                    staticClass: "preview-item-code-img",
                    attrs: { src: _vm.baseURL + _vm.previewItem[item.codeUrl] },
                  }),
                  _c("div", { staticClass: "preview-item-info" }, [
                    _c("p", { staticClass: "preview-item-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("p", { staticClass: "preview-item-note" }, [
                      _vm._v(_vm._s(item.note)),
                    ]),
                    item.setLink == "app"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "preview-item-label" }, [
                              _vm._v("强制用户登录查看"),
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.need_login,
                                  callback: function ($$v) {
                                    _vm.need_login = $$v
                                  },
                                  expression: "need_login",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: -1 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "preview-item-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleConfirm(item)
                            },
                          },
                        },
                        [_vm._v(" 立即使用 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }