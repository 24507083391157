<template>
  <el-upload
    :ref="`upload-${prop}`"
    v-loading="loading"
    :accept="videoFiles"
    :action="uploadApi"
    :before-upload="beforeVideoUpload"
    class="video-uploader"
    :data="uploadFormData"
    element-loading-spinner="el-icon-loading"
    element-loading-text="上传中"
    :limit="limit"
    :on-error="handleVideoError"
    :on-success="handleVideoSuccess"
    :show-file-list="false"
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <div v-if="videoUrl" class="video-url">
      <vab-player-mp4 :config="mp4Config" @player="$vabPlayerMap4 = $event" />
      <i class="el-icon-remove" @click.stop="handleRemoveVideo"></i>
    </div>
    <i v-else class="el-icon-video-play video-uploader-icon"></i>
  </el-upload>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { baseURL, uploadApi, videoAllowFiles, videoMaxSize } from '@/config'
  import { VabPlayerMp4 } from '@/extra/VabPlayer.js'
  import { uploadQiNiuVideo } from '@/api/user'
  import { timeFormat } from '@/filters'
  export default {
    components: {
      VabPlayerMp4,
    },
    props: {
      videoUrl: {
        type: String,
        default: null,
      },
      prop: {
        type: String,
        default: null,
      },
      limit: {
        type: [Number, String],
        default: 1,
      },
      width: {
        type: [Number, String],
        default: 360,
      },
      height: {
        type: [Number, String],
        default: 200,
      },
      eventName: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        loading: false,
        uploadApi: uploadApi,
        videoFiles: videoAllowFiles,
        uploadLink: `${baseURL}/core/attachment/attachment/attach?aid=`,
        $vabPlayerMp4: null,
      }
    },
    computed: {
      ...mapGetters({
        qiniuToken: 'user/qiniuToken',
      }),
      uploadFormData() {
        return {
          token: this.qiniuToken,
          key: null,
        }
      },
      mp4Config() {
        return {
          id: 'mse2',
          url: this.videoUrl,
          volume: 0.6,
          autoplay: false,
          fluid: true,
          pip: false,
          screenShot: false,
        }
      },
    },
    methods: {
      // 上传成功
      handleVideoSuccess(res) {
        // console.log(res, file)
        if (res.code === 100) {
          this.uploadQiNiuVideo()
        } else {
          this.loading = false
          this.$message.error('上传失败，请重新上传')
        }
        this.$refs[`upload-${this.prop}`].clearFiles()
      },
      handleVideoError() {
        this.loading = false
        this.$message.error('上传失败，请重新上传')
      },
      // 上传之前
      beforeVideoUpload(file) {
        this.loading = true
        this.uploadFormData.key = this.combinedPath(file.name)
        const isLt2M = file.size / 1024 / 1024 < videoMaxSize
        if (!isLt2M) {
          this.loading = false
          this.$message.error(`上传视频大小不能超过 ${videoMaxSize}MB!`)
        }
        return isLt2M
      },
      // 获取当前时间戳组成路径key
      combinedPath(name) {
        name = name.split('.').pop().toLowerCase()
        let time = timeFormat(new Date(), true, '')
        return `video/${time}/qi_niu_${new Date().getTime()}_${this.random()}_${this.random()}_${this.random()}.${name}`
      },
      // 随机数
      random() {
        let val = Math.random() * 1000
        val = Math.round(val)
        return val
      },
      // 获取上传图片id
      async uploadQiNiuVideo() {
        const params = {
          video_key: this.uploadFormData.key,
        }
        const data = await uploadQiNiuVideo(params)
        this.loading = false
        this.$emit(
          'handleVideoUrl',
          this.eventName,
          this.prop,
          data
          // `${this.uploadLink}${data}&width=${this.width}&height=${this.height}`
        )
      },
      // 删除视频
      handleRemoveVideo() {
        this.$emit('handleVideoUrl', this.eventName, this.prop, {})
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-upload,
  .video-url {
    width: 100%;
    height: 100%;
    position: relative;
    .el-icon-remove {
      position: absolute;
      right: -12px;
      top: -12px;
      font-size: 24px;
      color: $base-color-red;
    }
  }
  ::v-deep .el-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e6e6;
    .video-uploader-icon {
      font-size: 36px;
    }
  }
</style>
