var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-container" },
    [
      _c("div", { staticClass: "comment-title" }, [_vm._v("评论")]),
      _c(
        "div",
        { staticClass: "comment-content" },
        [
          _c(
            "div",
            { staticClass: "comment-user", on: { click: _vm.handleVestList } },
            [
              _c("img", { attrs: { alt: "", src: _vm.userInfo.head } }),
              _c("span", [_vm._v(_vm._s(_vm.userInfo.name))]),
            ]
          ),
          _c("el-input", {
            staticClass: "comment-input",
            attrs: { placeholder: "请输入评论内容" },
            model: {
              value: _vm.commentContent,
              callback: function ($$v) {
                _vm.commentContent = $$v
              },
              expression: "commentContent",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleComment } },
            [_vm._v("评 论")]
          ),
          _c("el-button", { on: { click: _vm.handleQuickComments } }, [
            _vm._v("快捷评论"),
          ]),
        ],
        1
      ),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          "is-pagination": true,
          loading: _vm.loading,
          "page-info": _vm.pageInfo,
          "show-header": false,
          "table-data": _vm.list,
        },
        on: {
          handleAdopt: _vm.handleAdopt,
          handleCurrentChange: _vm.handleCurrentChange,
          handleFail: _vm.handleFail,
          handleReply: _vm.handleReply,
        },
        scopedSlots: _vm._u([
          {
            key: "userInfo",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "comment-user-info" }, [
                  _c("img", { attrs: { alt: "", src: row.user.head } }),
                  _c("div", { staticClass: "comment-username" }, [
                    _c("p", [_vm._v(_vm._s(row.user.name))]),
                    _c("span", [_vm._v(_vm._s(row.pubdate_str))]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                row.audit == 1
                  ? _c("el-tag", [_vm._v("待审核")])
                  : row.audit == 2
                  ? _c("el-tag", { attrs: { type: "primary" } }, [
                      _vm._v("已通过"),
                    ])
                  : row.audit == 3
                  ? _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v("未通过"),
                    ])
                  : row.word == 2
                  ? _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v("敏感词"),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "comment-value" }, [
                  _vm._v(_vm._s(row.content)),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("VestList", { ref: "vestList", on: { setUserInfo: _vm.setUserInfo } }),
      _c("AuditNotPassDialog", {
        ref: "auditNotPass",
        on: { handleAuditNotPass: _vm.handleAuditNotPass },
      }),
      _c("ReplyDialog", {
        ref: "replyDialog",
        on: { getTableData: _vm.handleCommentPage },
      }),
      _c("QuickComments", {
        ref: "quickComments",
        attrs: { "is-recovery-rate": false, "show-number": 4, themetype: "3" },
        on: { getTableData: _vm.handleCommentPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }