var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "upload-" + _vm.prop,
      staticClass: "video-uploader",
      style: {
        width: _vm.width + "px",
        height: _vm.height + "px",
      },
      attrs: {
        accept: _vm.videoFiles,
        action: _vm.uploadApi,
        "before-upload": _vm.beforeVideoUpload,
        data: _vm.uploadFormData,
        "element-loading-spinner": "el-icon-loading",
        "element-loading-text": "上传中",
        limit: _vm.limit,
        "on-error": _vm.handleVideoError,
        "on-success": _vm.handleVideoSuccess,
        "show-file-list": false,
      },
    },
    [
      _vm.videoUrl
        ? _c(
            "div",
            { staticClass: "video-url" },
            [
              _c("vab-player-mp4", {
                attrs: { config: _vm.mp4Config },
                on: {
                  player: function ($event) {
                    _vm.$vabPlayerMap4 = $event
                  },
                },
              }),
              _c("i", {
                staticClass: "el-icon-remove",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleRemoveVideo.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _c("i", { staticClass: "el-icon-video-play video-uploader-icon" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }