<template>
  <div class="add-show-container">
    <div class="add-show-title">基础配置</div>
    <Form :column="column1" :query="query1" ref-name="basic" />
    <el-divider />
    <div class="add-show-title">内容对接</div>
    <Form :column="column2" :query="query2" ref-name="basic" />
    <el-divider />
    <div class="add-show-btn">
      <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      <el-button size="medium" @click="cancel">取消</el-button>
      <el-button
        v-if="$route.query.id"
        size="medium"
        type="danger"
        @click="onDelete"
      >
        删除
      </el-button>
    </div>

    <el-dialog title="信息" :visible.sync="dialogVisible" width="20%">
      <span>是否确认删除该信息流？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Form from '@/components/Form'
  import { getShowList, catSave, catDelete } from '@/api/information'
  import config from './config'
  export default {
    components: {
      Form,
    },
    props: {
      query1: {
        type: Object,
        default: () => ({}),
      },
      query2: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return config
    },
    computed: {
      column1() {
        return [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '信息流分组',
            prop: 'type',
            type: 'select',
            list: [
              {
                id: 5,
                label: '请选择',
              },
            ],
          },
          {
            label: '列表样式',
            prop: 'show_style',
            type: 'radio',
            list: this.listStyleList,
            tips: '保存配置后，重新加载内容列表生效',
          },
          {
            label: '样式选择',
            prop: 'pintu_type',
            type: 'radio',
            list: this.styleSelectionList,
            isHide: this.query1.show_style != 'pintu',
          },
          {
            label: '评论样式',
            prop: 'comment_type',
            type: 'radio',
            list: this.commentStyleList,
            tips: '当选择“神评优先”样式后，内容列表没有神评时显示最新评论；“仅显示神评”可以提升页面紧凑度',
            isHide: this.query1.show_style != 'pintu',
          },
          {
            label: '排序方式',
            prop: 'sort_type',
            type: 'radio',
            list: this.sortList,
            tips: '保存配置后，重新加载内容列表生效',
          },
          {
            label: '内容范围',
            prop: 'sort_max_size',
            type: 'radio',
            list: this.contentScopeList,
            tips: '客户端下拉刷新随机显示内容范围。',
            isHide: this.query1.sort_type != 2,
          },
        ]
      },
      column2() {
        return [
          {
            label: '来源圈子',
            prop: 'show_from',
            type: 'select',
            multiple: true,
            optionLabel: 'name',
            list: this.showList,
            tips: '选择内容来源版块，可多选',
          },
          {
            label: '排序方式',
            prop: 'sort',
            type: 'select',
            list: this.timeSortList,
          },
          {
            label: '发布时间',
            prop: 'publish_time',
            type: 'select',
            list: this.timeList,
            tips: '<span>发布时间区间内内容过多会造成服务器压力，</span>请根据需求合理选择区间范围。',
          },
          {
            label: '互动时间',
            prop: 'interact_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '是否精华',
            prop: 'show_is_elite',
            type: 'radio',
            list: this.whetherList,
          },
          {
            label: '只取图片',
            prop: 'show_is_pic',
            type: 'radio',
            list: this.whetherList,
          },
          {
            label: '是否添加小视频',
            prop: 'show_is_video',
            type: 'radio',
            list: this.whetherList,
          },
          {
            label: '输入经纬度',
            prop: ['lng', 'lat'],
            placeholder: ['经度', '纬度'],
            type: 'inputGroup',
            tips: `填入经纬度将会获取所填经纬度${this.query2.radius}公里范围的用户动态`,
          },
          {
            label: '半径',
            prop: 'radius',
            tips: '默认为：10，单位：KM',
          },
        ]
      },
    },
    created() {
      this.getShowList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      onSubmit() {
        const {
          name,
          type,
          sort_type,
          show_style,
          pintu_type,
          comment_type,
          sort_max_size,
        } = this.query1
        let params = {
          name,
          type,
          sort_type,
          sort_max_size,
          _config: {
            show_style,
            pintu_type,
            comment_type,
            ...this.query2,
          },
        }
        params._config.show_from = params._config.show_from.join(',')
        params._config = JSON.stringify(params._config)
        if (this.$route.query.id) params.id = this.$route.query.id
        catSave(params).then(async (res) => {
          if (res.success === true) {
            await this.delVisitedRoute('/info/addshow')
            await this.delVisitedRoute('/info/addInformation')
            this.$router.push('/info/flowManage')
          } else this.$baseMessage(res.msg, 'error')
        })
      },
      // 获取来源圈子
      getShowList() {
        var params = { step: 20 }
        getShowList(params).then((res) => {
          this.showList = res.list
        })
      },
      async cancel() {
        await this.delVisitedRoute('/info/addshow')
        this.$router.push('/info/addInformation')
      },
      // 点击删除
      onDelete() {
        this.dialogVisible = true
      },
      onSure() {
        const params = { id: this.$route.query.id }
        catDelete(params).then(async (res) => {
          if (res.success) {
            this.dialogVisible = false
            await this.delVisitedRoute('/info/column')
            this.$router.push('/')
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-show';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
