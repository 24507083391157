import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=0ccad362&scoped=true&"
import script from "./manage.vue?vue&type=script&lang=js&"
export * from "./manage.vue?vue&type=script&lang=js&"
import style0 from "./manage.vue?vue&type=style&index=0&id=0ccad362&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ccad362",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paul/Documents/project/beimei_app/metatopnews/metatopnews-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ccad362')) {
      api.createRecord('0ccad362', component.options)
    } else {
      api.reload('0ccad362', component.options)
    }
    module.hot.accept("./manage.vue?vue&type=template&id=0ccad362&scoped=true&", function () {
      api.rerender('0ccad362', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/information/flowManage/ShowColumn/manage.vue"
export default component.exports