<template>
  <div class="manage-portal-container">
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
      @handleEdit="handleEdit"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <div class="content-info">
          <span>{{ row.title }}</span>
          <el-image
            v-if="row.pics_arr.length"
            :preview-src-list="[row.pics_arr[0].tburl]"
            :src="row.pics_arr[0].tburl"
          />
        </div>
      </template>
      <!-- 发布时间 -->
      <template #publish_time="{ row }">
        {{ row.publish_time | dateFormat(true) }}
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import { getPortalPage } from '@/api/information'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    props: {
      detailInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        query: {
          cat_id: this.$route.query.id,
        },
        // 表格最大高度
        maxHeight: document.body.clientHeight - 450,
        columnList: [
          { label: '内容', slotName: 'content', align: 'left' },
          { label: '发布时间', slotName: 'publish_time', align: 'left' },
          { label: '来源/阅读量', prop: 'from', align: 'left' },
          {
            label: '操作',
            isButton: true,
            btnList: [
              { label: '编辑', type: 'primary', eventName: 'handleEdit' },
            ],
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
    },
    created() {
      this.getTableData()
    },
    methods: {
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        params.alertnative_beg_time = params.alertnative_beg_time
          ? params.alertnative_beg_time / 1000
          : null
        params.alertnative_end_time = params.alertnative_end_time
          ? params.alertnative_end_time / 1000
          : null
        delete params.pushTime
        getPortalPage(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = Number(res.total)
        })
      },
      handleEdit(val) {
        const { id, type } = this.$route.query
        this.$router.push({
          path: `operation/edit?cat_id=${id}&tid=${val.id}&type=${type}`,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.manage-portal';
  $table-info: '.content-info';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
  #{$table-info} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-image {
      margin-left: 20px;
      width: 60px !important;
      height: 60px !important;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
