import request from '@/utils/request'
// 获取圈子主题审核
export function getAuditThemeList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/auditThemePage',
    method: 'get',
    params,
  })
}
// 获取圈子评论审核
export function getAuditCommentList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/auditCommentPage',
    method: 'get',
    params,
  })
}

// 获取马甲列表
export function getVestList(params) {
  return request({
    url: '/metatime/user/v1/user/listNFTVestUserPage',
    method: 'get',
    params,
  })
}

// 同步NFT
export function createNFT(data) {
  return request({
    url: '/metatime/user/v1/user/createNFT',
    method: 'post',
    data,
  })
}

// 通过/不通过(圈子主题)
export function passOrNotTheme(data) {
  return request({
    url: '/metatime/circle/v1/adminCircle/passOrNotTheme',
    method: 'post',
    data,
  })
}

// 通过/不通过(圈子评论)
export function updateAudit(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/updateAudit',
    method: 'get',
    params,
  })
}

// 操作记录
export function listThemeActionLog(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/listThemeActionLog',
    method: 'get',
    params,
  })
}

// 评论编辑
export function editorComment(data) {
  return request({
    url: '/metatime/circle/v1/adminCircle/editorComment',
    method: 'post',
    data,
  })
}

// 图片详情
export function getAttachmentExt(data) {
  return request({
    url: '/core/attachment/attachment/getAttachmentExt',
    method: 'post',
    data,
  })
}

// 获取板块列表
export function getForumTree(params) {
  return request({
    url: '/metatime/circle/v1/adminForum/forumTreeV6',
    method: 'get',
    params,
  })
}

// 获取论坛主题审核
export function getAuditForumList(params) {
  return request({
    url: '/metatime/circle/v1/adminForum/forumThreadPage',
    method: 'get',
    params,
  })
}

// 获取用户
export function getUserList(params) {
  return request({
    url: '/metatime/user/v1/adminUser/userPage',
    method: 'get',
    params,
  })
}

// 主题审核通过/忽略/删除
export function auditForum(data) {
  return request({
    url: '/metatime/circle/v1/adminForum/auditForum',
    method: 'post',
    data,
  })
}

// 主题审核批量删除
export function batchAuditForum(data) {
  return request({
    url: '/metatime/circle/v1/adminForum/batchAuditForum',
    method: 'post',
    data,
  })
}

// 获取论坛评论审核
export function getCommentAuditedList(params) {
  return request({
    url: '/metatime/circle/v1/adminForum/commentAuditedPage',
    method: 'get',
    params,
  })
}

// 评论审核通过/忽略/删除
export function auditComment(data) {
  return request({
    url: '/metatime/circle/v1/adminForum/auditComment',
    method: 'post',
    data,
  })
}

// 评论审核批量删除
export function batchDeleteComment(data) {
  return request({
    url: '/metatime/circle/v1/adminForum/batchDeleteComment',
    method: 'post',
    data,
  })
}

// 获取合集
export function getCollectList(params) {
  return request({
    url: '/metatime/circle/v1/adminCollect/collectPage',
    method: 'get',
    params,
  })
}

// 获取话题
export function getRecommendList(params) {
  return request({
    url: '/metatime/circle/v1/adminTopic/listRecommendTopic',
    method: 'get',
    params,
  })
}

// 设为封面图
export function setCoverPic(data) {
  return request({
    url: '/metatime/circle/v1/adminCircle/setCoverPic',
    method: 'post',
    data,
  })
}

// 随机点赞
export function randomApplaudTheme(data) {
  return request({
    url: '/metatime/circle/v1/adminCircle/randomApplaudTheme',
    method: 'post',
    data,
  })
}

// 获取元宇宙用户
export function listUserVestPage(data) {
  return request({
    url: '/metatime/user/v1/user/listUserVestPage',
    method: 'post',
    data,
  })
}

// 获取常用元宇宙用户
export function getRecentVestList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/recentVestList',
    method: 'get',
    params,
  })
}

// 快捷评论
export function quickCommentTheme(data) {
  return request({
    url: '/metatime/circle/v1/adminCircle/quickCommentTheme',
    method: 'post',
    data,
  })
}

// 获取常用元宇宙用户
export function getVestUserAllList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/vestUserAllList',
    method: 'get',
    params,
  })
}

// 快捷评论(圈子评论审核)
export function circleComment(data) {
  return request({
    url: '/metatime/circle/v1/adminShow/contentAddComment',
    method: 'post',
    data,
  })
}

// 快捷评论(资讯评论审核)
export function infoComment(data) {
  return request({
    url: '/metatime/info/v1/info/contentAddComment',
    method: 'post',
    data,
  })
}

// 获取圈子
export function getShowListPage(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/showListPage',
    method: 'get',
    params,
  })
}

// 获取用户信息
export function getAppInfo(params) {
  return request({
    url: '/metatime/user/v1/adminUser/loadAppInfo',
    method: 'get',
    params,
  })
}

// 智能发布
export function contentAdd(data) {
  return request({
    url: '/metatime/circle/v1/adminShow/contentAdd',
    method: 'post',
    data,
  })
}

// 获取文章评论
export function getCommentPage(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/commentPage',
    method: 'get',
    params,
  })
}

// 获取圈子详情
export function getContentDetail(params) {
  return request({
    url: '/metatime/circle/v1/adminShow/ContentDetail',
    method: 'get',
    params,
  })
}

// 获取作者信息
export function getCircleInfo(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/circleGet',
    method: 'get',
    params,
  })
}
