<template>
  <el-dialog
    :append-to-body="true"
    :before-close="handleClose"
    :lock-scroll="false"
    title="跳转选择器"
    :visible.sync="dialogVisible"
    width="70%"
  >
    <el-row class="tac" :gutter="20">
      <el-col :span="4">
        <el-menu
          id="menu"
          :default-active="defaultActive"
          :default-openeds="defaultOpeneds"
          @select="handleSelectMenu"
        >
          <template v-for="(item, index) in navList">
            <el-submenu
              v-if="item.children"
              :key="index"
              :index="index.toString()"
            >
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group v-for="(el, idx) in item.subList" :key="idx">
                <el-menu-item :index="el.view">
                  {{ el.name }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :key="index" :index="item.view">
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <!-- 工具功能 -->
        <template v-if="defaultActive == 'component-tools'">
          <div class="tools-search">
            <SearchForm
              :btn-col="8"
              :column-span="16"
              :label-width="70"
              :query="query"
              :search-column="searchColumn1"
              @getTableData="getTableData"
              @resetForm="resetForm"
            />
            <div class="tools-more">
              <span>更多跳转链接，</span>
              <a
                href="http://doc.magcloud.net/318341?fcxqtw=jgfio2"
                target="_blank"
              >
                点此查看
              </a>
            </div>
          </div>
          <div v-loading="loading" class="tools-item">
            <div v-for="(item, index) in list" :key="index">
              <p class="tools-title">{{ item.name }}</p>
              <ul class="tools-list">
                <li v-for="(el, idx) in item.list" :key="idx">
                  <span>{{ el.name }}</span>
                  <el-button type="text" @click="previewUse(el)">
                    预览使用
                  </el-button>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <!-- 自定义链接 -->
        <template v-else-if="defaultActive == 'component-redefine'">
          <el-input
            v-model="link"
            class="m-b-20"
            placeholder="请输入地址"
            resize="none"
            :rows="2"
            type="textarea"
          />
          <el-button type="primary" @click="returnAddress(link)">
            确定
          </el-button>
        </template>
        <!-- 其他 -->
        <template v-else>
          <SearchForm
            :btn-col="8"
            :column-span="10"
            :label-width="70"
            :query="query"
            :search-column="searchColumn"
            @getTableData="getTableData"
            @resetForm="resetForm"
          />
          <!-- 表格 -->
          <TablePaging
            :column-list="columnList"
            :loading="loading"
            :max-height="maxHeight"
            :page-info="pageInfo"
            :table-data="list"
            @handleCurrentChange="handleCurrentChange"
            @previewUse="previewUse"
          >
            <!-- 标题 -->
            <template #label="{ row }">
              <el-image
                v-if="defaultActive == 'component-user'"
                class="info-head"
                :src="row.head"
              />
              <div class="info">
                <p class="info-title">
                  {{ row.title || row.content || row.name }}
                </p>
                <div class="info-item">
                  <span class="info-id">
                    ID: {{ row.tid || row.id || row.user_id }}
                  </span>
                  <span class="info-author">
                    {{ row.user_name || row.from }}
                  </span>
                  <span class="info-origin">
                    「{{
                      row.forum_name ||
                      row.circle_name ||
                      row.album_name ||
                      row.from ||
                      row.type_name ||
                      row.remark ||
                      row.desc
                    }}」
                  </span>
                  <span class="info-time">
                    {{ row.lastpost || row.create_time_str || row.timing }}
                  </span>
                </div>
              </div>
            </template>
          </TablePaging>
        </template>
      </el-col>
    </el-row>
    <PreviewDialog ref="previewDialog" @returnAddress="returnAddress" />
  </el-dialog>
</template>

<script>
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import PreviewDialog from './previewDialog.vue'
  import tableMixins from '@/mixins/tableCommon.vue'
  import config from './config'
  import request from '@/utils/request'

  export default {
    components: {
      SearchForm,
      TablePaging,
      PreviewDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    methods: {
      handleOpen() {
        this.dialogVisible = true
        this.getTableData()
      },
      handleClose(done) {
        done()
        setTimeout(() => {
          this.defaultActive = 'component-bbs'
          this.defaultOpeneds = ['0', '1', '2']
        }, 500)
      },
      async getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        const res = await request({
          url: this.apiList[this.defaultActive],
          method: 'get',
          params,
        })
        this.loading = false
        this.list = res.list
        this.pageInfo.count = res.total || res.list.length
      },

      // 选择菜单项
      handleSelectMenu(index) {
        this.defaultActive = index
        if (index == 'component-redefine') return
        this.pageInfo.page = 1
        this.getTableData()
      },

      // 预览使用
      previewUse(val) {
        for (let item of this.navList) {
          if (item.subList) {
            for (let el of item.subList) {
              if (this.defaultActive == el.view) {
                this.$refs.previewDialog.handleOpen(
                  `${el.name} | ${val.title || val.content || val.name}`,
                  val,
                  this.defaultActive
                )
              }
            }
          } else if (item.name == '工具功能') {
            this.$refs.previewDialog.handleOpen(
              `${item.name} | ${val.title || val.content || val.name}`,
              val,
              this.defaultActive
            )
          }
        }
      },

      // 立即使用的地址
      returnAddress(link) {
        this.$emit('handleLink', link)
        this.dialogVisible = false
        setTimeout(() => {
          this.defaultActive = 'component-bbs'
          this.defaultOpeneds = ['0', '1', '2']
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.info';
  $tools: '.tools';
  ::v-deep .el-dialog {
    height: 70vh;
    .el-dialog__body {
      height: calc(100% - 54px);
      padding: 30px 20px;
    }
  }
  .tac,
  .el-col,
  .el-menu {
    height: 100%;
  }
  .el-col {
    overflow: hidden;
  }
  .el-menu {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  #{$base} {
    float: left;
    max-width: 90%;
    &-title {
      width: 100%;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-item {
      span {
        margin-right: 15px;
      }
    }
    &-id,
    &-time {
      color: $base-color-text-secondary;
    }
    &-author {
      color: $base-color-green;
    }
  }
  #{$base}-head {
    margin-right: 15px;
    float: left;
    ::v-deep .el-image__inner {
      border-radius: 25px;
    }
  }
  #{$tools}-search {
    display: flex;
  }
  #{$tools}-more {
    margin-left: 20px;
    margin-top: 8px;
  }
  #{$tools}-item {
    height: calc(100% - 54px);
    overflow-y: scroll;
    box-sizing: border-box;
  }
  #{$tools}-title {
    color: $base-color-text-secondary;
  }
  #{$tools}-list {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #e9e9e9;
    margin-bottom: 1px;
    li {
      list-style: none;
      width: 33.33%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px 0 20px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #e9e9e9;
    }
  }
</style>
