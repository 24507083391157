<template>
  <div class="create-push-container">
    <Form
      :column="column"
      :query="query"
      ref-name="basic"
      @handleCheckLink="handleCheckLink"
      @handleFilterSelect="handleFilterSelect"
      @handleImageUrl="handleImageUrl"
    />
    <el-divider />
    <div class="create-push-btn">
      <el-button size="medium" type="primary" @click="handleRelease('now')">
        {{ translateTitle('立即发布') }}
      </el-button>
      <el-button
        icon="el-icon-pie-chart"
        size="medium"
        type="primary"
        @click="hanldeTiming"
      >
        {{ translateTitle('定时发布') }}
      </el-button>
      <el-button size="medium" @click="handleCancel">
        {{ translateTitle('取消') }}
      </el-button>
    </div>
    <!-- 定时发布--对话框 -->
    <ReleaseDialog ref="releaseDialog" @handleRelease="handleRelease" />
    <!-- 跳转地址--对话框 -->
    <CheckLinkDialog ref="checklink" @handleLink="handleLink" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import config from './config'
  import Form from '@/components/Form'
  import ReleaseDialog from '@/components/ReleaseDialog'
  import CheckLinkDialog from '@/components/JumpLink/checkLinkDialog.vue'
  import { pushSave } from '@/api/operate'
  import { getUserPage } from '@/api/user'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
      ReleaseDialog,
      CheckLinkDialog,
    },
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            title: '对象选择',
            label: '类型',
            prop: 'type',
            type: 'select',
            clearable: false,
            list: this.typeList,
          },
          {
            label: '用户名',
            type: 'filterSelect',
            prop: 'user_ids',
            multiple: true,
            defaultOptions: true,
            eventName: 'handleFilterSelect',
            list: [],
            isHide: this.query.type != 2,
            tips: '选择推送账号，可多选，不可超过50个',
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            clearable: false,
            list: this.sexList,
            isHide: this.query.type != 1,
          },
          {
            label: '活跃度',
            prop: 'active',
            type: 'select',
            clearable: false,
            list: this.activeList,
            isHide: this.query.type != 1,
          },
          {
            label: '标题',
            prop: 'tagGroup',
            type: 'select',
            clearable: false,
            list: this.tagGroup,
            isHide: this.query.type != 3,
          },
          {
            label: '',
            prop: 'groupTag',
            type: 'select',
            clearable: false,
            list: this.groupTag,
            isHide: this.query.type != 3,
          },
          {
            title: '推送内容',
            label: '推送标题',
            prop: 'title',
            tips: '可填。请填写消息推送文本标题，默认不填为推送内容',
          },
          {
            label: '推送内容',
            prop: 'content',
            type: 'textarea',
            tips: '必填。请填写消息推送文本内容',
          },
          {
            label: '推送封面',
            prop: 'img',
            type: 'uploadImage',
            width: 70,
            height: 70,
            eventName: 'handleImageUrl',
            tips: '可填。请填写消息推送文本封面，推荐尺寸：64 * 64px',
          },
          {
            label: '跳转类型',
            prop: 'jump_type',
            type: 'select',
            list: this.linkTypeList,
          },
          {
            label: 'URL',
            prop: 'jump_value',
            tips: '填写URL地址',
            clearable: false,
            // clickText: '选择跳转地址',
            // clickEventName: 'handleCheckLink',
            isHide: this.query.jump_type != 1,
          },
          {
            label: '轻分享',
            prop: 'jump_value',
            tips: '填写轻分享主题ID',
            isHide: this.query.jump_type != 2,
          },
          {
            label: '帖子',
            prop: 'jump_value',
            tips: '填写帖子主题ID',
            isHide: this.query.jump_type != 3,
          },
          {
            label: '每秒发送数',
            prop: 'once_push',
            type: 'radio',
            list: this.oncePushList,
          },
        ]
      },
    },
    methods: {
      translateTitle,
      // 发布
      async handleRelease(type, value) {
        if (!this.query.content) {
          return this.$message.error('推送内容不能为空')
        }
        const params = Object.assign({}, this.query)
        params.third_party_id = Math.floor(new Date().getTime() / 1000)
          .toString()
          .substring(4)
        if (type === 'now') {
          params.push_time = -1
        } else if (type === 'timing') {
          params.push_time = value / 1000
        }
        if (params.jump_type == 2) {
          params.jump_value =
            this.config.app_key + '://showView?id=' + params.jump_value
        } else if (params.jump_type == 3) {
          params.jump_value =
            '/metatime/circle/v1/forum/threadViewPage?tid=' +
            params.jump_value +
            '&from_push=1'
        }
        for (let item of this.activeType) {
          if (item.type == params.active) {
            params.is_active = item.is_active
            params.active = item.active
          }
        }
        params.user_ids = params.user_ids.join(',')
        const res = await pushSave(params)
        if (res.success) {
          this.handleCancel()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 地址
      handleLink(link) {
        this.query.jump_value = link
      },
      handleCheckLink() {
        this.$refs.checklink.handleOpen()
      },
      // 打开定时发布时间
      hanldeTiming() {
        this.$refs.releaseDialog.handleOpen()
      },
      // 切换到推送列表
      handleCancel() {
        this.$emit('handleTabActive', '2')
      },
      // 获取图片上传地址
      handleImageUrl(prop, id) {
        this.query[prop] = id
      },
      // 过滤
      async handleFilterSelect(prop, val, callback) {
        const params = {
          name: val,
          page: 1,
          step: 20,
        }
        const res = await getUserPage(params)
        let list = []
        if (res.list.length) {
          list = res.list.map((item) => {
            return {
              id: item.user_id,
              label: item.name,
            }
          })
        }
        callback(list)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.create-push';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-select {
      width: 300px !important;
    }
  }
</style>
