<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="50%"
  >
    <Form ref="create" :column="column" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { allAlbumPage, toBatchUpdateAlbum } from '@/api/video'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: '选择专辑',
        dialogVisible: false,
        loading: false,
        albumOption: [],
        item: {},
        query: {
          album_id: null,
          ids: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '所属专辑',
            prop: 'album_id',
            type: 'select',
            optionLabel: 'name',
            list: this.albumOption,
          },
        ]
      },
    },
    methods: {
      handleOpen(ids) {
        this.query.ids = ids
        this.allAlbumPage()
        this.dialogVisible = true
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 获取所属专辑
      async allAlbumPage() {
        const res = await allAlbumPage()
        if (res.success) {
          this.albumOption = res.list
        }
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = Object.assign({}, this.query)
        const res = await toBatchUpdateAlbum(params)
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('移入成功')
        } else {
          this.$message.error('移入失败')
        }
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.create.resetFields()
        setTimeout(() => {
          this.query.album_id = null
          this.query.ids = null
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
