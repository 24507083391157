<template>
  <div class="video-manage-container">
    <SearchForm
      :label-width="80"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :is-pagination="true"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :selection="true"
      :table-data="list"
      @cancelRecommend="cancelRecommend"
      @handleAlbum="handleAlbum"
      @handleCall="handleCall"
      @handleColumn="handleColumn"
      @handleCreate="handleCreate"
      @handleCurrentChange="handleCurrentChange"
      @handleEidt="handleEidt"
      @handleOnlyRemove="handleOnlyRemove"
      @handleRecovery="handleRecovery"
      @handleRemove="handleRemove"
      @handleSelectionChange="handleSelectionChange"
      @handleWithdraw="handleWithdraw"
      @recommend="recommend"
    >
      <!-- 标题 -->
      <template #content="{ row }">
        <div class="video-img">
          <el-image :src="row.cover_pic_url" @click="handleVideo(row)" />
          <span class="el-icon-video-play"></span>
          <span class="duration">{{ row.duration_str }}</span>
        </div>
        <div class="video">
          <p class="video-title" @click.stop="handleEidt(row)">
            {{ row.title }}
          </p>
          <div class="video-item text-secondary">
            <span class="video-play-num">
              {{ row.virtual_play_num }}
              <span v-if="row.play_num > 0">({{ row.play_num }})</span>
              播放
            </span>
            <span class="video-comment-num">{{ row.comment_num }} 评论</span>
            <span class="video-praise-num">{{ row.praise_num }} 点赞</span>
            <el-tag v-if="row.pay_type == 1" size="mini">免费</el-tag>
            <el-tag v-else-if="row.pay_type == 4" size="mini" type="danger">
              仅会员
            </el-tag>
            <el-tag v-else size="mini" type="warning">
              {{ row.pay_amount }}{{ config.virtual_currency_unit }}
            </el-tag>
          </div>
          <div class="video-item">
            <el-tag
              v-if="
                row.is_show == 1 && row.is_draft == 2 && row.timing <= nowTime
              "
              size="mini"
              type="warning"
            >
              已发布
            </el-tag>
            <el-tag
              v-if="row.is_show == 2 && row.is_draft == 2"
              size="mini"
              type="danger"
            >
              已撤回
            </el-tag>
            <el-tag
              v-if="
                row.is_show == 1 && row.is_draft == 2 && row.timing > nowTime
              "
              size="mini"
              type="warning"
            >
              定时发布中
            </el-tag>
            <el-tag v-if="row.is_draft == 1" size="mini" type="info">
              草稿
            </el-tag>
            <el-tag v-if="row.album.title" size="mini">
              {{ row.album.title }}
            </el-tag>
            <el-tag
              v-for="item in row.column_list"
              :key="item.id"
              size="mini"
              type="info"
            >
              {{ item.name }}
            </el-tag>
          </div>
          <div class="video-item text-secondary">
            <span>{{ row.source }}</span>
            <span v-if="row.is_draft == 2">
              {{ row.timing_formate }}
            </span>
          </div>
        </div>
      </template>
    </TablePaging>
    <ViewCallDialog ref="viewCall" />
    <AlbumDialog ref="album" @getTableData="getTableData" />
    <ColumnDialog ref="column" @getTableData="getTableData" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import config from './config'
  import ViewCallDialog from './components/viewCallDialog'
  import AlbumDialog from './components/albumDialog'
  import ColumnDialog from './components/columnDialog'
  import {
    allAlbumPage,
    getVideoPage,
    contentColumnPage,
    addContentRecommend,
    removeContentRecommend,
    toWithdraw,
    toCancelWithdraw,
    toBatchDelete,
  } from '@/api/video'
  export default {
    name: 'VideoManage',
    components: {
      SearchForm,
      TablePaging,
      ViewCallDialog,
      AlbumDialog,
      ColumnDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      searchColumn() {
        return [
          { label: '关键字', prop: 'keywords' },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            selectList: this.statusList,
          },
          {
            label: '专辑',
            prop: 'album_id',
            type: 'select',
            optionLabel: 'name',
            selectList: this.albumOption,
          },
          {
            label: '栏目',
            prop: 'column_id',
            type: 'select',
            optionLabel: 'name',
            selectList: this.columnOption,
          },
          {
            label: '付费模式',
            prop: 'pay_status',
            type: 'select',
            optionLabel: 'name',
            selectList: this.payStatusOption,
          },
          {
            label: '排序方式',
            prop: 'order',
            type: 'select',
            optionLabel: 'name',
            selectList: this.sortOption,
          },
        ]
      },
      // 表格配置
      columnList() {
        return [
          { label: '内容', slotName: 'content', align: 'left' },
          {
            label: '操作',
            isButton: true,
            width: 450,
            btnList: [
              { label: '编辑', type: 'primary', eventName: 'handleEidt' },
              {
                label: '推荐',
                type: 'primary',
                showName: 'has_recommend',
                showStatusList: [-1],
                eventName: 'recommend',
              },
              {
                label: '取消推荐',
                type: 'danger',
                showName: 'has_recommend',
                showStatusList: [1],
                eventName: 'cancelRecommend',
              },
              {
                label: '调用',
                type: 'primary',
                isObj: {
                  is_show: 1,
                  is_draft: 2,
                },
                eventName: 'handleCall',
              },
              {
                label: '撤回',
                type: 'danger',
                isObj: {
                  is_show: 1,
                  is_draft: 2,
                },
                eventName: 'handleWithdraw',
              },
              {
                label: '恢复',
                type: 'primary',
                isObj: {
                  is_show: 2,
                  is_draft: 2,
                },
                eventName: 'handleRecovery',
              },
              {
                label: '删除',
                type: 'danger',
                showName: 'is_show',
                showStatusList: [2],
                eventName: 'handleOnlyRemove',
              },
            ],
          },
        ]
      },
    },
    created() {
      this.allAlbumPage()
      this.contentColumnPage()
      this.getTableData()
    },
    methods: {
      // 获取所属专辑
      async allAlbumPage() {
        const res = await allAlbumPage()
        if (res.success) {
          this.albumOption = Object.freeze([
            {
              id: 0,
              name: '请选择所属专辑',
            },
            {
              id: -1,
              name: '暂未加入专辑',
            },
          ])
          this.albumOption = this.albumOption.concat(res.list)
        }
      },
      // 获取所属栏目
      async contentColumnPage() {
        const res = await contentColumnPage()
        if (res.success) {
          this.columnOption = [
            {
              id: 0,
              name: '请选择所属栏目',
            },
            {
              id: -1,
              name: '暂未加入栏目',
            },
          ]
          this.columnOption = this.columnOption.concat(res.list)
        }
      },
      // 列表视频图点击
      handleVideo(row) {
        if (row.code_source == 2 || row.code_source == 3) {
          window.open(row.video_url)
        }
      },
      // 发布内容
      handleCreate() {
        this.$router.push({
          path: '/video/contentManage/add',
        })
      },
      // 编辑
      handleEidt(val) {
        this.$router.push({
          path: '/video/contentManage/edit',
          query: {
            id: val.id,
          },
        })
      },
      // 移入专辑
      handleAlbum() {
        if (this.selectionList.length == 0)
          return this.$message.error('请至少选择一条数据进行操作')
        const ids = this.selectionList.map((item) => item.id).join()
        this.$refs.album.handleOpen(ids)
      },
      // 移入栏目
      handleColumn() {
        if (this.selectionList.length == 0)
          return this.$message.error('请至少选择一条数据进行操作')
        const ids = this.selectionList.map((item) => item.id).join()
        this.$refs.column.handleOpen(ids)
      },
      // 删除
      handleRemove(val, index, buttonName) {
        if (this.selectionList.length) {
          let selectionList = []
          for (let item of this.selectionList) {
            if (item.is_show == 2 && item.is_draft == 2) {
              selectionList.push(item.id)
            } else {
              this.$message.error('只能删除撤回后的内容')
              return false
            }
          }
          if (this.selectionList.length != selectionList.length) return
          this.$confirm(`该操作将会删除选中的视频数据，是否继续？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              const params = {
                ids: selectionList.join(','),
              }
              const res = await toBatchDelete(params)
              this.$message({
                type: res.success ? 'success' : 'error',
                message: res.success
                  ? `${buttonName}成功`
                  : `${buttonName}失败`,
              })
              if (res.success) this.getTableData()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
        } else {
          this.$message.error('请至少选择一条数据进行操作')
          return false
        }
      },
      // 行单独删除
      handleOnlyRemove(val, index, buttonName) {
        this.$confirm(`该操作将会删除选中的视频数据，是否继续？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              ids: val.id,
            }
            const res = await toBatchDelete(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 推荐
      async recommend(val, index, buttonName) {
        const params = {
          id: val.id,
        }
        const res = await addContentRecommend(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
        })
        if (res.success) this.getTableData()
      },
      // 取消推荐
      cancelRecommend(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前内容, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              id: val.id,
            }
            const res = await removeContentRecommend(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 调用
      handleCall(val) {
        this.$refs.viewCall.openDialog(val)
      },
      // 恢复
      async handleRecovery(val, index, buttonName) {
        const params = {
          id: val.id,
        }
        const res = await toCancelWithdraw(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
        })
        if (res.success) this.getTableData()
      },
      // 撤回
      handleWithdraw(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前内容, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              id: val.id,
            }
            const res = await toWithdraw(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getVideoPage(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.video';
  #{$base}-img {
    position: relative;
    float: left;
    margin-right: 15px;
    cursor: pointer;
    .el-image {
      display: block;
      width: 196px !important;
      height: 110px !important;
    }
    .el-icon-video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -25px;
      margin-left: -25px;
      font-size: 50px;
      color: #fff;
    }
    .duration {
      position: absolute;
      right: 8px;
      bottom: 10px;
      font-size: 12px;
      color: #fff;
    }
    span {
      line-height: 1;
    }
  }
  #{$base} {
    float: left;
    max-width: 60%;
    &-title {
      display: inline-block;
      width: 100%;
      margin: 0;
      overflow: hidden;
      font-size: 16px;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $base-color-blue;
        text-decoration: underline;
      }
    }
    &-item {
      margin-bottom: 6px;
      span {
        margin-right: 8px;
        font-size: 12px;
      }
    }
    &-id,
    &-time {
      color: $base-color-text-secondary;
    }
    &-author {
      color: $base-color-green;
    }
  }
  .text-secondary {
    color: $base-color-text-secondary;
  }
</style>
