<template>
  <div class="add-column-container">
    <div class="add-column-tips">配置内容的来源和规则。</div>
    <div class="add-column-title">基础配置</div>
    <Form :column="column1" :query="query1" ref-name="basic" />
    <el-divider />
    <div class="add-column-title">内容对接</div>
    <div class="add-column-tips">选择内容对接方式，进而配置对应调取规则</div>
    <div v-for="(item, index) in enableList" :key="index">
      <div class="add-column-item">
        <el-image class="add-column-item-image" :src="item.image" />
        <div class="add-column-item-content">
          <div class="add-column-item-content-first">{{ item.title }}</div>
          <div class="add-column-item-content-second" v-html="item.desc"></div>
        </div>
        <el-button
          size="medium"
          :type="enableBoolean(index) ? 'info' : 'primary'"
          @click="handleClickEnable(item)"
        >
          {{ enableBoolean(index) ? '已启用' : '启用' }}
        </el-button>
      </div>
      <el-collapse-transition>
        <Form
          v-if="item.title === '对接论坛' && enableBoolean(index)"
          :column="column2"
          :query="query2"
          ref-name="basic"
        />
        <Form
          v-if="item.title === '对接轻分享' && enableBoolean(index)"
          :column="column3"
          :query="query3"
          ref-name="basic"
        />
      </el-collapse-transition>
      <el-divider />
    </div>
    <div class="add-column-btn">
      <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      <el-button size="medium" @click="cancel">取消</el-button>
      <el-button
        v-if="$route.query.id"
        size="medium"
        type="danger"
        @click="onDelete"
      >
        删除
      </el-button>
    </div>

    <el-dialog title="信息" :visible.sync="dialogVisible" width="20%">
      <span>是否确认删除该信息流？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Form from '@/components/Form'
  import {
    getForumList,
    getShowList,
    catSave,
    catDelete,
  } from '@/api/information'
  import config from './config'
  export default {
    name: 'Column',
    components: {
      Form,
    },
    props: {
      query1: {
        type: Object,
        default: () => ({}),
      },
      query2: {
        type: Object,
        default: () => ({}),
      },
      query3: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return config
    },
    computed: {
      column1() {
        return [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '信息流分组',
            prop: 'type',
            type: 'select',
            list: [
              {
                id: 1,
                label: '请选择',
              },
            ],
          },
          {
            label: '封面配置',
            prop: 'cover_set',
            type: 'radio',
            list: this.coverConfigList,
          },
          {
            label: '封面图样式配置',
            prop: 'cover_style',
            type: 'radio',
            list: this.coverStyleList,
          },
          {
            label: '排序方式',
            prop: 'sort_type',
            type: 'radio',
            list: this.sortList,
            tips: '注意，若选择根据发布时间排序，若添加一条一年前发布的内容，不会出现在第一条，会按照发布时间倒序出现在后面的列表中，并不是没有添加上<br/><b v-if="query1.sort_type == 2">请修改内容范围</b>',
          },
          {
            label: '内容范围',
            prop: 'sort_max_size',
            type: 'radio',
            list: this.contentScopeList,
            tips: '客户端下拉刷新随机显示内容范围。',
            isHide: this.query1.sort_type != 2,
          },
          {
            label: '时间范围',
            prop: 'sort_time_range',
            type: 'radio',
            list: this.timeScopeList,
            tips: '随机内容的时间范围，一天指24小时，自定义天数请填写阿拉伯数字，超过该时间区间的内容显示在随机内容底部。',
            isHide: this.query1.sort_type != 2,
          },
          {
            prop: 'self_sort_time_range',
            isHide:
              this.query1.sort_time_range != 0 || this.query1.sort_type != 2,
            tips: '自定义天数',
          },
          {
            label: '首次进入是否随机',
            prop: 'is_first_rand_default',
            type: 'radio',
            list: this.whetherList1,
            tips: '选择是，则用户第一次进入时也为随机排序；选择否，用户首次进入为默认排序，下拉刷新后才变为随机排序。',
            isHide: this.query1.sort_type != 2,
          },
          {
            label: '样式选择',
            prop: 'style',
            type: 'radio',
            list: this.styleSelectionList,
            tips: '注意，若选择根据发布时间排序，若添加一条一年前发布的内容，不会出现在第一条，会按照发布时间倒序出现在后面的列表中，并不是没有添加上',
          },
          {
            label: '保存内容条数',
            prop: 'info_num_limit',
            type: 'radio',
            list: this.saveContentList,
            tips: '设置该信息流在后台能够保存的条数，超过设置的数值，则自动消失。',
          },
        ]
      },
      column2() {
        return [
          {
            label: '来源名称',
            prop: 'forum_from',
            type: 'select',
            multiple: true,
            list: this.forumList,
            optionLabel: 'name',
            optionValue: 'fid',
            tips: '选择内容来源版块，可多选',
          },
          {
            label: '发布时间',
            prop: 'forum_publish_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '互动时间',
            prop: 'forum_interact_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '阅读数大于',
            prop: 'forum_read_count',
          },
          {
            label: '评论数大于',
            prop: 'forum_comment_count',
          },
          {
            label: '只取精华',
            prop: 'forum_is_elite',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '只取图片',
            prop: 'forum_is_pic',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '只取视频',
            prop: 'forum_is_video',
            type: 'radio',
            list: this.whetherList2,
            tips: '(特指客户端发布的小视频)',
          },
          {
            label: '缓冲时间',
            prop: 'forum_buffer_time',
            tips: '单位（小时），缓冲时间内内容不会被推荐到首页<br/>首次创建会同步200条数据，后期符合条件的内容用户阅读即可触发',
          },
        ]
      },
      column3() {
        return [
          {
            label: '来源圈子',
            prop: 'show_from',
            type: 'select',
            multiple: true,
            list: this.showList,
            optionLabel: 'name',
            tips: '选择内容来源版块，可多选',
          },
          {
            label: '发布时间',
            prop: 'show_publish_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '互动时间',
            prop: 'show_interact_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '阅读数大于',
            prop: 'show_read_count',
          },
          {
            label: '评论数大于',
            prop: 'show_comment_count',
          },
          {
            label: '只取精华',
            prop: 'show_is_elite',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '只取图片',
            prop: 'show_is_pic',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '只取视频',
            prop: 'show_is_video',
            type: 'radio',
            list: this.whetherList2,
            tips: '(特指客户端发布的小视频)',
          },
          {
            label: '缓冲时间',
            prop: 'show_buffer_time',
            tips: '单位（小时），缓冲时间内内容不会被推荐到首页<br/>首次创建会同步200条数据，后期符合条件的内容用户阅读即可触发',
          },
        ]
      },
    },
    created() {
      this.getForumList()
      this.getShowList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      enableBoolean(index) {
        if (index === 0) {
          return this.enableList[index].isEnable || this.query2.is_forum
        } else {
          return this.enableList[index].isEnable || this.query3.is_show
        }
      },
      // 开启
      handleClickEnable(item) {
        item.isEnable = !item.isEnable
      },
      // 获取来源板块
      getForumList() {
        var params = { step: 20 }
        getForumList(params).then((res) => {
          this.forumList = res.list
        })
      },
      // 获取来源圈子
      getShowList() {
        var params = { step: 20 }
        getShowList(params).then((res) => {
          this.showList = res.list
        })
      },
      // 保存
      onSubmit() {
        let params = { ...this.query1, config: {} }
        params._config = {
          ...this.query2,
          ...this.query3,
          style: this.query1.style,
          group_id: 0,
          cover_set: this.query1.cover_set,
          cover_style: this.query1.cover_style,
          is_forum: this.enableBoolean(0),
          // is_show: this.enableBoolean(1),
        }
        if (params._config.forum_from)
          params._config.forum_from = params._config.forum_from.join(',')
        if (params._config.show_from)
          params._config.show_from = params._config.show_from.join(',')
        params.self_sort_time_range = Number(params.self_sort_time_range)
        params.sort_time_range = Number(
          params.sort_time_range || params.self_sort_time_range
        )
        params._config = JSON.stringify(params._config)
        if (params.sort_time_range == 0 && params.self_sort_time_range <= 0) {
          return this.$baseMessage('自定义时间必须大于0', 'error')
        }
        if (this.$route.query.id) params.id = this.$route.query.id
        catSave(params).then(async (res) => {
          if (res.success === true) {
            await this.delVisitedRoute('/info/addcolumn')
            await this.delVisitedRoute('/info/addInformation')
            this.$router.push('/info/flowManage')
          } else this.$baseMessage(res.msg, 'error')
        })
      },
      async cancel() {
        await this.delVisitedRoute('/info/addcolumn')
        this.$router.push('/info/addInformation')
      },
      // 点击删除
      onDelete() {
        this.dialogVisible = true
      },
      onSure() {
        const params = { id: this.$route.query.id }
        catDelete(params).then(async (res) => {
          if (res.success) {
            this.dialogVisible = false
            await this.delVisitedRoute('/info/show')
            this.$router.push('/')
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-column';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
