var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-manage-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-width": 80,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          "is-pagination": true,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          selection: true,
          "table-data": _vm.list,
        },
        on: {
          cancelRecommend: _vm.cancelRecommend,
          handleAlbum: _vm.handleAlbum,
          handleCall: _vm.handleCall,
          handleColumn: _vm.handleColumn,
          handleCreate: _vm.handleCreate,
          handleCurrentChange: _vm.handleCurrentChange,
          handleEidt: _vm.handleEidt,
          handleOnlyRemove: _vm.handleOnlyRemove,
          handleRecovery: _vm.handleRecovery,
          handleRemove: _vm.handleRemove,
          handleSelectionChange: _vm.handleSelectionChange,
          handleWithdraw: _vm.handleWithdraw,
          recommend: _vm.recommend,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "video-img" },
                  [
                    _c("el-image", {
                      attrs: { src: row.cover_pic_url },
                      on: {
                        click: function ($event) {
                          return _vm.handleVideo(row)
                        },
                      },
                    }),
                    _c("span", { staticClass: "el-icon-video-play" }),
                    _c("span", { staticClass: "duration" }, [
                      _vm._v(_vm._s(row.duration_str)),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "video" }, [
                  _c(
                    "p",
                    {
                      staticClass: "video-title",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleEidt(row)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(row.title) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "video-item text-secondary" },
                    [
                      _c("span", { staticClass: "video-play-num" }, [
                        _vm._v(" " + _vm._s(row.virtual_play_num) + " "),
                        row.play_num > 0
                          ? _c("span", [
                              _vm._v("(" + _vm._s(row.play_num) + ")"),
                            ])
                          : _vm._e(),
                        _vm._v(" 播放 "),
                      ]),
                      _c("span", { staticClass: "video-comment-num" }, [
                        _vm._v(_vm._s(row.comment_num) + " 评论"),
                      ]),
                      _c("span", { staticClass: "video-praise-num" }, [
                        _vm._v(_vm._s(row.praise_num) + " 点赞"),
                      ]),
                      row.pay_type == 1
                        ? _c("el-tag", { attrs: { size: "mini" } }, [
                            _vm._v("免费"),
                          ])
                        : row.pay_type == 4
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "danger" } },
                            [_vm._v(" 仅会员 ")]
                          )
                        : _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "warning" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(row.pay_amount) +
                                  _vm._s(_vm.config.virtual_currency_unit) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "video-item" },
                    [
                      row.is_show == 1 &&
                      row.is_draft == 2 &&
                      row.timing <= _vm.nowTime
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "warning" } },
                            [_vm._v(" 已发布 ")]
                          )
                        : _vm._e(),
                      row.is_show == 2 && row.is_draft == 2
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "danger" } },
                            [_vm._v(" 已撤回 ")]
                          )
                        : _vm._e(),
                      row.is_show == 1 &&
                      row.is_draft == 2 &&
                      row.timing > _vm.nowTime
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "warning" } },
                            [_vm._v(" 定时发布中 ")]
                          )
                        : _vm._e(),
                      row.is_draft == 1
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "info" } },
                            [_vm._v(" 草稿 ")]
                          )
                        : _vm._e(),
                      row.album.title
                        ? _c("el-tag", { attrs: { size: "mini" } }, [
                            _vm._v(" " + _vm._s(row.album.title) + " "),
                          ])
                        : _vm._e(),
                      _vm._l(row.column_list, function (item) {
                        return _c(
                          "el-tag",
                          {
                            key: item.id,
                            attrs: { size: "mini", type: "info" },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "video-item text-secondary" }, [
                    _c("span", [_vm._v(_vm._s(row.source))]),
                    row.is_draft == 2
                      ? _c("span", [
                          _vm._v(" " + _vm._s(row.timing_formate) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("ViewCallDialog", { ref: "viewCall" }),
      _c("AlbumDialog", {
        ref: "album",
        on: { getTableData: _vm.getTableData },
      }),
      _c("ColumnDialog", {
        ref: "column",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }