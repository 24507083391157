import Layout from '@/vab/layouts'

const application = {
  path: '/audit',
  name: 'Audit',
  component: Layout,
  meta: {
    title: '审核',
    icon: 'file-2-line',
  },
  children: [
    {
      path: 'circleTheme',
      name: 'CircleTheme',
      component: () => import('@/views/audit/circleThemeAudit'),
      meta: {
        title: '圈子主题审核',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'circleComment',
      name: 'CircleComment',
      component: () => import('@/views/audit/circleCommentAudit'),
      meta: {
        title: '圈子评论审核',
        // icon: 'computer-fill',
      },
    },
    // {
    //   path: 'forumTheme',
    //   name: 'ForumTheme',
    //   component: () => import('@/views/audit/forumThemeAudit'),
    //   meta: {
    //     title: '论坛主题审核',
    //     // icon: 'computer-fill',
    //   },
    // },
    // {
    //   path: 'forumComment',
    //   name: 'ForumComment',
    //   component: () => import('@/views/audit/forumCommentAudit'),
    //   meta: {
    //     title: '论坛评论审核',
    //     // icon: 'computer-fill',
    //   },
    // },
    {
      path: 'newsComment',
      name: 'NewsComment',
      component: () => import('@/views/audit/newsCommentAudit'),
      meta: {
        title: '头条评论审核',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'intellectPublish',
      name: 'IntellectPublish',
      component: () => import('@/views/audit/intellectPublish'),
      meta: {
        title: '头条智能发布',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'circleDetails',
      name: 'CircleDetails',
      component: () => import('@/views/audit/circleDetails'),
      meta: {
        hidden: true,
        dynamicNewTab: true,
        title: '主题详情',
        // title: '修改内容-混合备用型',
      },
    },
    // {
    //   path: 'rewardMessage',
    //   name: 'RewardMessage',
    //   component: () => import('@/views/audit/rewardMessage'),
    //   meta: {
    //     title: '打赏留言管理',
    //     // icon: 'computer-fill',
    //   },
    // },
  ],
}

export default application
