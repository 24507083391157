import { render, staticRenderFns } from "./common.vue?vue&type=template&id=274c30a1&scoped=true&"
import script from "./common.vue?vue&type=script&lang=js&"
export * from "./common.vue?vue&type=script&lang=js&"
import style0 from "./common.vue?vue&type=style&index=0&id=274c30a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274c30a1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paul/Documents/project/beimei_app/metatopnews/metatopnews-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('274c30a1')) {
      api.createRecord('274c30a1', component.options)
    } else {
      api.reload('274c30a1', component.options)
    }
    module.hot.accept("./common.vue?vue&type=template&id=274c30a1&scoped=true&", function () {
      api.rerender('274c30a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/information/flowManage/Show/components/common.vue"
export default component.exports