var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Common", {
    attrs: {
      from: _vm.type,
      query1: _vm.query1,
      query2: _vm.query2,
      "read-num-list": _vm.readNumList,
    },
    on: {
      createRandom: _vm.createRandom,
      getAsyncWechatContent: _vm.getAsyncWechatContent,
      handleImageUrl: _vm.handleImageUrl,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }