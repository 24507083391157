<template>
  <Common
    :query="query"
    @createRandom="createRandom"
    @handleImageUrl="handleImageUrl"
    @handleVideoUrl="handleVideoUrl"
  />
</template>

<script>
  import mixins from './mixins'
  import { videoLoad } from '@/api/video'
  export default {
    mixins: [mixins],
    created() {
      if (this.$route.query.id) this.videoLoad()
    },
    methods: {
      // 获取视频数据
      videoLoad() {
        let params = { id: this.$route.query.id }
        videoLoad(params).then((res) => {
          this.detailInfo = res.data
          // query
          for (let key of Object.keys(res.data)) {
            for (let key1 of Object.keys(this.query)) {
              if (key1 === key) {
                if (key == 'sort_time' || key == 'timing') {
                  this.query[key1] = res.data[key] * 1000
                } else {
                  this.query[key1] = res.data[key]
                }
              } else if (key == 'column_ids') {
                this.query['column_id'] = []
                res.data[key].split('#').filter((item) => {
                  if (item && item.trim()) {
                    this.query['column_id'].push(Number(item))
                  }
                })
              }
            }
          }
          if (res.data.code_source == 6) {
            this.query.local_video_url1 = res.data.video_url
          }
          if (
            res.data.is_open_try_see == 1 &&
            res.data.try_see_code_source == 6
          ) {
            this.query.local_video_url2 = res.data.try_see_video_url
          }
        })
      },
    },
  }
</script>
