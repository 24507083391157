<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
  >
    <Form
      :column="column"
      :label-width="100"
      :query="query"
      ref-name="dialog"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelFixed">取消固定</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getFixedPosition,
    saveFixed,
    getFixedTitle,
    cancelInfoFixed,
  } from '@/api/information'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        row: {},
        query: {
          position: null,
          beg_time: null,
          end_time: null,
          title: '',
        },
        index: 0,
        list: [],
        positionList: [],
      }
    },
    computed: {
      column() {
        return [
          {
            label: '固定位置',
            type: 'select',
            prop: 'position',
            optionLabel: 'name',
            optionValue: 'value',
            list: this.positionList,
          },
          {
            label: '开始时间',
            prop: 'beg_time',
            type: 'startDaterangePicker',
            timeType: 'datetime',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            type: 'endDaterangePicker',
            timeType: 'datetime',
            tips: '未设置结束时间将永久固定',
          },
          {
            label: '固定内容',
            prop: 'title',
            type: 'textarea',
            disabled: true,
          },
        ]
      },
    },
    methods: {
      handleOpen(title, val) {
        this.title = title
        this.row = val
        if (val.fixedInfo && val.fixedInfo.length != 0) {
          const { position, beg_time, end_time } = val.fixedInfo
          this.query.position = position
          this.query.beg_time = beg_time * 1000
          this.query.end_time = end_time * 1000
        } else {
          this.query.beg_time = new Date().getTime()
          this.query.end_time = new Date().getTime() + 24 * 60 * 60 * 1000
        }
        this.dialogVisible = true
        this.getFixedPosition()
        this.getFixedTitle()
      },
      // 获取固定内容
      async getFixedTitle() {
        const params = {
          list_type: 1,
          list_type_value: this.row.cat_id,
          position: this.query.position || 1,
        }
        const res = await getFixedTitle(params)
        if (res.success) {
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              this.query.title += `${res.data[i].title}\n`
            }
          }
        }
      },
      // 获取是否固定列表
      async getFixedPosition() {
        const params = {
          list_type: 1,
          list_type_value: this.row.cat_id,
        }
        const res = await getFixedPosition(params)
        if (res.success) {
          this.positionList = res.list
          this.query.position = res.list[0].value
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = {
          ...this.query,
          list_type: 1,
          list_type_value: this.row.cat_id,
          content_type: 1,
          content_id: this.row.id,
        }
        params.beg_time = params.beg_time ? params.beg_time / 1000 : null
        params.end_time = params.end_time ? params.end_time / 1000 : null
        params.info = JSON.stringify({
          title: `${
            this.query.title.indexOf('固定内容：') > -1 ? '' : '固定内容：'
          }${this.query.title || this.row.title}`,
        })
        delete params.title
        const res = await saveFixed(params)
        this.loading = false
        if (res.success) this.$emit('getTableData')
        this.cancel()
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `固定成功` : `固定失败`,
        })
      },
      // 取消固定
      cancelFixed() {
        if (this.loading) return
        this.$confirm('是否确认取消该内容的固定位置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            this.loading = true
            const params = {
              cat_id: this.row.cat_id,
              id: this.row.id,
            }
            const res = await cancelInfoFixed(params)
            this.loading = false
            if (res.success) this.$emit('getTableData')
            this.cancel()
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `取消固定成功` : `取消固定失败`,
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 关闭
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          this.positionList = []
          for (let key in this.query) {
            this.query[key] = ''
          }
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tips {
    color: $base-color-red !important;
  }
</style>
