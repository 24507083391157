<template>
  <div class="manage-community-container">
    <SearchForm
      :btn-col="0"
      :label-width="0"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @handleChangeStatus="handleChangeStatus"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
      @handleDelete="handleDelete"
      @handleEdit="handleEdit"
      @handlePreview="handlePreview"
    >
      <!-- 内容 -->
      <template #content="{ row }">
        <div
          class="content-info"
          :style="{
            display:
              row.pic_type != 4 && row.pics_arr.length > 1 ? 'block' : 'flex',
          }"
        >
          <ul>
            <li>
              <span class="content-info-title" @click.stop="jumpDetail(row)">
                {{ row.title }}
              </span>
            </li>
            <li>
              <span class="content-info-from">{{ row.from }}</span>
              <span class="text-secondary" style="margin-left: 20px">
                {{ row.publish_time | dateFormat(true) }}
              </span>
            </li>
            <li>
              <span class="text-secondary">{{ row.click }}阅读</span>
              <span class="text-secondary" style="margin-left: 20px">
                id: {{ row.id }}
              </span>
            </li>
          </ul>
          <template v-if="row.pic_type != 4 && row.pics_arr.length">
            <el-image
              v-for="(item, index) in row.pics_arr"
              :key="index"
              :preview-src-list="[
                item.tburl.slice(0, 1) === '/'
                  ? baseURL + item.tburl
                  : item.tburl,
              ]"
              :src="
                item.tburl.slice(0, 1) === '/'
                  ? baseURL + item.tburl
                  : item.tburl
              "
            />
          </template>
          <el-image
            v-if="row.pic_type != 4 && row.pics_arr.length"
            :preview-src-list="[row.pics_arr[0].tburl]"
            :src="row.pics_arr[0].tburl"
          />
        </div>
      </template>
      <!-- 其他信息 -->
      <template #other="{ row }">
        <span>所在版块</span>
        <el-tag class="content-info-other" type="info">
          {{ row.circle.name ? row.circle.name : '后台发布' }}
        </el-tag>
      </template>
    </TablePaging>
    <PreviewDialog ref="previewDialog" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import {
    getContentStatusOptions,
    getCommunityPage,
    communityDelete,
    showDeleteRecover,
    deleteInfoReal,
  } from '@/api/information'
  import { baseURL } from '@/config'
  import PreviewDialog from '@/components/QRcode/previewDialog.vue'
  export default {
    components: {
      SearchForm,
      TablePaging,
      PreviewDialog,
    },
    mixins: [tableMixins],
    data() {
      return {
        query: {
          status: null,
          cat_id: this.$route.query.id,
        },
        // 表格最大高度
        maxHeight: document.body.clientHeight - 340,
        configList: [],
        columnList: [
          { label: '内容', slotName: 'content', align: 'left' },
          { label: '其他信息', slotName: 'other', align: 'left' },
          {
            label: '操作',
            isButton: true,
            width: 350,
            btnList: [
              { label: '编辑', type: 'primary', eventName: 'handleEdit' },
              {
                label: '屏蔽',
                type: 'primary',
                showName: 'delete_time',
                showStatusList: [null, undefined],
                eventName: 'handleDelete',
              },
              {
                label: '取消屏蔽',
                type: 'primary',
                showName: 'delete_time',
                notEmpty: true,
                eventName: 'handleDelete',
              },
              {
                label: '预览',
                type: 'primary',
                eventName: 'handlePreview',
              },
              {
                label: '删除',
                type: 'danger',
                disabledName: 'type',
                disabledStatusList: [6],
                eventName: 'handleDelete',
              },
            ],
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      searchColumn() {
        return [
          {
            label: '',
            prop: 'status',
            type: 'select',
            clearable: false,
            optionLabel: 'name',
            eventName: 'handleChangeStatus',
            selectList: this.configList,
          },
        ]
      },
    },
    created() {
      this.getContentStatusOptions().then(() => {
        this.getTableData()
      })
    },
    methods: {
      // 获取状态列表
      async getContentStatusOptions() {
        const data = await getContentStatusOptions()
        if (data.success) {
          this.configList = data.list
          this.query.status = data.list[0].id
        }
      },
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getCommunityPage(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = Number(res.total)
        })
      },
      jumpDetail(row) {
        if (row.link) window.open(window.open(`${baseURL}${row.link}`))
      },
      // 预览
      handlePreview(row) {
        this.$refs.previewDialog.handleOpen(`${baseURL}${row.link}`)
      },
      handleChangeStatus() {
        this.pageInfo.page = 1
        this.getTableData()
      },
      handleEdit(val) {
        const { id, type } = this.$route.query
        this.$router.push({
          path: `operation/edit?cat_id=${id}&tid=${val.id}&type=${type}`,
        })
      },
      // 屏蔽/取消屏蔽/删除
      handleDelete(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前内容, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            let params = {}
            if (buttonName == '屏蔽') {
              params.id = val.id
              params.cat_id = this.query.cat_id
              res = await communityDelete(params)
            } else if (buttonName == '取消屏蔽') {
              params.id = val.id
              params.cat_id = this.query.cat_id
              res = await showDeleteRecover(params)
            } else if (buttonName == '删除') {
              params.ids = val.id
              res = await deleteInfoReal(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.manage-community';
  $table-info: '.content-info';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
  #{$table-info} {
    display: flex;
    justify-content: space-between;
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 5px 0;
      }
    }
    .el-image {
      margin-left: 20px;
      width: 160px !important;
      height: 118px !important;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      color: $base-color-black;
      cursor: pointer;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      &:hover {
        text-decoration: underline;
        color: $base-color-blue;
      }
    }
    &-from {
      color: $base-color-blue;
    }
  }
  #{$table-info}-other {
    margin-left: 15px;
  }
  .text-secondary {
    color: $base-color-text-secondary;
  }
</style>
