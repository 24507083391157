import { render, staticRenderFns } from "./viewCallDialog.vue?vue&type=template&id=01450415&"
import script from "./viewCallDialog.vue?vue&type=script&lang=js&"
export * from "./viewCallDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paul/Documents/project/beimei_app/metatopnews/metatopnews-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01450415')) {
      api.createRecord('01450415', component.options)
    } else {
      api.reload('01450415', component.options)
    }
    module.hot.accept("./viewCallDialog.vue?vue&type=template&id=01450415&", function () {
      api.rerender('01450415', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/videoManage/content/components/viewCallDialog.vue"
export default component.exports