var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vab-layout-horizontal",
      class: {
        fixed: _vm.fixedHeader,
        "no-tabs-bar": !_vm.showTabs,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "vab-layout-header",
          class: {
            "fixed-header": _vm.fixedHeader,
          },
        },
        [
          _c("vab-header"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTabs,
                  expression: "showTabs",
                },
              ],
              class: {
                "vab-tabs-horizontal": _vm.showTabs,
              },
            },
            [_c("div", { staticClass: "vab-main" }, [_c("vab-tabs")], 1)]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vab-main main-padding" },
        [_c("vab-app-main")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }