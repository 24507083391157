<template>
  <div class="flow-manage-container">
    <SearchForm
      :label-position="'left'"
      :label-width="60"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :is-pagination="true"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @configInfo="configInfo"
      @handleCreate="handleCreate"
      @handleCurrentChange="handleCurrentChange"
      @settingPermanent="settingPermanent"
      @viewCall="viewCall"
    >
      <!-- 状态 -->
      <template #type="{ row }">
        <template v-for="item in typeList">
          <span v-if="row.type == item.id" :key="item.id">
            {{ item.label.indexOf('论坛') === -1 ? item.label : '创作者' }}
          </span>
        </template>
      </template>
      <!-- 排序 -->
      <template #sortTime="{ row }">
        <el-input
          v-model="row.sort_time"
          type="number"
          @input="handleInput(row.id, row.sort_time)"
        />
      </template>
    </TablePaging>
    <ViewCallDialog ref="viewCall" />
  </div>
</template>

<script>
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import ViewCallDialog from './components/viewCallDialog'
  import {
    getInforMationList,
    editCatPermanent,
    updateSort,
  } from '@/api/information'
  import config from './config'
  export default {
    name: 'FlowManage',
    components: {
      SearchForm,
      TablePaging,
      ViewCallDialog,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '组',
            prop: 'group_id',
            type: 'select',
            selectList: [{ id: '0', label: '请选择' }],
          },
          { label: '关键字', prop: 'keywords' },
        ]
      },
    },
    watch: {
      $route(to) {
        if (to.path === '/info/flowManage') {
          this.getTableData()
        }
      },
    },
    created() {
      this.getTableData()
    },
    methods: {
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getInforMationList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      handleInput(id, sort) {
        let params = { id, sort }
        updateSort(params).then((res) => {
          if (res.success) this.getTableData()
        })
      },
      // 新增信息流
      handleCreate() {
        this.$router.push({ path: '/info/addInformation' })
      },
      // 设置常驻
      settingPermanent(val, index, buttonName) {
        let params = {
          id: val.id,
          is_permanent: buttonName == '设为常驻' ? 1 : -1,
        }
        editCatPermanent(params).then((res) => {
          if (res.success) {
            this.getTableData()
            location.reload()
          }
        })
      },
      // 调用
      viewCall(val) {
        this.$refs.viewCall.openDialog(val.id)
      },
      // 配置/管理内容
      configInfo(val, index, label) {
        let activeName = label == '配置' ? 2 : 1
        let pathItem = this.typeList.find((item) => item.id == val.type)
        this.$router.push({
          path: `/info/${pathItem.path}`,
          query: {
            id: val.id,
            type: val.type,
            activeName: activeName,
            title: val.name,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-input {
    width: 90px;
  }
</style>
