<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('定时发布')"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form
      ref="timingForm"
      :column="column"
      :query="query"
      ref-name="timing"
      :rules="rules"
    />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveTiming('timing')">
        {{ translateTitle('确 定') }}
      </el-button>
      <el-button type="danger" @click="cancel">
        {{ translateTitle('取 消') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Form from '@/components/Form'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        dialogVisible: false,
        query: {
          time: null,
        },
        rules: {
          time: [
            {
              type: 'date',
              required: true,
              message: '请选择定时发布日期',
              trigger: ['change', 'blur'],
            },
          ],
        },
        column: [
          {
            label: '定时发布时间',
            prop: 'time',
            type: 'datetime',
          },
        ],
      }
    },
    methods: {
      translateTitle,
      handleOpen() {
        this.dialogVisible = true
      },
      // 定时发布保存
      saveTiming(formName) {
        this.$refs.timingForm.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit('handleRelease', 'timing', this.query.time)
            this.cancel()
          }
        })
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.timingForm.resetFields()
      },
    },
  }
</script>
