export default {
  vabI18n: {
    首页: 'Home',
    看板: 'Dashboard',
    工作台: 'Workbench',
    更新日志: 'Change log',
    配置: 'Settings',
    个人中心: 'User center',
    用户管理: 'User management',
    角色管理: 'Role management',
    部门管理: 'Department management',
    菜单管理: 'Menu management',
    系统日志: 'System log',
    组件: 'Part',
    其他: 'Other',
    角色权限: 'Roles',
    外链: 'External links',
    图标: 'Icon',
    常规图标: 'Awesome icon',
    小清新图标: 'Remix icon',
    图标选择器: 'Icon selector',
    表格: 'Table',
    综合表格: 'Comprehensive table',
    行内编辑表格: 'Inline edit table',
    自定义表格: 'Custom table',
    表单: 'Form',
    综合表单: 'Comprehensive form',
    分步表单: 'Step form',
    按钮: 'Button',
    文字链接: 'Link',
    单选框: 'Radip',
    多选框: 'Checkbox',
    输入框: 'Input',
    计数器: 'Input number',
    选择器: 'Select',
    开关: 'Switch',
    滑块: 'Slider',
    时间选择器: 'Time picker',
    日期选择器: 'Date picker',
    日期时间选择器: 'Date time picker',
    评分: 'rate',
    工作流: 'Workflow',
    图表: 'Echarts',
    打印: 'Print',
    手机预览: 'Mobile preview',
    头像裁剪: 'Head cropper',
    时间线: 'Timeline',
    数字自增长: 'Count',
    多标签: 'Tabs',
    动态Meta: 'Dynamic meta',
    动态路径参数: 'Dynamic segment',
    拖拽: 'Drag',
    弹窗拖拽: 'Dialog drag',
    卡片拖拽: 'Card drag',
    流程图拖拽: 'Flowsheet drag',
    右键菜单: 'Contextmenu',
    列表: 'List',
    多级路由缓存: 'Menu1',
    '多级路由1-1': 'Menu1-1',
    '多级路由1-1-1': 'Menu1-1-1',
    加载: 'Loading',
    视频播放器: 'Player',
    编辑器: 'Editor',
    Markdown编辑器: 'Markdown editor',
    富文本编辑器: 'Rich text editor',
    上传: 'Upload',
    错误日志模拟: 'Log',
    Excel: 'Excel',
    导出Excel: 'Export excel',
    导出选中行Excel: 'Export selected excel',
    导出合并Excel: 'Export merge header excel',
    Css动画: 'Cssfx',
    第三方登录: 'Social login',
    物料源: 'Material',
    物料市场: 'Material market',
    错误页: 'Error',
    403: '403',
    404: '404',
    邮件: 'Email',
    清空消息: 'Clear message',
    更多: 'More',
    关闭其他: 'Close other',
    关闭左侧: 'Close left',
    关闭右侧: 'Close right',
    关闭全部: 'Close all',
    退出登录: 'Logout',
    登录: 'Login',
    验证码: 'Verification code',
    注册: 'register',
    主题配置: 'Theme',
    主题: 'Theme',
    常用设置: 'Common settings',
    其它设置: 'Other settings',
    分栏布局时生效: 'Column layout takes effect',
    标签开启时生效: 'Effective when the label is opened',
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
      'The layout configuration only takes effect in the computer window,the vertical layout will be locked in the mobile window by default',
    默认: 'Default',
    海洋之心: 'Ocean',
    绿荫草场: 'Green',
    碰触纯白: 'White',
    月上重火: 'Red',
    蓝黑: 'Blue black',
    蓝白: 'Blue white',
    绿黑: 'Green black',
    绿白: 'Green white',
    红黑: 'Red black',
    红白: 'Red white',
    渐变: 'Ocean',
    布局: 'Layouts',
    分栏: 'Column',
    纵向: 'Vertical',
    横向: 'Horizontal',
    综合: 'Comprehensive',
    常规: 'Common',
    浮动: 'Float',
    菜单背景: 'Background',
    分栏风格: 'Column style',
    箭头: 'Arrow',
    头部固定: 'Header',
    固定: 'Fixed',
    不固定: 'No fixed',
    标签: 'Tabs',
    标签风格: 'Tabs style',
    标签图标: 'Tabs Icon',
    卡片: 'Card',
    灵动: 'Smart',
    圆滑: 'Smooth',
    国际化: 'Language',
    进度条: 'Progress',
    刷新: 'Refresh',
    通知: 'Notice',
    全屏: 'Full screen',
    搜索: 'Search',
    开启: 'Open',
    关闭: 'Close',
    随机换肤: 'Random',
    购买源码: 'Buy',
    拷贝源码: 'Code',
    恢复默认: 'Defalut',
    清理缓存: 'Claer',
    保存: 'Save',
    欢迎来到: 'Welcome to',
    请输入用户名: 'Please enter one user name',
    请输入密码: 'Please input a password',
    用户名不能为空: 'The user name cannot be empty',
    密码不能少于6位: 'The password cannot be less than 6 digits',
    请输入正确的手机号: 'Please enter the correct mobile phone number',
    请输入手机号: 'Please enter your mobile phone number',
    请输入手机验证码: 'Please input the mobile phone verification code',
    获取验证码: 'Get captcha',
    活动: 'activity',
    活动列表: 'Activity List',
    广告中心: 'Advertising Center',
    系统广告: 'System advertisement',
    自定义广告: 'Custom Advertising',
    状态: 'state',
    全部活动: 'All activities',
    进行中: 'have in hand',
    已结束: 'Closed',
    关键字: 'keyword',
    请输入关键字: 'Please enter keywords',
    重置: 'Reset',
    发布新活动: 'Publish new activities',
    复制推广链接: 'Copy promotion link',
    标题: 'title',
    封面: 'cover',
    '阅读量（真实/虚拟）': 'Reading volume (real/virtual)',
    发布时间: 'Release time',
    排序: 'sort',
    操作: 'operation',
    推荐: 'recommend',
    管理: 'Administration',
    编辑: 'edit',
    删除: 'delete',
    发布活动: 'Publishing activities',
    活动类型: 'activity type',
    活动标题: 'Activity Title',
    请输入活动标题: 'Please enter the activity title',
    '建议标题字数在18-25个字之间':
      'It is suggested that the number of words in the title should be between 18-25',
    活动封面: 'Activity Cover',
    建议尺寸580x290px: 'Recommended size 580x290px',
    无: 'nothing',
    广告: 'advertisement',
    亲子: 'Parenting',
    加精: 'Refinement',
    美食: 'delicious food',
    热点: 'Hotspot',
    房产: 'Property',
    婚嫁: 'Marriage',
    户外: 'Outdoor',
    商家: 'Merchant',
    置顶: 'Top',
    红包: 'Red envelope',
    '激励标签（系统）': 'Incentive label (system)',
    活动开始时间: 'Activity start time',
    活动结束时间: 'Activity end time',
    报名开始时间: 'Registration start time',
    报名结束时间: 'Registration end time',
    虚拟阅读数: 'Virtual Reading',
    更多说明: 'More instructions',
    活动报名: 'Activity registration',
    报名必填项目: 'Required items for registration',
    活动支付: 'Activity Payment',
    支付金额: 'Payment amount',
    详细内容: 'Details',
    请输入虚拟阅读数: 'Please enter the number of virtual readings',
    重新生成: 'Rebuild',
    新增一组: 'Add a group',
    请输入标题: 'Please enter the title',
    请输入内容: 'Please enter the content',
    '请输入跳转(选填)': 'Please enter jump (optional)',
    手机号: 'phone',
    姓名: 'name',
    微信: 'WeChat',
    备注: 'remarks',
    请填写支付金额: 'Please fill in the payment amount',
    填写格式如: 'The filling format is as follows',
    则该活动应支付金额为: 'The amount payable for this activity is',
    立即发布: 'Publish Now',
    定时发布: 'Scheduled release',
    存为草稿: 'Save as Draft',
    元: 'element',
    '内容已被设置为定时发布，发布时间':
      'The content has been set to be published regularly at',
    '确 定': 'determine',
    '取 消': 'Cancel',
    请选择定时发布时间: 'Please select the scheduled publishing time',
    '请填写支付金额，并且不能小于':
      'Please fill in the payment amount and it cannot be less than',
    请填写更多说明项目标题:
      'Please fill in the title of more descriptive items',
    请填写更多说明项目内容: 'Please fill in more description items',
    保存成功: 'Saving succeeded',
    取消推荐: 'Cancel recommendation',
    活动功能: 'Activity function',
    活动推广: 'Event promotion',
    活动详情: 'Event Details',
    现场管理: 'Site management',
    签到: 'Sign in',
    报名审核: 'Enrollment review',
    审核开关: 'Audit switch',
    复制: 'copy',
    活动详情二维码下载: 'QR code download of activity details',
    开启审核后用户报名后需后台通过管理员审核才能成功报名:
      'After the approval is enabled, the user can register successfully only after being approved by the administrator in the background',
    '保 存': 'Save',
    开启签到后用户可以在现场通过扫码完成签到:
      'After the sign in is enabled, the user can complete the sign in by scanning the code on site',
    签到二维码下载: 'Sign in QR code download',
    '开屏、资讯列表、主题列表为系统支持的广告配置位。':
      'Open screen, information list and topic list are the advertising configuration bits supported by the system. ',
    查看示例: 'View Example',
    开屏广告: 'Open screen advertisement',
    首页浮动广告: 'Home page floating advertisement',
    新增广告: 'New ads',
    图片: 'picture',
    显示时间: 'Display time',
    显示状态: 'Show Status',
    位置: 'position',
    全部状态: 'All Status',
    正在显示: 'Displaying',
    未显示: 'Not shown',
    已关闭: 'Closed',
    开屏广告在用户每次启动app时显示:
      'The opening screen advertisement is displayed every time the user starts the app',
    取消: 'cancel',
    广告位: 'Advertising space',
    是否显示: 'Whether to display',
    是: 'yes',
    否: 'no',
    广告备注: 'Advertising remarks',
    请输入广告备注: 'Please enter advertisement comments',
    审核配置: 'Audit Configuration',
    类型选择: 'Type selection',
    动图: 'Kinetograph',
    视频: 'video',
    样式选择: 'Style Selection',
    非全屏显示: 'Non full screen display',
    全屏显示: 'Full screen display',
    上传图片: 'Upload pictures',
    点击热区文案配置: 'Click Hot Zone Copy Configuration',
    请输入点击热区文案配置:
      'Please enter the click hot area copy configuration',
    跳转: 'Jump',
    请输入跳转: 'Please enter jump',
    是否显示广告标签: 'Whether to display advertising labels',
    广告分类: 'Advertising classification',
    请选择广告分类: 'Please select an advertisement category',
    开始时间: 'start time',
    结束时间: 'End time',
    单天生效时间: 'Single day effective time',
    请输入: 'Please enter',
    '默认“点击查看内容详情”，15字以内，超过15字将显示不全，不配置文案则不展示热区，开屏只有点击热区才会进行跳转':
      'By default, "click to view content details". Within 15 words, more than 15 words will not be displayed completely. If no copy is configured, the hot area will not be displayed. The screen will jump only after clicking the hot area',
    选择跳转地址: 'Select Jump Address',
    '选填，可以配置app内部任意页面的跳转链接。':
      'Optional, you can configure the jump link of any page in the app.',
    '该广告显示多长时间,如果不填写默认显示3秒(单位: 秒)':
      'How long will the advertisement be displayed? If it is not filled in, it will be displayed for 3 seconds by default (unit: second)',
    请填写时间: 'Please fill in the time',
    视频不能为空: 'Video cannot be empty',
    '请选择分类！': 'Please select a category!',
    时间必须小于24: 'Time must be less than 24',
    开始时间不得大于结束时间:
      'The start time cannot be greater than the end time',
    '新增-广告信息-开屏广告':
      'Add - Advertising information - Open screen advertising',
    '新增-广告信息-首页浮动广告':
      'New - advertising information - home page floating ads',
    '首页图展样式配置在组件添加时设定，可以在组件配置中二次编辑':
      'The style configuration of the home page exhibition is set when adding components, and can be edited twice in the component configuration',
    显示标题: 'show heading',
    请输入显示标题: 'Please enter the display title',
    建议尺寸280x280px: 'Recommended size 280x280px',
    '可进行幻灯，天气模块的配置与管理':
      'Configuration and management of slide show and weather module',
    图片轮播: 'Picture carousel',
    天气模块: 'Weather module',
    '频道页：附近频道': 'Channel page: nearby channels',
    '频道页：精彩推荐': 'Channel page: excellent recommendations',
    发现页: 'Discovery Page',
    '广告在列表中显示的固定位置，数字相同时最新发布的广告靠前':
      'The fixed position of the advertisement in the list, with the latest advertisement in front of the same number',
    '<p>组件样式一建议尺寸：750*328px</p><p>组件样式二建议尺寸：750*234px</p><p>组件样式三建议尺寸：750*140px</p>':
      '<p>Recommended size of component style I: 750 * 328px</p><p>Recommended size of component style II: 750 * 234px</p><p>Recommended size of component style III: 750 * 140px</p>',
    '<p>建议尺寸640x280px</p><p>建议尺寸674x296px</p>':
      '<p>Recommended size 640x280px</p><p>Recommended size 674x296px</p>',
    配置项: 'Configuration Item',
    苹果审核版显示: 'Apple review version display',
    苹果普通版显示: 'Apple Normal Display',
    安卓审核版显示: 'Android audit version display',
    安卓普通版显示: 'Android normal display',
    '若选择审核版，则用户看不到配置的内容，若选择普通版，则审核人员看不到配置的内容':
      'If the audit version is selected, the user cannot see the configured content; if the normal version is selected, the auditor cannot see the configured content',
    '修改-广告信息-天气模块——发现页':
      'Modification - advertising information - weather module - discovery page',
    '图片轮播-频道页：附近频道':
      'Picture carousel - channel page: nearby channels',
    '图片轮播-频道页：精彩推荐':
      'Picture carousel - channel page: wonderful recommendations',
    '天气模块：发现页': 'Weather Module: Discovery Page',
    '图片轮播-4.3版本之前首页':
      'Picture carousel - homepage before version 4.3',
    '修改-广告信息-首页浮动广告':
      'Modification - advertising information - home page floating advertisement',
  },
}
