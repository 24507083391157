var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.translateTitle("定时发布"),
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("Form", {
        ref: "timingForm",
        attrs: {
          column: _vm.column,
          query: _vm.query,
          "ref-name": "timing",
          rules: _vm.rules,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveTiming("timing")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("确 定")) + " ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("取 消")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }