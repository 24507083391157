<template>
  <div class="push-error-list-container">
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 创建时间 -->
      <template #create_time="{ row }">
        <span>{{ row.create_time | dateFormat(true) }}</span>
      </template>
      <!-- 错误描述 -->
      <template #error_des="{ row }">
        <span v-if="row.error_code">{{ row.error_des }}</span>
        <span v-else>{{ row.body }}</span>
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import { getPushErrorList } from '@/api/operate'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        // 表格最大高度
        maxHeight: document.body.clientHeight - 340,
        list: [],
      }
    },
    computed: {
      columnList() {
        return [
          { label: '类型', prop: 'type' },
          { label: '标题', prop: 'title' },
          { label: '创建时间', slotName: 'create_time' },
          { label: '错误码', prop: 'error_code' },
          { label: '错误描述', slotName: 'error_des' },
        ]
      },
    },
    created() {
      this.getTableData()
    },
    methods: {
      translateTitle,
      // 查询信息流列表
      getTableData() {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        this.loading = true
        getPushErrorList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.push-error-list';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
</style>
