const config = {
  dialogVisible: false,
  // 封面配置
  coverConfigList: Object.freeze([
    {
      id: 1,
      label: '居左',
    },
    {
      id: 2,
      label: '居右',
    },
  ]),
}

export default config
