const config = {
  time: new Date().getTime(),
  query: {
    type: 1,
    user_ids: [],
    sex: 0,
    active: 1,
    tagGroup: null,
    groupTag: '',
    title: null,
    content: null,
    img: null,
    jump_type: 1,
    jump_value: null,
    once_push: 10,
  },
  tagGroup: [{ id: 1, label: '运营标签' }],
  groupTag: [{ id: '', label: '请选择' }],
  activeType: Object.freeze([
    {
      type: 1,
      is_active: false,
      active: 0,
    },
    {
      type: 2,
      is_active: true,
      active: 7,
    },
    {
      type: 3,
      is_active: false,
      active: 7,
    },
    {
      type: 4,
      is_active: true,
      active: 15,
    },
    {
      type: 5,
      is_active: false,
      active: 15,
    },
    {
      type: 6,
      is_active: true,
      active: 30,
    },
    {
      type: 7,
      is_active: false,
      active: 30,
    },
  ]),
  linkTypeList: Object.freeze([
    {
      id: 1,
      label: '链接',
    },
    {
      id: 2,
      label: '轻分享',
    },
    {
      id: 3,
      label: '帖子',
    },
  ]),
  oncePushList: Object.freeze([
    {
      id: 10,
      label: '10',
    },
    {
      id: 50,
      label: '50',
    },
    {
      id: 100,
      label: '100',
    },
    {
      id: 200,
      label: '200',
    },
  ]),
  typeList: Object.freeze([
    {
      id: 1,
      label: '全部用户',
    },
    {
      id: 2,
      label: '特定用户',
    },
    {
      id: 3,
      label: '标签用户',
    },
  ]),
  sexList: Object.freeze([
    {
      id: 0,
      label: '全部',
    },
    {
      id: 1,
      label: '男',
    },
    {
      id: 2,
      label: '女',
    },
  ]),
  activeList: Object.freeze([
    {
      id: 1,
      label: '全部',
    },
    {
      id: 2,
      label: '最近一周活跃',
    },
    {
      id: 3,
      label: '最近一周不活跃',
    },
    {
      id: 4,
      label: '最近半个月活跃',
    },
    {
      id: 5,
      label: '最近半个月不活跃',
    },
    {
      id: 6,
      label: '最近一个月活跃',
    },
    {
      id: 7,
      label: '最近一个月不活跃',
    },
  ]),
}

export default config
