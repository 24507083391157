<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="cancel"
  >
    <SearchForm
      ref="searchForm"
      :column-span="18"
      :label-position="'left'"
      :label-width="80"
      :query="query"
      :reset-show="false"
      :search-column="searchColumn"
      @getTableData="getCatSelectData"
      @resetForm="resetForm"
    />
    <Form
      v-loading="loading"
      :column="column"
      :label-show="false"
      :label-width="0"
      :query="params"
      ref-name="origin"
      @handleCheck="handleCheck"
    />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="references">引用内容</el-button>
      <el-button type="primary" @click="move('copy')">复制移动</el-button>
      <el-button type="primary" @click="move('shear')">剪切移动</el-button>
      <el-button @click="cancel">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import SearchForm from '@/components/SearchForm'
  import tableMixins from '@/mixins/tableCommon.vue'
  import Form from '@/components/Form'
  import { catSelect, moveInfoSave, quoteInfoSave } from '@/api/operate'
  export default {
    name: 'MoveDialog',
    components: {
      SearchForm,
      Form,
    },
    mixins: [tableMixins],
    data() {
      return {
        query: {
          name: '',
          type: '1',
          ids: '',
        },
        title: '移动内容',
        catSelectList: [],
        dialogFormVisible: false,
        params: {
          cat_ids: [],
        },
      }
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '标题关键字',
            prop: 'name',
          },
        ]
      },
      column() {
        return [
          {
            label: '',
            prop: 'cat_ids',
            type: 'checkbox',
            optionLabel: 'name',
            list: this.catSelectList,
            eventName: 'handleCheck',
          },
        ]
      },
    },
    methods: {
      openDialog(val) {
        // this.query.type = val.type
        this.query.ids = val.id
        this.getCatSelectData()
        this.dialogFormVisible = true
      },
      // 获取移动内容
      async getCatSelectData(value) {
        let params = {
          type: this.query.type,
          name: this.query.name,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        const res = await catSelect(params)
        this.loading = false
        if (res.success) {
          this.catSelectList = res.list
        }
      },
      // 引用内容
      async references() {
        const params = {
          ids: this.query.ids,
          cat_ids: this.params.cat_ids.join(','),
        }
        const res = await quoteInfoSave(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? '引用成功' : '引用失败',
        })
        if (res.success) this.cancel()
      },
      // 复制移动/剪切移动
      async move(name) {
        let flag = name === 'copy' ? 1 : 0
        const params = {
          flag,
          ids: this.query.ids,
          cat_ids: this.params.cat_ids.join(','),
        }
        const res = await moveInfoSave(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? '移动成功' : '移动失败',
        })
        if (res.success) this.cancel()
      },
      // 重置回调（勿删）
      resetForm() {},
      handleCheck() {},
      // 关闭
      cancel() {
        this.params.cat_ids = []
        this.$refs.searchForm.resetForm()
        this.dialogFormVisible = false
      },
    },
  }
</script>
