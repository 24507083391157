<template>
  <Common
    :query="query"
    @createRandom="createRandom"
    @handleImageUrl="handleImageUrl"
    @handleVideoUrl="handleVideoUrl"
  />
</template>

<script>
  import mixins from './mixins'
  export default {
    mixins: [mixins],
  }
</script>
