var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        { ref: "viewCall", attrs: { "label-width": "80px", model: _vm.query } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户端调用", prop: "link" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.query.link,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.query,
                      "link",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "query.link",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "wap调用", prop: "wap_url" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.query.wap_url,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.query,
                      "wap_url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "query.wap_url",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }