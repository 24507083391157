<template>
  <div class="search">
    <el-form
      ref="search"
      :label-position="labelPosition"
      :label-width="`${labelWidth}px`"
      :model="query"
    >
      <el-row>
        <el-col :span="24 - btnCol">
          <template v-for="(item, index) in searchColumn">
            <template v-if="!item.isHide">
              <el-col :key="index" :span="item.span || columnSpan">
                <!-- 输入框 -->
                <el-form-item
                  v-if="item.type === 'input' || !item.type"
                  :label="labelShow ? translateTitle(item.label) : ''"
                  :prop="item.prop"
                >
                  <el-input
                    v-model="params[item.prop]"
                    :clearable="item.clearable !== false"
                    :placeholder="translateTitle(`请输入${item.label}`)"
                    @keyup.enter.native="handleKeyup(item.keyupName)"
                  />
                </el-form-item>
                <el-form-item
                  v-else-if="item.type === 'startDaterangePicker'"
                  :label="labelShow ? item.label : ''"
                  :prop="item.prop"
                >
                  <!--开始时间-->
                  <el-date-picker
                    v-model="params[item.prop]"
                    :clearable="item.clearable !== false"
                    :picker-options="pickerStartOptions"
                    :placeholder="translateTitle(`${item.label}`)"
                    style="width: 100%"
                    :type="item.timeType || 'date'"
                    :value-format="item.format || 'timestamp'"
                    @focus="() => handleFocusDate('start', index)"
                  />
                </el-form-item>
                <!--结束时间-->
                <el-form-item
                  v-else-if="item.type === 'endDaterangePicker'"
                  :label="labelShow ? translateTitle(item.label) : ''"
                  :prop="item.prop"
                >
                  <el-date-picker
                    v-model="params[item.prop]"
                    :clearable="item.clearable !== false"
                    :picker-options="pickerEndOptions"
                    :placeholder="translateTitle(`${item.label}`)"
                    style="width: 100%"
                    :type="item.timeType || 'date'"
                    :value-format="item.format || 'timestamp'"
                    @focus="() => handleFocusDate('end', index)"
                  />
                </el-form-item>
                <!-- 下拉框 -->
                <el-form-item
                  v-else-if="item.type === 'select'"
                  :label="labelShow ? translateTitle(item.label) : ''"
                  :prop="item.prop"
                >
                  <el-select
                    v-model="params[item.prop]"
                    :clearable="item.clearable !== false"
                    :collapse-tags="item.collapseTags || false"
                    :filterable="item.filterable || false"
                    :multiple="item.multiple || false"
                    :placeholder="translateTitle(`请选择${item.label}`)"
                    @change="handleChange(item.eventName)"
                  >
                    <el-option
                      v-for="(el, i) in item.selectList"
                      :key="i"
                      :label="translateTitle(el[item.optionLabel || 'label'])"
                      :value="el[item.optionValue || 'id']"
                    />
                  </el-select>
                </el-form-item>
                <!-- 时间框 -->
                <el-form-item
                  v-else-if="
                    item.type === 'date' ||
                    item.type === 'month' ||
                    item.type === 'datetime'
                  "
                  :label="labelShow ? translateTitle(item.label) : ''"
                  :prop="item.prop"
                >
                  <el-date-picker
                    v-model="params[item.prop]"
                    :clearable="item.clearable !== false"
                    :placeholder="translateTitle(`请选择${item.label}`)"
                    :type="item.type || 'date'"
                    :value-format="item.format || 'timestamp'"
                  />
                </el-form-item>
                <!-- 单选框 -->
                <el-form-item
                  v-else-if="item.type === 'radio'"
                  :label="labelShow ? translateTitle(item.label) : ''"
                  :prop="item.prop"
                >
                  <el-radio-group
                    v-model="params[item.prop]"
                    @change="handleChange(item.eventName)"
                  >
                    <el-radio
                      v-for="(el, i) in item.radioList"
                      :key="i"
                      :label="translateTitle(el[item.optionValue || 'id'])"
                    >
                      {{ translateTitle(el[item.optionLabel || 'label']) }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </template>
          </template>
        </el-col>
        <el-col align="right" :span="btnCol">
          <el-button icon="el-icon-search" type="primary" @click="handleSearch">
            {{ translateTitle('搜索') }}
          </el-button>
          <el-button v-if="resetShow" @click="resetForm">
            {{ translateTitle('重置') }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  export default {
    name: 'SearchForm',
    props: {
      // 按钮区域宽度
      btnCol: {
        type: Number,
        default: 4,
      },
      // 每个条件搜索的宽度
      columnSpan: {
        type: Number,
        default: 6,
      },
      // 搜索数据
      query: {
        type: Object,
        default: () => ({}),
      },
      // 搜索配置
      searchColumn: {
        type: Array,
        default: () => [],
      },
      // 重置按钮显示
      resetShow: {
        type: Boolean,
        default: true,
      },
      // 标签宽度
      labelWidth: {
        type: [Number, String],
        default: 110,
      },
      labelPosition: {
        type: String,
        default: 'right',
      },
      // 标题显示
      labelShow: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        pickerStartOptions: {}, // 开始日期配置
        pickerEndOptions: {}, // 结束日期配置
        params: this.query,
      }
    },
    methods: {
      translateTitle,
      // 键盘事件
      handleKeyup(eventName) {
        this.$emit(eventName, this.params)
      },
      // 下拉框变换
      handleChange(eventName) {
        if (eventName) this.$emit(eventName, this.params)
      },
      // 点击搜索
      handleSearch() {
        this.$emit('getTableData', this.params)
      },
      // 重置按钮
      resetForm() {
        this.$refs.search.resetFields()
        this.$emit('resetForm')
      },
      // 开始结束日期
      handleFocusDate(type, index) {
        const dateProp =
          type === 'start'
            ? this.searchColumn[index + 1].prop
            : this.searchColumn[index - 1].prop

        const pickerOptions = {
          disabledDate: (time) => {
            return this.disabledDate(time, dateProp, type)
          },
        }
        if (type === 'start') {
          this.pickerStartOptions = pickerOptions
        } else {
          this.pickerEndOptions = pickerOptions
        }
      },
      // 开始结束日期禁用日期
      disabledDate(time, prop, type) {
        if (type === 'end') {
          const startDate = this.params[prop]
          if (startDate) {
            return time.getTime() < new Date(startDate).getTime()
          }
        } else {
          const endDate = this.params[prop]
          if (endDate) {
            return time.getTime() > new Date(endDate).getTime()
          }
        }
      },
    },
  }
</script>
