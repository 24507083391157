<template>
  <div class="table">
    <!-- 按钮 -->
    <div v-if="buttonList.length" class="button-area">
      <el-button
        v-for="(item, index) in buttonList"
        :key="index"
        :icon="item.icon"
        :size="item.size"
        :type="item.type"
        @click="handleBtnClick($event, item.eventName, item, index, item.label)"
      >
        {{ translateTitle(item.label) }}
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table
      v-if="isTable"
      :ref="refName"
      v-loading="loading"
      border
      :data="tableData"
      fit
      :header-cell-style="headClass"
      highlight-current-row
      :max-height="maxHeight"
      :show-header="showHeader"
      @cell-click="handleCellClick"
      @expand-change="expandChange"
      @row-click="handleRowClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="selection" type="selection" width="55" />
      <el-table-column v-if="isIndex" type="index" width="50" />
      <!-- 折叠 -->
      <el-table-column v-if="isExpand" type="expand">
        <template slot-scope="row">
          <!-- 具名插槽（根据传入的插槽名称，父组件用同样名称来接收row数据） -->
          <slot :index="row.$index" name="expand" :row="row.row" />
        </template>
      </el-table-column>
      <template v-for="(item, index) in columnList">
        <el-table-column
          v-if="item.slotName || item.isButton"
          :key="index"
          :align="item.align || 'center'"
          :fit="item.fit || false"
          :label="translateTitle(item.label)"
          :prop="item.prop"
          :width="item.width || null"
        >
          <template slot-scope="row">
            <!-- 具名插槽（根据传入的插槽名称，父组件用同样名称来接收row数据） -->
            <slot
              v-if="item.slotName"
              :index="row.$index"
              :name="item.slotName"
              :row="row.row"
            />
            <!-- 按钮 -->
            <template v-else-if="item.isButton">
              <template v-for="el in item.btnList">
                <el-button
                  v-if="isShow(el, row)"
                  :key="el.id"
                  :disabled="isDisabled(el, row)"
                  :size="el.size || 'mini'"
                  :type="el.type"
                  @click.stop="
                    handleBtnClick(
                      $event,
                      el.eventName,
                      row.row,
                      row.$index,
                      el.label
                    )
                  "
                >
                  {{ translateTitle(el.label) }}
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          v-else
          :key="index"
          :align="item.align || 'center'"
          :fit="item.fit || false"
          :label="translateTitle(item.label)"
          :prop="item.prop"
          :show-overflow-tooltip="!item.fit || false"
          :width="item.width || null"
        />
      </template>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      v-if="isPagination"
      background
      :current-page="pageInfo.page"
      layout="total, prev, pager, next, jumper"
      :page-count="pageInfo.page_count"
      :page-size="pageInfo.step"
      :total="pageInfo.count"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  export default {
    props: {
      // 列表加载动画
      loading: {
        type: Boolean,
        default: false,
      },
      // 是否显示序号
      isIndex: {
        type: Boolean,
        default: false,
      },
      // 是否显示列表树
      isExpand: {
        type: Boolean,
        default: false,
      },
      // 是否显示多选框
      selection: {
        type: Boolean,
        default: false,
      },
      // 是否显示表格
      isTable: {
        type: Boolean,
        default: true,
      },
      // 是否显示表头
      showHeader: {
        type: Boolean,
        default: true,
      },
      // 表格最大高度
      maxHeight: {
        type: [Number, String],
        default: document.body.clientHeight - 172,
      },
      // 分页数据
      pageInfo: {
        type: Object,
        default: () => ({}),
      },
      // 列表数据
      tableData: {
        type: Array,
        default: () => [],
      },
      // 列表配置数据
      columnList: {
        type: Array,
        default: () => [],
      },
      // 图片预览列表
      srcList: {
        type: Array,
        default: () => [],
      },
      refName: {
        type: String,
        default: 'table',
      },
      // 按钮操作区
      buttonList: {
        type: Array,
        default: () => [],
      },
      // 分页显示
      isPagination: {
        type: Boolean,
        default: true,
      },
      // 唯一字段值
      rowKey: {
        type: String,
        default: 'id',
      },
      // 当只有一页时隐藏分页
      hideOnSinglePage: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      translateTitle,
      // 数据多选
      handleSelectionChange(val) {
        this.$emit('handleSelectionChange', val)
      },
      // 表头样式设置
      headClass() {
        return 'background: rgb(205, 231, 255)'
      },
      // 翻页
      handleCurrentChange(val) {
        this.$emit('handleCurrentChange', val)
      },
      // 下拉树展开
      expandChange(val) {
        this.$emit('expandChange', val)
      },
      // 单行点击
      handleRowClick(row, column, event) {
        if (this.selection) {
          this.$refs[this.refName].toggleRowSelection(row)
        }
        this.$emit('handleRowClick', row, column, event)
      },
      handleCellClick(row, column, cell, event) {
        this.$emit('handleCellClick', row, column, cell, event)
      },
      /** 自定义按钮事件
       *  eventName 事件名称
       *  val 传递的值
       *  index 当前点击按钮的索引
       *  buttonName 当前点击按钮的名称
       */
      handleBtnClick(e, eventName, val, index, buttonName) {
        if (!eventName) return
        this.$emit(eventName, val, index, buttonName, e)
      },
      /** 检测是否显示
       * item按钮每一项
       * row每一行参数
       * showName显示的属性字段，不传使用当前行的index
       * showStatusList 显示状态数组
       * 按钮默认显示状态，函数默认返回true
       */
      isShow(item, row) {
        if (item.showStatusList) {
          const flag = item.showName ? row.row[item.showName] : row.$index
          return item.showStatusList.includes(flag)
        } else if (item.notEmpty) {
          return row.row[item.showName]
        } else if ('isHide' in item) {
          return item.isHide
        } else if (item.isObj) {
          let i = 0
          for (let key of Object.keys(item.isObj)) {
            if (item.isObj[key] === row.row[key]) {
              i++
            }
          }
          if (Object.keys(item.isObj).length == i) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      },
      /** 检测是否禁用
       * item按钮每一项
       * row每一行参数
       * disabledName禁止的属性字段，不传使用当前行的index
       * disabledStatusList 禁用状态数组
       * 按钮默认不禁用状态，函数默认返回false
       */
      isDisabled(item, row) {
        if (item.disabledStatusList) {
          const flag = item.disabledName
            ? row.row[item.disabledName]
            : row.$index
          return item.disabledStatusList.includes(flag)
        } else {
          return false
        }
      },
      // 获取选中数据
      getRowKeys(row) {
        return row[this.rowKey]
      },
      // 清除选中数据
      clearSelection() {
        this.$refs[this.refName].clearSelection()
      },
      // 进入默认选中
      toggleSelection(rows, key) {
        if (rows.length) {
          rows.forEach((row) => {
            this.tableData.forEach((item) => {
              if (row === item[key]) {
                this.$refs[this.refName].toggleRowSelection(item, true)
              }
            })
          })
        } else {
          this.clearSelection()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .button-area {
    margin-bottom: 15px;
  }
  .table {
    .el-table {
      .el-button {
        margin: 5px;
      }
    }
  }
</style>
