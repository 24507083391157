var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: _vm.refName,
      attrs: {
        "label-position": _vm.labelPosition,
        "label-width": _vm.labelWidth + "px",
        model: _vm.query,
        rules: _vm.rules,
      },
    },
    [
      _vm._l(_vm.column, function (item, index) {
        return [
          item.title
            ? _c("div", { key: index + "-only", staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.translateTitle(item.title)) + " "),
              ])
            : _vm._e(),
          !item.isHide
            ? [
                item.type === "input" || !item.type
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: item.clearable !== false,
                            placeholder: _vm.translateTitle(
                              "请输入" + (item.label || "")
                            ),
                            readonly: item.readonly || false,
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        item.clickText
                          ? _c(
                              "span",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.clickEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.clickText)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.tipsBtn
                          ? _c(
                              "div",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.tipsEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.tipsBtn)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === "text"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          "label-width":
                            (item.labelWidth || _vm.labelWidth) + "px",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.translateTitle(item.text))),
                        ]),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                        item.tipsBtn
                          ? _c(
                              "span",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.tipsEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.tipsBtn)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                item.type === "textarea"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            autosize: { minRows: 6 },
                            clearable: item.clearable !== false,
                            disabled: item.disabled,
                            placeholder: _vm.translateTitle(
                              "请输入" + (item.label || "")
                            ),
                            type: "textarea",
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "click-tips",
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent(
                                  item.clickEventName,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.translateTitle(item.clickText)) +
                                " "
                            ),
                          ]
                        ),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "number"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            controls: item.controls || false,
                            max: item.max,
                            min: item.min || 0,
                            placeholder: _vm.translateTitle(
                              "请输入" + (item.label || "")
                            ),
                            precision: item.precision || 0,
                            step: item.step || 1,
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        item.clickText
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.clickEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.clickText)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "inputGroup"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "m-r-10",
                          attrs: {
                            clearable: item.clearable !== false,
                            placeholder: _vm.translateTitle(
                              "请输入" + item.placeholder[0]
                            ),
                          },
                          model: {
                            value: _vm.params[item.prop[0]],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop[0], $$v)
                            },
                            expression: "params[item.prop[0]]",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            clearable: item.clearable !== false,
                            placeholder: _vm.translateTitle(
                              "请输入" + item.placeholder[1]
                            ),
                          },
                          model: {
                            value: _vm.params[item.prop[1]],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop[1], $$v)
                            },
                            expression: "params[item.prop[1]]",
                          },
                        }),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "select"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: item.clearable !== false,
                              "collapse-tags": item.collapseTags || false,
                              filterable: item.filterable || false,
                              multiple: item.multiple || false,
                              placeholder: _vm.translateTitle(
                                "请选择" + item.label
                              ),
                              remote: item.remote || false,
                              "value-key": "id",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleEvent(item.eventName, $event)
                              },
                            },
                            model: {
                              value: _vm.params[item.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.params, item.prop, $$v)
                              },
                              expression: "params[item.prop]",
                            },
                          },
                          _vm._l(item.list, function (el, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: {
                                label: _vm.translateTitle(
                                  el[item.optionLabel || "label"]
                                ),
                                value: el[item.optionValue || "id"],
                              },
                            })
                          }),
                          1
                        ),
                        item.clickText
                          ? _c(
                              "span",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.clickEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.clickText)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                        item.tipsBtn
                          ? _c(
                              "span",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.tipsEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.tipsBtn)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "filterSelect"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("treeselect", {
                          attrs: {
                            id: "select-" + item.prop,
                            async: true,
                            clearable: item.clearable !== false,
                            "default-options": item.defaultOptions || false,
                            "instance-id": item.prop,
                            "load-options": _vm.loadOptions,
                            multiple: item.multiple || false,
                            options: item.list,
                            placeholder: _vm.translateTitle(
                              "请选择" + item.label
                            ),
                            "search-prompt-text":
                              _vm.translateTitle("请输入关键词"),
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "radio"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleEvent(item.eventName, $event)
                              },
                            },
                            model: {
                              value: _vm.params[item.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.params, item.prop, $$v)
                              },
                              expression: "params[item.prop]",
                            },
                          },
                          _vm._l(item.list, function (el, i) {
                            return _c(
                              "el-radio",
                              {
                                key: i,
                                attrs: { label: el[item.optionValue || "id"] },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.translateTitle(
                                        el[item.optionLabel || "label"]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        item.showBtn
                          ? _c(
                              "el-button",
                              {
                                staticClass: "btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick(item.btnEvent)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.btn)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "checkbox"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleEvent(item.eventName, $event)
                              },
                            },
                            model: {
                              value: _vm.params[item.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.params, item.prop, $$v)
                              },
                              expression: "params[item.prop]",
                            },
                          },
                          _vm._l(item.list, function (el, i) {
                            return _c(
                              "el-checkbox",
                              {
                                key: i,
                                attrs: {
                                  disabled: el.disabled,
                                  label: el[item.optionValue || "id"],
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.translateTitle(
                                        el[item.optionLabel || "label"]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                        item.clickText
                          ? _c(
                              "span",
                              {
                                staticClass: "click-tips",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      item.clickEventName,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translateTitle(item.clickText)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "startDaterangePicker"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: item.clearable !== false,
                            "picker-options": _vm.pickerStartOptions,
                            placeholder: _vm.translateTitle("" + item.label),
                            type: item.timeType || "date",
                            "value-format": item.format || "timestamp",
                          },
                          on: {
                            focus: function () {
                              return _vm.handleFocusDate("start", index)
                            },
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                      ],
                      1
                    )
                  : item.type === "endDaterangePicker"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: item.clearable !== false,
                            "picker-options": _vm.pickerEndOptions,
                            placeholder: _vm.translateTitle("" + item.label),
                            type: item.timeType || "date",
                            "value-format": item.format || "timestamp",
                          },
                          on: {
                            focus: function () {
                              return _vm.handleFocusDate("end", index)
                            },
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              style: "margin-top: " + item.top,
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "date" ||
                    item.type === "month" ||
                    item.type === "datetime"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            clearable: item.clearable !== false,
                            placeholder: _vm.translateTitle(
                              "请选择" + item.label
                            ),
                            type: item.type || "date",
                            "value-format": item.format || "timestamp",
                          },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                      ],
                      1
                    )
                  : item.type === "btn"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: item.size || "mini",
                              type: item.btnType,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEvent(item.eventName, $event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.translateTitle(item.btnText)) +
                                " "
                            ),
                          ]
                        ),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              style: "margin-top: " + item.top,
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "uploadImage"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("UploadImage", {
                          attrs: {
                            "event-name": item.eventName,
                            height: item.height,
                            "image-url": _vm.params[item.prop],
                            limit: item.limit,
                            prop: item.prop,
                            width: item.width,
                          },
                          on: { handleImageUrl: _vm.handleImageUrl },
                        }),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "uploadVideo"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("UploadVideo", {
                          attrs: {
                            "event-name": item.eventName,
                            height: item.height,
                            limit: item.limit,
                            prop: item.prop,
                            "video-url": _vm.params[item.prop],
                            width: item.width,
                          },
                          on: { handleVideoUrl: _vm.handleVideoUrl },
                        }),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.type === "richTextEditor"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          prop: item.prop,
                        },
                      },
                      [
                        _c("vue-ueditor-wrap", {
                          attrs: { config: _vm.myConfig },
                          on: { beforeInit: _vm.addCustomDialog },
                          model: {
                            value: _vm.params[item.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, item.prop, $$v)
                            },
                            expression: "params[item.prop]",
                          },
                        }),
                        _c("el-upload", {
                          ref: "elUpload",
                          refInFor: true,
                          attrs: {
                            accept: _vm.imageFiles,
                            action: _vm.myConfig.imageUrl,
                            "auto-upload": true,
                            "before-upload": _vm.beforeImageUpload,
                            "on-success": _vm.handleImageSuccess,
                            "show-file-list": false,
                          },
                        }),
                      ],
                      1
                    )
                  : item.type === "slot"
                  ? _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: _vm.labelShow
                            ? _vm.translateTitle(item.label)
                            : "",
                          "label-width":
                            (item.labelWidth || _vm.labelWidth) + "px",
                          prop: item.prop,
                        },
                      },
                      [
                        item.slotName ? _vm._t(item.slotName) : _vm._e(),
                        item.sign
                          ? _c("span", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.sign)
                                ),
                              },
                            })
                          : _vm._e(),
                        item.tips
                          ? _c("p", {
                              staticClass: "tips",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.translateTitle(item.tips)
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }