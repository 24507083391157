<template>
  <Common
    :from="type"
    :query1="query1"
    :query2="query2"
    :read-num-list="readNumList"
    @createRandom="createRandom"
    @getAsyncWechatContent="getAsyncWechatContent"
    @handleImageUrl="handleImageUrl"
  />
</template>

<script>
  import mixins from './mixins'
  export default {
    mixins: [mixins],
    data() {
      return {
        type: 'add',
      }
    },
  }
</script>
