import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5fb588ca&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paul/Documents/project/beimei_app/metatopnews/metatopnews-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fb588ca')) {
      api.createRecord('5fb588ca', component.options)
    } else {
      api.reload('5fb588ca', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5fb588ca&", function () {
      api.rerender('5fb588ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vab/components/VabLanguage/index.vue"
export default component.exports