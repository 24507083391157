var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-column-container" },
    [
      _c("div", { staticClass: "add-column-tips" }, [
        _vm._v("配置内容的来源和规则。"),
      ]),
      _c("div", { staticClass: "add-column-title" }, [_vm._v("基础配置")]),
      _c("Form", {
        attrs: { column: _vm.column1, query: _vm.query1, "ref-name": "basic" },
      }),
      _c("el-divider"),
      _c("div", { staticClass: "add-column-title" }, [_vm._v("内容对接")]),
      _c("div", { staticClass: "add-column-tips" }, [
        _vm._v("选择内容对接方式，进而配置对应调取规则"),
      ]),
      _vm._l(_vm.enableList, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "div",
              { staticClass: "add-column-item" },
              [
                _c("el-image", {
                  staticClass: "add-column-item-image",
                  attrs: { src: item.image },
                }),
                _c("div", { staticClass: "add-column-item-content" }, [
                  _c("div", { staticClass: "add-column-item-content-first" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", {
                    staticClass: "add-column-item-content-second",
                    domProps: { innerHTML: _vm._s(item.desc) },
                  }),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "medium",
                      type: _vm.enableBoolean(index) ? "info" : "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleClickEnable(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.enableBoolean(index) ? "已启用" : "启用") +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-collapse-transition",
              [
                item.title === "对接论坛" && _vm.enableBoolean(index)
                  ? _c("Form", {
                      attrs: {
                        column: _vm.column2,
                        query: _vm.query2,
                        "ref-name": "basic",
                      },
                    })
                  : _vm._e(),
                item.title === "对接轻分享" && _vm.enableBoolean(index)
                  ? _c("Form", {
                      attrs: {
                        column: _vm.column3,
                        query: _vm.query3,
                        "ref-name": "basic",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("el-divider"),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "add-column-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _vm.$route.query.id
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "danger" },
                  on: { click: _vm.onDelete },
                },
                [_vm._v(" 删除 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "信息", visible: _vm.dialogVisible, width: "20%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否确认删除该信息流？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }