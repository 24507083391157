<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('快捷评论')"
    :visible.sync="dialogVisible"
    width="60%"
  >
    <el-row v-loading="loading" class="m-b-15" :gutter="20">
      <el-col v-for="(item, index) in allVestList" :key="index" :span="10">
        <el-dropdown
          placement="bottom-start"
          trigger="click"
          @command="handleCommand($event, index)"
          @visible-change="visibleChange"
        >
          <span
            class="head"
            :style="{ backgroundImage: `url(${item.head})` }"
          ></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="title" disabled>
              元宇宙用户选择器
            </el-dropdown-item>
            <el-input
              v-model="downQuery.name"
              class="inputname"
              placeholder="请输入马甲账号"
              @input="handleScreen"
            />
            <template v-if="!screening">
              <el-dropdown-item class="second-title" disabled divided>
                最近常用马甲
              </el-dropdown-item>
              <el-dropdown-item
                v-for="(el, i) in recentVestList"
                :key="el.user_id"
                :command="el"
                :divided="i !== 0"
              >
                <span
                  class="head"
                  :style="{ backgroundImage: `url(${el.head})` }"
                ></span>
                <span class="username">{{ el.name }}</span>
                <img alt="" class="level" :src="el.group_ico_src" />
                <span>
                  <i>{{ translateTitle('发布') }}：{{ el.post }}</i>
                  <i>{{ translateTitle('评论') }}：{{ el.post_comment }}</i>
                </span>
              </el-dropdown-item>
            </template>
            <div v-if="!screening" class="recommended second-title">
              <span>推荐使用</span>
              <el-link :underline="false" @click="exchange">
                换一批
                <i class="el-icon-refresh"></i>
              </el-link>
            </div>
            <el-dropdown-item
              v-for="(el, i) in vestList"
              :key="el.id"
              :command="el"
              :divided="i !== 0"
            >
              <span
                class="head"
                :style="{ backgroundImage: `url(${el.head})` }"
              ></span>
              <span class="username">{{ el.name }}</span>
              <img alt="" class="level" :src="el.group_ico_src" />
              <span>
                <i>{{ translateTitle('发布') }}：{{ el.post }}</i>
                <i>{{ translateTitle('评论') }}：{{ el.post_comment }}</i>
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-input v-model="item.content" :placeholder="item.name" />
      </el-col>
    </el-row>
    <div v-if="isRecoveryRate" class="recoveryRate">
      <el-checkbox v-model="recoveryRate">指定回复速率</el-checkbox>
      <template v-if="recoveryRate">
        <el-input v-model="public_minute" placeholder="请输入分钟数" />
        <span>分钟内全部发布</span>
      </template>
    </div>
    <p class="tip">
      提示：第一条评论1分钟内生效,其他评论会按照半小时到一个半小时间隔发布
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveComment">
        {{ translateTitle('确定') }}
      </el-button>
      <el-button
        v-if="allVestList.length > 4"
        type="primary"
        @click="changeBatch"
      >
        {{ translateTitle('换一批') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { randomNum } from '@/utils'
  import {
    listUserVestPage,
    quickCommentTheme,
    getRecentVestList,
  } from '@/api/audit'
  export default {
    props: {
      themetype: {
        type: [Number, String],
        default: 1,
      },
      isRecoveryRate: {
        type: Boolean,
        default: true,
      },
      showNumber: {
        type: [Number, String],
        default: 8,
      },
    },
    data() {
      return {
        dialogVisible: false,
        loading: false,
        screening: false,
        recoveryRate: false,
        val: {},
        query: {
          page: 1,
          step: 20,
          is_audit: false,
        },
        downQuery: {
          page: 1,
          step: 10,
          extra: 1,
          name: '',
        },
        public_minute: 0,
        allVestList: [],
        recentVestList: [],
        vestList: [],
      }
    },
    methods: {
      translateTitle,
      handleOpen(val) {
        this.val = val
        this.query.page = 1
        const params = JSON.parse(JSON.stringify(this.query))
        this.listUserVestPage(params)
        this.dialogVisible = true
      },
      // 获取元宇宙用户
      async listUserVestPage(params) {
        this.loading = true
        const res = await listUserVestPage(params)
        this.loading = false
        if (res.success) {
          if (res.list.length > this.showNumber) {
            this.allVestList = randomNum(res.list, this.showNumber)
          } else {
            this.allVestList = res.list
          }
        }
      },
      // 更换列表换一批
      exchange() {
        if (this.vestList.length < 10) {
          this.downQuery.page = 1
        } else {
          this.downQuery.page++
        }
        const params = JSON.parse(JSON.stringify(this.downQuery))
        this.listVest(params)
      },
      // 更换列表点选
      handleCommand(val, index) {
        if (
          this.allVestList.filter((item) => item.user_id === val.user_id)
            .length > 0
        )
          return this.$message({
            type: 'error',
            message: '用户已存在，请重新选择！',
          })
        this.$set(this.allVestList, index, val)
      },
      // 下拉菜单显示隐藏
      visibleChange(bool) {
        if (bool) {
          this.getRecentVestList()
          const params = JSON.parse(JSON.stringify(this.downQuery))
          this.listVest(params)
        } else {
          this.query.page = 1
          this.downQuery.page = 1
          this.downQuery.name = ''
        }
      },
      // 元宇宙用户筛选
      handleScreen(value) {
        if (value) {
          const params = {
            name: value,
          }
          this.screening = true
          this.listVest(params)
        } else {
          this.screening = false
          this.getRecentVestList()
          const params = JSON.parse(JSON.stringify(this.downQuery))
          this.listVest(params)
        }
      },
      // 获取常用元宇宙用户
      async getRecentVestList() {
        const res = await getRecentVestList()
        if (res.success) this.recentVestList = res.list
      },
      async listVest(params) {
        const res = await listUserVestPage(params)
        if (res.success) this.vestList = res.list
      },
      // 换一批
      changeBatch() {
        this.query.page++
        const params = JSON.parse(JSON.stringify(this.query))
        this.listUserVestPage(params)
      },
      // 确定
      async saveComment() {
        const comment = this.allVestList.filter((item) => item.content)
        if (comment.length === 0)
          return this.$message({
            type: 'error',
            message: '当前没有用户进行评论，请输入！',
          })
        const params = {
          id: this.val.id,
          themetype: this.themetype,
          comment_task_arr: JSON.stringify(
            comment.map((item) => {
              return {
                vest_user_id: item.user_id,
                content: item.content,
                content_id: this.val.id,
              }
            })
          ),
        }
        if (this.isRecoveryRate) {
          params.public_minute = this.recoveryRate ? this.public_minute : 0
        }
        const res = await quickCommentTheme(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `评论成功` : `评论失败`,
        })
        if (res.success) this.$emit('getTableData')
        this.dialogVisible = false
        this.recoveryRate = false
        this.public_minute = 0
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin: 0 3.5%;
    border-bottom: 1px solid #e7e7e7;
    ::v-deep .el-input__inner {
      border: none;
    }
    .head:hover::before {
      content: '更换';
      display: block;
      font-size: 12px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      color: #fff;
      background-color: #00000060;
    }
  }
  .el-dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
    .el-dropdown-menu__item {
      span {
        vertical-align: middle;
      }
      .head {
        display: inline-block;
        margin-right: 10px;
      }
      i {
        font-style: normal;
        color: $base-color-text-secondary;
      }
      .level {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 16px;
        height: 17px;
        border-radius: 4px;
      }
    }
  }
  .head {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    text-align: center;
    line-height: 40px;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-color: #00000060;
  }
  .username {
    width: 120px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .title {
    font-size: 16px;
    color: $base-color-text-primary !important;
  }
  .second-title {
    font-size: 14px;
    color: $base-color-text-primary !important;
  }
  .inputname {
    padding: 5px 15px;
  }
  .recommended {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 8px;
    margin-top: 4px;
    border-top: 1px solid #ebeef5;
  }
  .recoveryRate {
    display: flex;
    align-items: center;
    margin: 0 3%;
    height: 32px;
    .el-input {
      width: 200px;
      margin: 0 5px;
    }
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .tip {
    margin: 5px 3%;
    color: $base-color-text-secondary;
  }
</style>
