<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle(title)"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form ref="aduitForm" :column="column" :query="query" ref-name="aduit" />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveAduit">
        {{ translateTitle('确定') }}
      </el-button>
      <el-button type="danger" @click="cancel">
        {{ translateTitle('取消') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Form from '@/components/Form'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
    },
    props: {
      title: {
        type: String,
        default: '审核不通过',
      },
    },
    data() {
      return {
        dialogVisible: false,
        val: {},
        query: {
          reason: null,
          remake: null,
          is_push: -1,
        },
        isPushList: [
          {
            id: 1,
            label: '是',
          },
          {
            id: -1,
            label: '否',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            label: '不通过原因',
            prop: 'reason',
            type: 'select',
            optionLabel: 'name',
            optionValue: 'name',
            list: this.config.show_not_through_reason,
          },
          {
            label: '消息通知',
            prop: 'is_push',
            type: 'radio',
            list: this.isPushList,
          },
          {
            label: '消息备注',
            prop: 'remark',
            type: 'textarea',
            tips: '若开启了消息通知，填写的备注内容将会替换上方选择的不通过原因，发送给用户',
          },
        ]
      },
    },
    methods: {
      translateTitle,
      handleOpen(val) {
        this.val = val
        this.dialogVisible = true
      },
      // 确定
      saveAduit() {
        const params = {
          is_push: this.query.is_push,
          remark:
            this.query.is_push == 1 ? this.query.remark : this.query.reason,
        }
        this.$emit('handleAuditNotPass', this.val, params)
        this.cancel()
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.aduitForm.resetFields()
      },
    },
  }
</script>
