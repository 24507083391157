import request from '@/utils/request'
// 获取所属专辑
export function allAlbumPage(params) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/allAlbumPage',
    method: 'get',
    params,
  })
}
// 获取所属栏目
export function contentColumnPage(params) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/contentColumnPage',
    method: 'get',
    params,
  })
}

// 获取视频列表
export function getVideoPage(params) {
  return request({
    url: '/metatime/livevideo/v1/VideoAdmin/videoPage',
    method: 'get',
    params,
  })
}

// 推荐
export function addContentRecommend(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/addContentRecommend',
    method: 'post',
    data,
  })
}

// 取消推荐
export function removeContentRecommend(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/removeContentRecommend',
    method: 'post',
    data,
  })
}

// 撤回
export function toWithdraw(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/toWithdraw',
    method: 'post',
    data,
  })
}

// 恢复
export function toCancelWithdraw(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/toCancelWithdraw',
    method: 'post',
    data,
  })
}

// 获取视频详情
export function videoLoad(params) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/videoLoad',
    method: 'get',
    params,
  })
}

// 视频保存
export function videoEdit(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/videoEdit',
    method: 'post',
    data,
  })
}

// 视频删除
export function toBatchDelete(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/toBatchDelete',
    method: 'post',
    data,
  })
}

// 视频发布
export function videoAdd(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/videoAdd',
    method: 'post',
    data,
  })
}

// 草稿视频
export function checkSensitiveContent(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/checkSensitiveContent',
    method: 'post',
    data,
  })
}

// 移入栏目
export function toBatchUpdateColumnRel(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/toBatchUpdateColumnRel',
    method: 'post',
    data,
  })
}

// 移入专辑
export function toBatchUpdateAlbum(data) {
  return request({
    url: '/metatime/livevideo/v1/videoAdmin/toBatchUpdateAlbum',
    method: 'post',
    data,
  })
}
