var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        title: _vm.translateTitle(_vm.title),
        visible: _vm.dialogVisible,
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        _vm._l(_vm.vestList, function (item) {
          return _c(
            "el-col",
            {
              key: item.user_id,
              attrs: { span: 7 },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSelectUser(item)
                },
              },
            },
            [
              _c("span", {
                staticClass: "head",
                style: { backgroundImage: "url(" + item.head + ")" },
              }),
              _c("div", { staticClass: "info-main" }, [
                _c("div", { staticClass: "info" }, [
                  _c("span", { staticClass: "username" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("img", {
                    staticClass: "level",
                    attrs: { alt: "", src: item.sex != 2 ? _vm.boy : _vm.girl },
                  }),
                ]),
                item.wallet_id
                  ? _c("span", { staticClass: "wallet-id" }, [
                      _vm._v(" wallet_id: " + _vm._s(item.wallet_id) + " "),
                    ])
                  : _vm._e(),
              ]),
              _vm.title === "元宇宙用户选择器"
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm.translateTitle("发布")) +
                          "：" +
                          _vm._s(item.post)
                      ),
                    ]),
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm.translateTitle("评论")) +
                          "：" +
                          _vm._s(item.post_comment)
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.pageInfo.page,
          layout: "total, prev, pager, next, jumper",
          "page-count": _vm.pageInfo.page_count,
          "page-size": _vm.pageInfo.step,
          total: _vm.pageInfo.count,
        },
        on: { "current-change": _vm.handleCurrentChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }