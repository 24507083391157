var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vab-nav" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c("el-col", { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 4 } }, [
            _c(
              "div",
              { staticClass: "left-panel" },
              [
                _vm.layout !== "float" ? _c("vab-fold") : _vm._e(),
                _vm.layout === "comprehensive"
                  ? _c(
                      "el-tabs",
                      {
                        attrs: { "tab-position": "top" },
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.extra.first,
                          callback: function ($$v) {
                            _vm.$set(_vm.extra, "first", $$v)
                          },
                          expression: "extra.first",
                        },
                      },
                      [
                        _vm._l(_vm.handleRoutes, function (route, index) {
                          return [
                            _c(
                              "el-tab-pane",
                              {
                                key: index + route.name,
                                attrs: { name: route.name },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    route.meta.icon
                                      ? _c("vab-icon", {
                                          staticStyle: { "min-width": "16px" },
                                          attrs: {
                                            icon: route.meta.icon,
                                            "is-custom-svg":
                                              route.meta.isCustomSvg,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.translateTitle(route.meta.title)
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("vab-breadcrumb", { staticClass: "hidden-xs-only" }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 20 } }, [
            _c(
              "div",
              { staticClass: "right-panel" },
              [
                _c("vab-error-log"),
                _c("vab-search"),
                _c("vab-notice"),
                _c("vab-full-screen"),
                _c("vab-language"),
                _c("vab-theme"),
                _c("vab-refresh"),
                _c("vab-avatar"),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }