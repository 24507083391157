<template>
  <div class="add-column-container">
    <div class="add-column-tips m-b">配置内容的来源和规则。</div>
    <Form
      :column="column"
      :query="query"
      ref-name="basic"
      @handleImageUrl="handleImageUrl"
    />
    <el-divider />
    <div class="add-column-btn">
      <el-button
        :loading="loading"
        size="medium"
        type="primary"
        @click="onSubmit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Form from '@/components/Form'
  import {
    getThemeInfo,
    checkSensitiveContent,
    themeInfoSave,
  } from '@/api/information'
  import config from './config'
  export default {
    name: 'OperationEdit',
    components: {
      Form,
    },
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            label: '标题',
            type: 'textarea',
            prop: 'title',
            tips: '建议标题字数在25字以内',
          },
          {
            label: '列表图片',
            prop: 'pic_type',
            type: 'radio',
            list: this.listPicTypes,
          },
          {
            label: '',
            prop: 'pics',
            type: 'uploadImage',
            width: 178,
            height: 134,
            isHide: this.query.pic_type === 4,
            eventName: 'handleImageUrl',
            tips: '图片建议尺寸：178x134px',
          },
          {
            label: '标签',
            prop: 'label',
            type: 'radio',
            list: this.labelList,
          },
          {
            label: '显示来源',
            prop: 'from',
            tips: '来源将在列表页显示',
          },
        ]
      },
    },
    created() {
      this.getLabelList()
      this.getThemeInfo()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 获取标签列表
      getLabelList() {
        if (this.labelList.length === 0) {
          this.labelList.push({ id: '', label: '无' })
          for (let key of Object.keys(this.config.icons.labels)) {
            const item = { id: key, label: this.config.icons.labels[key] }
            this.labelList.push(item)
          }
        }
      },
      getThemeInfo() {
        const { cat_id, tid, type } = this.$route.query
        this.query.cat_id = Number(cat_id)
        this.query.tid = Number(tid)
        this.query.type = Number(type)
        const params = { cat_id, tid, type }
        getThemeInfo(params).then((res) => {
          for (let key of Object.keys(res.data)) {
            for (let key1 of Object.keys(this.query)) {
              if (key1 === key) {
                this.query[key1] = res.data[key]
              }
            }
          }
        })
      },
      handleImageUrl(val, data) {
        this.query[val] = data
      },
      async onSubmit() {
        const params1 = {
          title: this.query.title,
          source: this.query.from,
        }
        await checkSensitiveContent(params1)
        const {
          id,
          title,
          type,
          cat_id,
          from,
          tid,
          pic_type,
          pics,
          label,
          user_id,
        } = this.query
        const params2 = {
          id,
          title,
          type,
          cat_id,
          from,
          pic_type,
          label,
          user_id,
          pics: pic_type == 4 ? '' : pics,
          type_value: tid,
        }
        this.loading = true
        const res = await themeInfoSave(params2)
        this.loading = false
        if (res.success) {
          await this.delVisitedRoute('/operation/edit')
          this.$router.go(-1)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-column';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
    .m-b {
      margin-bottom: 20px;
    }
    ::v-deep.el-radio__label {
      line-height: 32px;
    }
  }
</style>
