import Layout from '@/vab/layouts'

const push = {
  path: '/push',
  name: 'Push',
  component: Layout,
  meta: {
    title: '推送',
    icon: 'logout-box-r-line',
  },
  children: [
    {
      path: 'massPush',
      name: 'MassPush',
      component: () => import('@/views/operate/messagePush/massPush'),
      meta: {
        title: '推送中心',
      },
    },
  ],
}

export default push
