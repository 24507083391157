var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-video-container" },
    [
      _c("Form", {
        attrs: { column: _vm.column, "label-width": 130, query: _vm.params },
        on: {
          createRandom: _vm.createRandom,
          handleImageUrl: _vm.handleImageUrl,
          handleVideoUrl: _vm.handleVideoUrl,
        },
        scopedSlots: _vm._u([
          {
            key: "videoTime",
            fn: function () {
              return [
                _c(
                  "el-row",
                  { attrs: { type: "flex" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入时" },
                          model: {
                            value: _vm.params.duration_h,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "duration_h", $$v)
                            },
                            expression: "params.duration_h",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { align: "center", span: 1 } }, [
                      _vm._v("h"),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入分" },
                          model: {
                            value: _vm.params.duration_i,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "duration_i", $$v)
                            },
                            expression: "params.duration_i",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { align: "center", span: 1 } }, [
                      _vm._v("m"),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入秒" },
                          model: {
                            value: _vm.params.duration_s,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "duration_s", $$v)
                            },
                            expression: "params.duration_s",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { align: "center", span: 1 } }, [
                      _vm._v("s"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "add-video-btn" },
        [
          _vm.$route.query.id
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRelease("save")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _vm.isBtn
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRelease("now")
                      },
                    },
                  },
                  [_vm._v(" 立即发布 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRelease("null")
                      },
                    },
                  },
                  [_vm._v(" 存为草稿 ")]
                ),
              ]
            : _vm._e(),
          !_vm.$route.query.id
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-pie-chart",
                    size: "medium",
                    type: "primary",
                  },
                  on: { click: _vm.hanldeTiming },
                },
                [_vm._v(" 定时发布 ")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("ReleaseDialog", {
        ref: "releaseDialog",
        on: { handleRelease: _vm.handleRelease },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }