<script>
  export default {
    data() {
      return {
        pageInfo: {
          page: 1,
          step: 20,
          count: 0,
          page_count: 0,
        },
        loading: false, // 列表动画开关
        list: [], // 列表数据
        selectionList: [], // 选中列表
      }
    },
    methods: {
      // 重置事件
      resetForm() {
        this.pageInfo.page = 1
        this.getTableData()
      },
      // 分页
      handleCurrentChange(value) {
        this.pageInfo.page = value
        this.getTableData()
      },
      // 选中列表
      handleSelectionChange(row) {
        this.selectionList = row
      },
    },
  }
</script>
