import Layout from '@/vab/layouts'

const video = {
  path: '/video',
  name: 'Video',
  component: Layout,
  meta: {
    title: '视频',
    icon: 'video-chat-fill',
  },
  children: [
    {
      path: 'contentManage',
      name: 'ContentManage',
      component: () => import('@/views/videoManage/content'),
      meta: {
        title: '内容管理',
        // icon: 'computer-fill',
      },
    },
    {
      path: 'contentManage/add',
      name: 'AddVideo',
      component: () => import('@/views/videoManage/content/add'),
      meta: {
        hidden: true,
        title: '发布视频',
      },
    },
    {
      path: 'contentManage/edit',
      name: 'EditVideo',
      component: () => import('@/views/videoManage/content/edit'),
      meta: {
        hidden: true,
        title: '编辑视频',
      },
    },
  ],
}

export default video
