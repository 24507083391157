var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-community-container" },
    [
      _c("SearchForm", {
        attrs: {
          "btn-col": 0,
          "label-width": 0,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: {
          getTableData: _vm.getTableData,
          handleChangeStatus: _vm.handleChangeStatus,
          resetForm: _vm.resetForm,
        },
      }),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          handleCurrentChange: _vm.handleCurrentChange,
          handleDelete: _vm.handleDelete,
          handleEdit: _vm.handleEdit,
          handlePreview: _vm.handlePreview,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  {
                    staticClass: "content-info",
                    style: {
                      display:
                        row.pic_type != 4 && row.pics_arr.length > 1
                          ? "block"
                          : "flex",
                    },
                  },
                  [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "span",
                          {
                            staticClass: "content-info-title",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.jumpDetail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.title) + " ")]
                        ),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "content-info-from" }, [
                          _vm._v(_vm._s(row.from)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "text-secondary",
                            staticStyle: { "margin-left": "20px" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(row.publish_time, true)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "text-secondary" }, [
                          _vm._v(_vm._s(row.click) + "阅读"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "text-secondary",
                            staticStyle: { "margin-left": "20px" },
                          },
                          [_vm._v(" id: " + _vm._s(row.id) + " ")]
                        ),
                      ]),
                    ]),
                    row.pic_type != 4 && row.pics_arr.length
                      ? _vm._l(row.pics_arr, function (item, index) {
                          return _c("el-image", {
                            key: index,
                            attrs: {
                              "preview-src-list": [
                                item.tburl.slice(0, 1) === "/"
                                  ? _vm.baseURL + item.tburl
                                  : item.tburl,
                              ],
                              src:
                                item.tburl.slice(0, 1) === "/"
                                  ? _vm.baseURL + item.tburl
                                  : item.tburl,
                            },
                          })
                        })
                      : _vm._e(),
                    row.pic_type != 4 && row.pics_arr.length
                      ? _c("el-image", {
                          attrs: {
                            "preview-src-list": [row.pics_arr[0].tburl],
                            src: row.pics_arr[0].tburl,
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "other",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v("所在版块")]),
                _c(
                  "el-tag",
                  {
                    staticClass: "content-info-other",
                    attrs: { type: "info" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(row.circle.name ? row.circle.name : "后台发布") +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("PreviewDialog", { ref: "previewDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }