var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        "before-close": _vm.handleClose,
        "lock-scroll": false,
        title: "跳转选择器",
        visible: _vm.dialogVisible,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "tac", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    id: "menu",
                    "default-active": _vm.defaultActive,
                    "default-openeds": _vm.defaultOpeneds,
                  },
                  on: { select: _vm.handleSelectMenu },
                },
                [
                  _vm._l(_vm.navList, function (item, index) {
                    return [
                      item.children
                        ? _c(
                            "el-submenu",
                            { key: index, attrs: { index: index.toString() } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                              ]),
                              _vm._l(item.subList, function (el, idx) {
                                return _c(
                                  "el-menu-item-group",
                                  { key: idx },
                                  [
                                    _c(
                                      "el-menu-item",
                                      { attrs: { index: el.view } },
                                      [_vm._v(" " + _vm._s(el.name) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _c(
                            "el-menu-item",
                            { key: index, attrs: { index: item.view } },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _vm.defaultActive == "component-tools"
                ? [
                    _c(
                      "div",
                      { staticClass: "tools-search" },
                      [
                        _c("SearchForm", {
                          attrs: {
                            "btn-col": 8,
                            "column-span": 16,
                            "label-width": 70,
                            query: _vm.query,
                            "search-column": _vm.searchColumn1,
                          },
                          on: {
                            getTableData: _vm.getTableData,
                            resetForm: _vm.resetForm,
                          },
                        }),
                        _c("div", { staticClass: "tools-more" }, [
                          _c("span", [_vm._v("更多跳转链接，")]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "http://doc.magcloud.net/318341?fcxqtw=jgfio2",
                                target: "_blank",
                              },
                            },
                            [_vm._v(" 点此查看 ")]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "tools-item",
                      },
                      _vm._l(_vm.list, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("p", { staticClass: "tools-title" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "tools-list" },
                            _vm._l(item.list, function (el, idx) {
                              return _c(
                                "li",
                                { key: idx },
                                [
                                  _c("span", [_vm._v(_vm._s(el.name))]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewUse(el)
                                        },
                                      },
                                    },
                                    [_vm._v(" 预览使用 ")]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                : _vm.defaultActive == "component-redefine"
                ? [
                    _c("el-input", {
                      staticClass: "m-b-20",
                      attrs: {
                        placeholder: "请输入地址",
                        resize: "none",
                        rows: 2,
                        type: "textarea",
                      },
                      model: {
                        value: _vm.link,
                        callback: function ($$v) {
                          _vm.link = $$v
                        },
                        expression: "link",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.returnAddress(_vm.link)
                          },
                        },
                      },
                      [_vm._v(" 确定 ")]
                    ),
                  ]
                : [
                    _c("SearchForm", {
                      attrs: {
                        "btn-col": 8,
                        "column-span": 10,
                        "label-width": 70,
                        query: _vm.query,
                        "search-column": _vm.searchColumn,
                      },
                      on: {
                        getTableData: _vm.getTableData,
                        resetForm: _vm.resetForm,
                      },
                    }),
                    _c("TablePaging", {
                      attrs: {
                        "column-list": _vm.columnList,
                        loading: _vm.loading,
                        "max-height": _vm.maxHeight,
                        "page-info": _vm.pageInfo,
                        "table-data": _vm.list,
                      },
                      on: {
                        handleCurrentChange: _vm.handleCurrentChange,
                        previewUse: _vm.previewUse,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.defaultActive == "component-user"
                                ? _c("el-image", {
                                    staticClass: "info-head",
                                    attrs: { src: row.head },
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "info" }, [
                                _c("p", { staticClass: "info-title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.title || row.content || row.name
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "info-item" }, [
                                  _c("span", { staticClass: "info-id" }, [
                                    _vm._v(
                                      " ID: " +
                                        _vm._s(
                                          row.tid || row.id || row.user_id
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "info-author" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.user_name || row.from) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "info-origin" }, [
                                    _vm._v(
                                      " 「" +
                                        _vm._s(
                                          row.forum_name ||
                                            row.circle_name ||
                                            row.album_name ||
                                            row.from ||
                                            row.type_name ||
                                            row.remark ||
                                            row.desc
                                        ) +
                                        "」 "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "info-time" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.lastpost ||
                                            row.create_time_str ||
                                            row.timing
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c("PreviewDialog", {
        ref: "previewDialog",
        on: { returnAddress: _vm.returnAddress },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }