var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: _vm.translateTitle("新增推送"), name: "1" } },
        [
          _vm.activeName == 1
            ? _c("CreatePush", { on: { handleTabActive: _vm.handleTabActive } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: _vm.translateTitle("推送列表"), name: "2" } },
        [_vm.activeName == 2 ? _c("PushList") : _vm._e()],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: _vm.translateTitle("错误日志"), name: "3" } },
        [_vm.activeName == 3 ? _c("PushErrorList") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }