<template>
  <div class="add-show-column-container">
    <div class="add-show-column-tips">配置内容的来源和规则。</div>
    <div class="add-show-column-title">基础配置</div>
    <Form :column="column1" :query="query1" ref-name="basic" />
    <el-divider />
    <div class="add-show-column-title">内容对接</div>
    <Form :column="column2" :query="query2" ref-name="basic" />
    <el-divider />
    <div class="add-show-column-btn">
      <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      <el-button size="medium">取消</el-button>
      <el-button
        v-if="$route.query.id"
        size="medium"
        type="danger"
        @click="onDelete"
      >
        删除
      </el-button>
    </div>

    <el-dialog title="信息" :visible.sync="dialogVisible" width="20%">
      <span>是否确认删除该信息流？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Form from '@/components/Form'
  import {
    getShowList,
    getAllTopicPageList,
    catSave,
    catDelete,
  } from '@/api/information'
  import config from './config'
  export default {
    components: {
      Form,
    },
    props: {
      query1: {
        type: Object,
        default: () => ({}),
      },
      query2: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return config
    },
    computed: {
      column1() {
        return [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '信息流分组',
            prop: 'type',
            type: 'select',
            list: [
              {
                id: 6,
                label: '请选择',
              },
            ],
          },
          {
            label: '列表样式',
            prop: 'show_style',
            type: 'radio',
            list: this.listStyleList,
            tips: '保存配置后，重新加载内容列表生效',
          },
          {
            label: '样式选择',
            prop: 'pintu_type',
            type: 'radio',
            list: this.styleSelectionList,
            isHide: this.query1.show_style != 'pintu',
          },
          {
            label: '评论样式',
            prop: 'comment_type',
            type: 'radio',
            list: this.commentStyleList,
            tips: '当选择“神评优先”样式后，内容列表没有神评时显示最新评论；“仅显示神评”可以提升页面紧凑度',
          },
          {
            label: '排序方式',
            prop: 'sort_type',
            type: 'radio',
            list: this.sortList,
            tips: this.query1.sort_type == 2 ? '请修改内容范围' : '',
          },
          {
            label: '内容范围',
            prop: 'sort_max_size',
            type: 'radio',
            list: this.contentScopeList,
            tips: '客户端下拉刷新随机显示内容范围。',
            isHide: this.query1.sort_type != 2,
          },
          {
            label: '时间范围',
            prop: 'sort_time_range',
            type: 'radio',
            list: this.timeScopeList,
            tips: '随机内容的时间范围，一天指24小时，自定义天数请填写阿拉伯数字，超过该时间区间的内容显示在随机内容底部。',
            isHide: this.query1.sort_type != 2,
          },
          {
            prop: 'self_sort_time_range',
            isHide: this.query1.sort_time_range != 0,
            tips: '自定义天数',
          },
          {
            label: '首次进入是否随机',
            prop: 'is_first_rand_default',
            type: 'radio',
            list: this.whetherList2,
            tips: '选择是，则用户第一次进入时也为随机排序；选择否，用户首次进入为默认排序，下拉刷新后才变为随机排序。',
            isHide: this.query1.sort_type != 2,
          },
          {
            label: '保存内容条数',
            prop: 'info_num_limit',
            type: 'radio',
            list: this.saveContentList,
            tips: '设置该信息流在后台能够保存的条数，超过设置的数值，则自动消失。',
          },
        ]
      },
      column2() {
        return [
          {
            label: '内容来源',
            prop: 'cat_from_type',
            type: 'radio',
            eventName: 'handleContentSource',
            list: this.contentSourceList,
            tips: '设置该信息流在后台能够保存的条数，超过设置的数值，则自动消失。',
          },
          {
            label: this.query2.cat_from_type == 1 ? '来源圈子' : '来源话题',
            prop: this.query2.cat_from_type == 1 ? 'show_from' : 'topic_from',
            type: 'select',
            multiple: true,
            optionLabel: 'name',
            list:
              this.query2.cat_from_type == 1
                ? this.showList
                : this.allTopicPageList,
            tips:
              this.query2.cat_from_type == 1
                ? '选择内容来源版块，可多选'
                : '话题可多选',
          },
          {
            label: '发布时间',
            prop: 'show_publish_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '互动时间',
            prop: 'show_interact_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '阅读数大于',
            prop: 'show_read_count',
          },
          {
            label: '评论数大于',
            prop: 'show_comment_count',
          },
          {
            label: '只取精华',
            prop: 'show_is_elite',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '只取图片',
            prop: 'show_is_pic',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '只取视频',
            prop: 'show_is_video',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '缓冲时间',
            prop: 'show_buffer_time',
            tips: '单位（小时），缓冲时间内内容不会被推荐到首页<br/>首次创建会同步200条数据，后期符合条件的内容用户阅读即可触发',
          },
        ]
      },
    },
    created() {
      this.getShowList()
      this.getAllTopicPageList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      onSubmit() {
        const { show_style, pintu_type, comment_type, is_forum } = this.query1
        let params = {
          ...this.query1,
          _config: {
            is_forum,
            show_style,
            pintu_type,
            comment_type,
            ...this.query2,
          },
        }
        params.self_sort_time_range = Number(params.self_sort_time_range)
        params.sort_time_range = Number(
          params.sort_time_range || params.self_sort_time_range
        )
        params._config.show_from = params._config.show_from.join(',')
        params._config.topic_from = params._config.topic_from.join(',')
        params._config = JSON.stringify(params._config)
        if (this.$route.query.id) params.id = this.$route.query.id
        catSave(params).then(async (res) => {
          if (res.success === true) {
            await this.delVisitedRoute('/info/addshowColumn')
            await this.delVisitedRoute('/info/addInformation')
            this.$router.push('/info/flowManage')
          } else this.$baseMessage(res.msg, 'error')
        })
      },
      async cancel() {
        await this.delVisitedRoute('/info/addshowColumn')
        this.$router.push('/info/addInformation')
      },
      // 获取来源圈子
      getShowList() {
        var params = { step: 20 }
        getShowList(params).then((res) => {
          this.showList = res.list
        })
      },
      // 获取来源话题
      getAllTopicPageList() {
        var params = { step: 20 }
        getAllTopicPageList(params).then((res) => {
          this.allTopicPageList = res.list
        })
      },
      // 点击删除
      onDelete() {
        this.dialogVisible = true
      },
      onSure() {
        const params = { id: this.$route.query.id }
        catDelete(params).then(async (res) => {
          if (res.success) {
            this.dialogVisible = false
            await this.delVisitedRoute('/info/showColumn')
            this.$router.push('/')
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-show-column';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 48px;
        height: 48px;
        margin-right: 50px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
