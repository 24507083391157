var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.cancel,
      },
    },
    [
      _c("SearchForm", {
        ref: "searchForm",
        attrs: {
          "column-span": 18,
          "label-position": "left",
          "label-width": 80,
          query: _vm.query,
          "reset-show": false,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getCatSelectData, resetForm: _vm.resetForm },
      }),
      _c("Form", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          column: _vm.column,
          "label-show": false,
          "label-width": 0,
          query: _vm.params,
          "ref-name": "origin",
        },
        on: { handleCheck: _vm.handleCheck },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.references } },
            [_vm._v("引用内容")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.move("copy")
                },
              },
            },
            [_vm._v("复制移动")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.move("shear")
                },
              },
            },
            [_vm._v("剪切移动")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }