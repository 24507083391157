const config = {
  time: new Date().getTime(),
  dialogVisible: false,
  dialogVisible1: false,
  fixedPositionList: [],
  isShowPosition: false,
  labelList: [],
  userPageList: [],
  userInfo: {},
  timingQuery: {
    publish_time: null,
  },
  timingRules: {
    publish_time: [
      {
        type: 'date',
        required: true,
        message: '请选择定时发布日期',
        trigger: ['change', 'blur'],
      },
    ],
  },
  timingColumn: [
    {
      label: '定时发布时间',
      prop: 'publish_time',
      type: 'datetime',
    },
  ],
  typeList: Object.freeze([
    {
      id: 1,
      label: '后台发布',
    },
    // {
    //   id: 3,
    //   label: '轻分享',
    // },
    // {
    //   id: 2,
    //   label: '论坛主题',
    // },
    // {
    //   id: 4,
    //   label: '链接',
    // },
    {
      id: 5,
      label: '微信文章URL',
    },
    // {
    //   id: 9,
    //   label: '视频',
    // },
    // {
    //   id: 11,
    //   label: '引用资讯',
    // },
    // {
    //   id: 12,
    //   label: '图集',
    // },
  ]),
  fixedList: Object.freeze([
    {
      id: -1,
      label: '不固定',
    },
    {
      id: 1,
      label: '固定',
    },
  ]),
  openRedPacketList: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  listPicTypes: Object.freeze([
    {
      id: 1,
      label: '单图',
    },
    // {
    //   id: 2,
    //   label: '三图',
    // },
    // {
    //   id: 3,
    //   label: '宽图',
    // },
    {
      id: 4,
      label: '无图',
    },
  ]),
}

export default config
