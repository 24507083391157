import { render, staticRenderFns } from "./previewDialog.vue?vue&type=template&id=34734859&scoped=true&"
import script from "./previewDialog.vue?vue&type=script&lang=js&"
export * from "./previewDialog.vue?vue&type=script&lang=js&"
import style0 from "./previewDialog.vue?vue&type=style&index=0&id=34734859&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34734859",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paul/Documents/project/beimei_app/metatopnews/metatopnews-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34734859')) {
      api.createRecord('34734859', component.options)
    } else {
      api.reload('34734859', component.options)
    }
    module.hot.accept("./previewDialog.vue?vue&type=template&id=34734859&scoped=true&", function () {
      api.rerender('34734859', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/JumpLink/previewDialog.vue"
export default component.exports