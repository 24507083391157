<template>
  <el-upload
    :ref="`upload-${prop}`"
    v-loading="loading"
    :accept="imageFiles"
    :action="uploadApi"
    :before-upload="beforeImageUpload"
    class="image-uploader"
    :data="uploadFormData"
    element-loading-spinner="el-icon-loading"
    element-loading-text="上传中"
    :limit="limit"
    :on-error="handleImageError"
    :on-success="handleImageSuccess"
    :show-file-list="false"
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <div v-if="imageUrl" class="image-url">
      <img :src="imageLink" />
      <i class="el-icon-remove" @click.stop="handleRemoveImage"></i>
    </div>
    <i v-else class="el-icon-picture image-uploader-icon"></i>
  </el-upload>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { baseURL, uploadApi, imageAllowFiles, imageMaxSize } from '@/config'
  import { uploadQiNiuPic, getQNToken } from '@/api/user'
  import { timeFormat } from '@/filters'
  export default {
    props: {
      imageUrl: {
        type: [Number, String],
        default: null,
      },
      prop: {
        type: String,
        default: null,
      },
      limit: {
        type: [Number, String],
        default: 1,
      },
      width: {
        type: [Number, String],
        default: 360,
      },
      height: {
        type: [Number, String],
        default: 150,
      },
      eventName: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        loading: false,
        uploadApi: uploadApi,
        imageFiles: imageAllowFiles,
        uploadLink: `${baseURL}/core/attachment/attachment/attach?aid=`,
      }
    },
    computed: {
      ...mapGetters({
        qiniuToken: 'user/qiniuToken',
      }),
      uploadFormData() {
        return {
          token: null,
          key: null,
        }
      },
      imageLink() {
        return String(this.imageUrl).includes('http')
          ? this.imageUrl
          : `${this.uploadLink}${this.imageUrl}&width=${this.width}&height=${this.height}`
      },
    },
    methods: {
      // 上传成功
      handleImageSuccess(res) {
        // console.log(res, file)
        if (res.code === 100) {
          this.uploadQiNiuPic(res.data)
        } else {
          this.loading = false
          this.$message.error('上传失败，请重新上传。code=' + res.code)
        }
        this.$refs[`upload-${this.prop}`].clearFiles()
      },
      handleImageError(err) {
        this.loading = false
        this.$message.error('上传失败，请重新上传。err=' + JSON.stringify(err))
      },
      // 上传之前
      async beforeImageUpload(file) {
        this.loading = true
        const { data } = await getQNToken()
        this.uploadFormData.token = data
        this.uploadFormData.key = this.combinedPath(file.name)
        const isLt2M = file.size / 1024 / 1024 < imageMaxSize
        if (!isLt2M) {
          this.$message.error(`上传图片大小不能超过 ${imageMaxSize}MB!`)
        }
        return isLt2M
      },
      // 获取当前时间戳组成路径key
      combinedPath(name) {
        name = name.split('.').pop().toLowerCase()
        let time = timeFormat(new Date(), true, '')
        return `pic/${time}/qi_niu_${new Date().getTime()}_${this.random()}_${this.random()}_${this.random()}.${name}`
      },
      // 随机数
      random() {
        let val = Math.random() * 1000
        val = Math.round(val)
        return val
      },
      // 获取上传图片id
      async uploadQiNiuPic(res) {
        const params = {
          width: res.w,
          height: res.h,
          key: res.key,
        }
        const { data } = await uploadQiNiuPic(params)
        this.loading = false
        this.$emit(
          'handleImageUrl',
          this.eventName,
          this.prop,
          data
          // `${this.uploadLink}${data}&width=${this.width}&height=${this.height}`
        )
      },
      // 删除视频
      handleRemoveImage() {
        this.$emit('handleImageUrl', this.eventName, this.prop, null)
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-upload,
  .image-url {
    width: 100%;
    height: 100%;
    position: relative;
    .el-icon-remove {
      position: absolute;
      right: -12px;
      top: -12px;
      font-size: 24px;
      color: $base-color-red;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep .el-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e6e6;
    .image-uploader-icon {
      font-size: 26px;
    }
  }
</style>
