<template>
  <div class="manage-show-container">
    <SearchForm
      :btn-col="0"
      :label-width="0"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @handleChangeStatus="handleChangeStatus"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 其他信息 -->
      <template #other="{ row }">
        <span>所在版块</span>
        <span>{{ row.circle.name ? row.circle.name : '后台发布' }}</span>
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import {
    getContentStatusOptions,
    getCommunityShowPage,
  } from '@/api/information'
  export default {
    components: {
      SearchForm,
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        query: {
          type: 0,
          status: null,
          keywords: null,
          cat_id: this.$route.query.id,
        },
        // 表格最大高度
        maxHeight: document.body.clientHeight - 340,
        config: [],
        columnList: [
          { label: '内容', prop: 'user_name' },
          { label: '其他信息', slotName: 'other' },
          // {
          //   label: '操作',
          //   isButton: true,
          //   width: 350,
          //   btnList: [
          //     { label: '编辑', type: 'primary' },
          //     {
          //       label: '屏蔽',
          //       type: 'primary',
          //       showName: 'delete_time',
          //       showStatusList: [false],
          //     },
          //     {
          //       label: '取消屏蔽',
          //       type: 'primary',
          //       showName: 'delete_time',
          //       showStatusList: [true],
          //     },
          //     {
          //       label: '删除',
          //       type: 'danger',
          //       showName: 'type',
          //       showStatusList: [1, 2, 3, 9, 11],
          //     },
          //   ],
          // },
        ],
      }
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '',
            prop: 'status',
            type: 'select',
            clearable: false,
            optionLabel: 'name',
            eventName: 'handleChangeStatus',
            selectList: this.config,
          },
        ]
      },
    },
    created() {
      this.getContentStatusOptions().then(() => {
        this.getTableData()
      })
    },
    methods: {
      // 获取状态列表
      async getContentStatusOptions() {
        const data = await getContentStatusOptions()
        if (data.success) {
          this.config = data.list
          this.query.status = data.list[0].id
        }
      },
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getCommunityShowPage(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = Number(res.total)
        })
      },
      handleChangeStatus() {
        this.pageInfo.page = 1
        this.getTableData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.manage-show';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
  }
</style>
