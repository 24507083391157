import request from '@/utils/request'
// 获取自定义广告列表
export function getCustoCnmpm(params) {
  return request({
    url: '/metatime/operative/v1/adminAd/getCustoCnmpm',
    method: 'get',
    params,
  })
}

// 获取广告列表
export function getAdPageList(params) {
  return request({
    url: '/metatime/operative/v1/adminAd/adPage',
    method: 'get',
    params,
  })
}

// 关闭
export function closeAd(data) {
  return request({
    url: '/metatime/operative/v1/adminAd/closeAd',
    method: 'post',
    data,
  })
}

// 开启
export function openAd(data) {
  return request({
    url: '/metatime/circle/v3/appletAdmin/openAd',
    method: 'post',
    data,
  })
}

// 删除
export function delAppletAd(data) {
  return request({
    url: '/metatime/operative/v1/adminAd/delAd',
    method: 'post',
    data,
  })
}

// 广告分类
export function getAllClassificationPage(params) {
  return request({
    url: '/metatime/operative/v1/adminAd/getAllClassificationPage',
    method: 'get',
    params,
  })
}

// 根据id获取广告
export function getloadAd(params) {
  return request({
    url: 'mag/operative/v1/adminAd/loadAd',
    method: 'get',
    params,
  })
}

// 保存广告
export function saveAd(data) {
  return request({
    url: 'mag/operative/v1/adminAd/saveAd',
    method: 'post',
    data,
  })
}

// 获取活动列表
export function getActivityList(params) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityPage',
    method: 'get',
    params,
  })
}

// 获取活动列表
export function activityUpdate(data) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityUpdate',
    method: 'post',
    data,
  })
}

// 活动推荐
export function toRecommendTopic(data) {
  return request({
    url: '/metatime/activity/v1/ActivityAdmin/toRecommendTopic',
    method: 'post',
    data,
  })
}

// 活动删除
export function activityDelete(data) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityDelete',
    method: 'post',
    data,
  })
}

// 获取活动详情
export function activityDetail(params) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityDetail',
    method: 'get',
    params,
  })
}

// 保存活动详情
export function activitySave(data) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activitySave',
    method: 'post',
    data,
  })
}

// 获取活动管理详情
export function getActivityManage(params) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityManageGet',
    method: 'get',
    params,
  })
}

// 保存活动管理详情
export function saveActivityManage(params) {
  return request({
    url: '/metatime/activity/v1/activityAdmin/activityManage',
    method: 'get',
    params,
  })
}

// 获取群聊列表
export function getGroupList(params) {
  return request({
    url: '/metatime/easemob/adminChatGroups/groupPage',
    method: 'get',
    params,
  })
}

// 获取社区助手列表
export function getAssistantList(params) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantList',
    method: 'get',
    params,
  })
}

// 新增助手
export function assistantSave(data) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantSave',
    method: 'post',
    data,
  })
}

// 助手状态更新
export function assistantStatusUpdate(data) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantStatusUpdate',
    method: 'post',
    data,
  })
}

// 助手删除
export function assistantDel(data) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantDel',
    method: 'post',
    data,
  })
}

// 获取社区助手详情
export function getAssistantDetail(params) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantGet',
    method: 'get',
    params,
  })
}

// 重置secrte
export function resetSecrte(data) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/resetSecrte',
    method: 'post',
    data,
  })
}

// 获取社区助手消息
export function getAssistantMsgList(params) {
  return request({
    url: '/metatime/operative/v1/adminAssistant/assistantMsgPage',
    method: 'get',
    params,
  })
}

// 获取推送列表
export function getPushList(params) {
  return request({
    url: '/metatime/push/v1/pushAdmin/pushPage',
    method: 'get',
    params,
  })
}

// 撤销推送
export function cancelPush(data) {
  return request({
    url: '/metatime/push/v1/pushAdmin/cancelPush',
    method: 'post',
    data,
  })
}

// 获取推送错误列表
export function getPushErrorList(params) {
  return request({
    url: '/metatime/push/v1/pushAdmin/pusherrorPage',
    method: 'get',
    params,
  })
}

// 新增推送
export function pushSave(data) {
  return request({
    url: '/metatime/push/v1/pushAdmin/pushSave',
    method: 'post',
    data,
  })
}

// 获取移动内容列表
export function catSelect(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catSelectV2Page',
    method: 'get',
    params,
  })
}

// 复制移动/剪切移动
export function moveInfoSave(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/moveInfoSave',
    method: 'post',
    data,
  })
}

// 引用内容
export function quoteInfoSave(data) {
  return request({
    url: '/metatime/info/v1/infoAdmin/quoteInfoSave',
    method: 'post',
    data,
  })
}

// 获取圈子
export function getCircleList(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/circleSelectPage',
    method: 'get',
    params,
  })
}
