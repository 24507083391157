<template>
  <div class="comment-container">
    <div class="comment-title">评论</div>
    <div class="comment-content">
      <div class="comment-user" @click="handleVestList">
        <img alt="" :src="userInfo.head" />
        <span>{{ userInfo.name }}</span>
      </div>
      <el-input
        v-model="commentContent"
        class="comment-input"
        placeholder="请输入评论内容"
      />
      <el-button type="primary" @click="handleComment">评 论</el-button>
      <el-button @click="handleQuickComments">快捷评论</el-button>
    </div>
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :is-pagination="true"
      :loading="loading"
      :page-info="pageInfo"
      :show-header="false"
      :table-data="list"
      @handleAdopt="handleAdopt"
      @handleCurrentChange="handleCurrentChange"
      @handleFail="handleFail"
      @handleReply="handleReply"
    >
      <!-- 用户信息 -->
      <template #userInfo="{ row }">
        <div class="comment-user-info">
          <img alt="" :src="row.user.head" />
          <div class="comment-username">
            <p>{{ row.user.name }}</p>
            <span>{{ row.pubdate_str }}</span>
          </div>
        </div>
      </template>
      <!-- 内容 -->
      <template #content="{ row }">
        <el-tag v-if="row.audit == 1">待审核</el-tag>
        <el-tag v-else-if="row.audit == 2" type="primary">已通过</el-tag>
        <el-tag v-else-if="row.audit == 3" type="danger">未通过</el-tag>
        <el-tag v-else-if="row.word == 2" type="danger">敏感词</el-tag>
        <span class="comment-value">{{ row.content }}</span>
      </template>
    </TablePaging>
    <!-- 选择用户--对话框 -->
    <VestList ref="vestList" @setUserInfo="setUserInfo" />
    <!-- 审批不通过 -->
    <AuditNotPassDialog
      ref="auditNotPass"
      @handleAuditNotPass="handleAuditNotPass"
    />
    <!-- 回复该层 -->
    <ReplyDialog ref="replyDialog" @getTableData="handleCommentPage" />
    <!-- 快捷评论 -->
    <QuickComments
      ref="quickComments"
      :is-recovery-rate="false"
      :show-number="4"
      themetype="3"
      @getTableData="handleCommentPage"
    />
  </div>
</template>
<script>
  import {
    listUserVestPage,
    infoComment,
    getCommentPage,
    updateAudit,
  } from '@/api/audit'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import VestList from '@/views/audit/components/vestList'
  import ReplyDialog from '@/views/audit/components/replyDialog'
  import AuditNotPassDialog from '@/components/AuditNotPassDialog'
  import QuickComments from '@/views/audit/components/quickComments'
  import { randomNum } from '@/utils'
  export default {
    components: {
      VestList,
      TablePaging,
      AuditNotPassDialog,
      ReplyDialog,
      QuickComments,
    },
    mixins: [tableMixins],
    props: {
      userPageList: {
        type: Array,
        default: () => [],
      },
      id: {
        type: [Number, String],
        default: 0,
      },
      themetype: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        userInfo: {},
        vestUserList: [],
        commentContent: '',
        // 表格配置
        columnList: Object.freeze([
          { label: '', slotName: 'userInfo' },
          { label: '', align: 'left', slotName: 'content', width: '600' },
          {
            label: '',
            isButton: true,
            width: 200,
            btnList: [
              {
                label: '回复该层',
                type: 'primary',
                eventName: 'handleReply',
              },
              {
                label: '通过',
                type: 'primary',
                showName: 'audit',
                showStatusList: [1, 3],
                eventName: 'handleAdopt',
              },
              {
                label: '不通过',
                type: 'danger',
                showName: 'audit',
                showStatusList: [1, 2],
                eventName: 'handleFail',
              },
            ],
          },
        ]),
      }
    },
    created() {
      this.listUserVestPage()
      this.handleCommentPage()
    },
    methods: {
      // 获取元宇宙用户
      async listUserVestPage() {
        const params = {
          page: 1,
          step: 20,
        }
        let res = await listUserVestPage(params)
        if (res.success) {
          this.userInfo = randomNum(res.list, 1)[0]
          this.vestUserList = res.list
        }
      },
      // 获取文章评论列表
      async handleCommentPage() {
        const params = {
          ctype: this.themetype,
          content_id: this.id,
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        this.loading = true
        const res = await getCommentPage(params)
        this.loading = false
        this.list = res.list || []
        this.pageInfo.count = res.total
      },
      // 获取元宇宙用户
      handleVestList() {
        this.$refs.vestList.handleOpen()
      },
      setUserInfo(val) {
        this.userInfo = val
      },
      // 评论
      async handleComment() {
        if (!this.commentContent) return this.$message.error('评论内容不能为空')
        const params = {
          content_id: this.id,
          content: this.commentContent,
          vest_user_id: this.userInfo.user_id,
        }
        const res = await infoComment(params)
        this.commentContent = ''
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `评论成功` : `评论失败`,
        })
        if (res.success) this.handleCommentPage()
      },
      // 快捷评论
      handleQuickComments() {
        const val = { id: this.id }
        this.$refs.quickComments.handleOpen(val)
      },
      // 通过
      handleAdopt(val) {
        const params = {
          content_id: val.content_id,
          comment_id: val.id,
          ctype: this.themetype,
          audit: 2,
          remark: '',
          is_push: false,
        }
        this.updateAudit(params)
      },
      // 不通过按钮
      handleFail(val) {
        this.$refs.auditNotPass.handleOpen(val)
      },
      // 不通过弹窗
      handleAuditNotPass(val, query) {
        const params = {
          content_id: val.content_id,
          comment_id: val.id,
          ctype: this.themetype,
          audit: 3,
          remark: query.remark,
          is_push: query.is_push,
        }
        this.updateAudit(params)
      },
      // 通过不通过状态更新
      async updateAudit(params) {
        const res = await updateAudit(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `更新状态成功` : `更新状态失败`,
        })
        if (res.success) {
          this.list = this.list.map((item) => {
            if (params.comment_id === item.id) {
              item.audit = item.audit == 3 ? 2 : 3
            }
            return item
          })
        }
      },
      // 回复该层
      handleReply(val) {
        this.$refs.replyDialog.handleOpen(val, this.vestUserList, 'info')
      },
      // 分页
      handleCurrentChange(value) {
        this.pageInfo.page = value
        this.handleCommentPage()
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.comment';
  #{$base}-container {
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-content {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    #{$base}-user {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        flex-shrink: 0;
      }
      span {
        margin: 0 10px;
        flex-shrink: 0;
      }
    }
    #{$base}-input {
      margin-right: 10px;
    }
    #{$base}-user-info {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    #{$base}-username {
      text-align: left;
      p {
        margin-top: 0;
        margin-bottom: 3px;
        line-height: 1;
        white-space: nowrap;
      }
      span {
        font-size: 12px;
        color: $base-color-text-secondary;
      }
    }
    #{$base}-value {
      margin-left: 10px;
    }
  }
</style>
