const config = {
  query: {
    group_id: null,
    keywords: null,
  },
  // 表格最大高度
  maxHeight: document.body.clientHeight - 340,
  // 表格配置
  columnList: Object.freeze([
    { label: '信息流名称', prop: 'name' },
    { label: '信息流分组', prop: 'group_name' },
    { label: '信息流类型', prop: 'type', slotName: 'type' },
    { label: '内容数量', prop: 'contentNum' },
    { label: '排序', prop: 'sort_time', slotName: 'sortTime' },
    {
      label: '操作',
      isButton: true,
      width: 450,
      btnList: [
        // { label: '配置', type: 'primary', eventName: 'configInfo' },
        // { label: '调用', type: 'primary', eventName: 'viewCall' },
        { label: '管理内容', type: 'primary', eventName: 'configInfo' },
        {
          label: '设为常驻',
          type: 'primary',
          showName: 'is_permanent',
          showStatusList: [-1],
          eventName: 'settingPermanent',
        },
        {
          label: '取消常驻',
          type: 'danger',
          showName: 'is_permanent',
          showStatusList: [1],
          eventName: 'settingPermanent',
        },
      ],
    },
  ]),
  // 按钮列表
  buttonList: Object.freeze([
    {
      label: '新增信息流',
      type: 'primary',
      icon: 'el-icon-plus',
      eventName: 'handleCreate',
    },
  ]),
  typeList: Object.freeze([
    { id: 1, label: '混合备选型', path: 'column' },
    { id: 2, label: '论坛聚合型', path: 'community' },
    { id: 3, label: '论坛文章', path: 'portal' },
    { id: 5, label: '轻分享聚合型', path: 'show' },
    { id: 6, label: '轻分享备选型', path: 'showColumn' },
  ]),
}

export default config
