<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="viewCall" label-width="80px" :model="query">
      <el-form-item label="客户端调用" prop="link">
        <el-input v-model.trim="query.link" readonly />
      </el-form-item>
      <el-form-item label="wap调用" prop="wap_url">
        <el-input v-model.trim="query.wap_url" readonly />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'ViewCallDialog',
    data() {
      return {
        query: {
          link: '',
          wap_url: '',
        },
        title: '调用链接',
        url: 'bmtt://infolist?cat_id=',
        dialogFormVisible: false,
      }
    },
    methods: {
      openDialog(row) {
        this.query.link = row.link
        this.query.wap_url = row.wap_url
        this.dialogFormVisible = true
      },
      close() {
        this.query = {
          link: '',
          wap_url: '',
        }
        this.dialogFormVisible = false
      },
    },
  }
</script>
