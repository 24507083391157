/** timestamp 转换 date
 * @param {Number} timeStamp
 */
export function timeFormat(timeStamp, hideTime, symbol) {
  const dt = new Date(timeStamp)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  symbol = symbol != undefined ? symbol : '-'
  if (hideTime) {
    return `${y}${symbol}${m}${symbol}${d}`
  }
  return `${y}${symbol}${m}${symbol}${d} ${hh}:${mm}:${ss}`
}
/**
 * 当前时间一个月有多少天
 * @param {number} num
 */
export function monthDay() {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = new Date(year, month, 0)
  return day.getDate()
}
/**
 * 10000 => '10,000'
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
/**
 * timestamp 转换 date
 * @param {Number} timeStamp
 * @param {Boolean} showTime
 */
export function dateFormat(timeStamp, showTime) {
  const dt = new Date(timeStamp * 1000)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  if (!showTime) {
    return `${y}-${m}-${d}`
  }
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
}

/**
 * timestamp 转换 date
 * @param {Number} timeStamp
 * @param {Boolean} showTime
 */
export function timeBetween(value, time) {
  if (!value) return
  value = value / 1000
  if (!time) time = new Date().getTime() / 1000
  const day = (value - time) / 60 / 60 / 24
  const h = ((value - time) / 60 / 60) % 24
  const m = ((value - time) / 60) % 60
  let text = ''
  if (parseInt(day) > 0) {
    text += parseInt(day) + '天'
  }
  if (parseInt(h) > 0) {
    text += parseInt(h) + '时'
  }
  if (parseInt(m) > 0) {
    text += parseInt(m) + '分'
  }
  return text
}
