import store from '@/store'

const config = store.getters['user/config']

export default function () {
  let auto_click = config.info_auto_click || 100
  let auto_click_up = auto_click * 1.2
  let auto_click_down = auto_click * 0.8
  let style = ''
  for (let i = 0; i < 5; i++) {
    if (style) {
      style += ',' + randomNum(auto_click_down, auto_click_up)
    } else {
      style = randomNum(auto_click_down, auto_click_up)
    }
  }
  return style
}
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10)
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    default:
      return 0
  }
}
