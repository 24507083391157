const config = {
  dialogVisible: false,
  link: null,
  defaultActive: 'component-bbs',
  defaultOpeneds: ['0', '1', '2'],
  // 表格最大高度
  maxHeight: document.body.clientHeight * 0.7 - 219,
  query: {
    id: null,
    keyword: null,
  },
  columnList: Object.freeze([
    { label: '标题', slotName: 'label', align: 'left' },
    {
      label: '操作',
      isButton: true,
      align: 'left',
      width: '120',
      btnList: [
        {
          label: '预览使用',
          type: 'text',
          eventName: 'previewUse',
        },
      ],
    },
  ]),
  searchColumn: Object.freeze([
    { label: 'ID', prop: 'id' },
    { label: '关键字', prop: 'keyword' },
  ]),
  searchColumn1: Object.freeze([
    {
      label: '关键字',
      prop: 'keyword',
      span: 22,
    },
  ]),
  apiList: Object.freeze({
    'component-circle': '/metatime/index/v1/indexAdmin/showContentJumpPage',
    'component-info': '/metatime/index/v1/indexAdmin/infoJumpPage',
    'component-activity': '/metatime/index/v1/indexAdmin/activityJumpPage',
    'component-topic': '/metatime/index/v1/indexAdmin/topicJumpPage',
    'component-panel': '/metatime/index/v1/indexAdmin/circleJumpPage',
    'component-special-thread': '/metatime/index/v1/indexAdmin/subjectJumpPage',
    'component-channel': '/metatime/index/v1/indexAdmin/channelJumpPage',
    'component-message': '/metatime/index/v1/indexAdmin/infoCatJumpPage',
    'component-user': '/metatime/index/v1/indexAdmin/userJumpPage',
    'component-group': '/metatime/index/v1/indexAdmin/chatGroupJumpPage',
    'component-tools': '/metatime/index/v1/indexAdmin/toolsList',
    'component-bbs': '/metatime/index/v1/indexAdmin/threadContentJumpPage',
    'component-video': '/metatime/index/v1/indexAdmin/videoJumpPage',
    'component-video-album': '/metatime/index/v1/indexAdmin/videoAlbumJumpPage',
    'component-video-column':
      '/metatime/index/v1/indexAdmin/videoColumnJumpPage',
  }),
  navList: Object.freeze([
    {
      name: '内容详情',
      children: true,
      subList: [
        {
          name: '论坛主题',
          view: 'component-bbs',
        },
        {
          name: '圈子内容',
          view: 'component-circle',
        },
        {
          name: '资讯',
          view: 'component-info',
        },
        {
          name: '活动',
          view: 'component-activity',
        },
        {
          name: '视频/直播',
          view: 'component-video',
        },
      ],
    },
    {
      name: '页面频道',
      children: true,
      subList: [
        {
          name: '话题首页',
          view: 'component-topic',
        },
        {
          name: '圈子/版块',
          view: 'component-panel',
        },
        {
          name: '专题',
          view: 'component-special-thread',
        },
        {
          name: '频道页',
          view: 'component-channel',
        },
        {
          name: '信息流',
          view: 'component-message',
        },
        {
          name: '视频专辑',
          view: 'component-video-album',
        },
        {
          name: '视频栏目',
          view: 'component-video-column',
        },
      ],
    },
    {
      name: '用户/群组',
      children: true,
      subList: [
        {
          name: '用户',
          view: 'component-user',
        },
        {
          name: '群组',
          view: 'component-group',
        },
      ],
    },
    {
      name: '工具功能',
      children: false,
      view: 'component-tools',
    },
    {
      name: '自定义链接',
      children: false,
      view: 'component-redefine',
    },
  ]),
}

export default config
