<template>
  <div class="add-community-container">
    <div class="add-community-title">基础配置</div>
    <Form :column="column1" :query="query1" ref-name="basic" />
    <el-divider />
    <div class="add-community-title">内容对接</div>
    <Form :column="column2" :query="query2" ref-name="basic" />
    <el-divider />
    <div class="add-community-btn">
      <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      <el-button size="medium" @click="cancel">取消</el-button>
      <el-button
        v-if="$route.query.id"
        size="medium"
        type="danger"
        @click="onDelete"
      >
        删除
      </el-button>
    </div>

    <el-dialog title="信息" :visible.sync="dialogVisible" width="20%">
      <span>是否确认删除该信息流？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Form from '@/components/Form'
  import { getForumList, catSave, catDelete } from '@/api/information'
  import config from './config'
  export default {
    name: 'Community',
    components: {
      Form,
    },
    props: {
      query1: {
        type: Object,
        default: () => ({}),
      },
      query2: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return config
    },
    computed: {
      column1() {
        return [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '信息流分组',
            prop: 'type',
            type: 'select',
            list: [
              {
                id: 2,
                label: '请选择',
              },
            ],
          },
          {
            label: '样式选择',
            prop: 'show_style',
            type: 'radio',
            list: this.styleSelectionList,
          },
          {
            label: '内容样式',
            prop: 'content_show_style',
            type: 'radio',
            list: this.contentStyleList,
            isHide: this.query1.show_style != 'weibo',
          },
          {
            label: '封面配置',
            prop: 'cover_set',
            type: 'radio',
            list: this.coverConfigList,
          },
          {
            label: '封面图样式配置',
            prop: 'cover_style',
            type: 'radio',
            list: this.coverStyleList,
          },
        ]
      },
      column2() {
        return [
          {
            label: '来源板块',
            prop: 'forum_from',
            type: 'select',
            multiple: true,
            list: this.forumList,
            optionLabel: 'name',
            optionValue: 'fid',
            tips: '选择内容来源版块，可多选',
          },
          {
            label: '排序方式',
            prop: 'sortord',
            type: 'select',
            list: this.sortList,
          },
          {
            label: '发布时间',
            prop: 'publish_time',
            type: 'select',
            list: this.timeList,
            tips: '<span>发布时间区间内内容过多会造成服务器压力，</span>请根据需求合理选择区间范围。',
          },
          {
            label: '互动时间',
            prop: 'interact_time',
            type: 'select',
            list: this.timeList,
          },
          {
            label: '是否精华',
            prop: 'forum_is_elite',
            type: 'radio',
            list: this.whetherList,
          },
        ]
      },
    },
    created() {
      this.getForumList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 保存
      onSubmit() {
        const {
          name,
          type,
          show_style,
          content_show_style,
          cover_set,
          cover_style,
        } = this.query1
        let params = {
          name,
          type,
          _config: {
            show_style,
            content_show_style,
            cover_set,
            cover_style,
            ...this.query2,
          },
        }
        params._config = JSON.stringify(params._config)
        if (this.$route.query.id) params.id = this.$route.query.id
        catSave(params).then(async (res) => {
          if (res.success === true) {
            await this.delVisitedRoute('/info/addcommunity')
            await this.delVisitedRoute('/info/addInformation')
            this.$router.push('/info/flowManage')
          } else this.$baseMessage(res.msg, 'error')
        })
      },
      // 获取来源板块
      getForumList() {
        var params = { step: 20 }
        getForumList(params).then((res) => {
          this.forumList = res.list
        })
      },
      async cancel() {
        await this.delVisitedRoute('/info/addcommunity')
        this.$router.push('/info/addInformation')
      },
      // 点击删除
      onDelete() {
        this.dialogVisible = true
      },
      onSure() {
        const params = { id: this.$route.query.id }
        catDelete(params).then(async (res) => {
          if (res.success) {
            this.dialogVisible = false
            await this.delVisitedRoute('/info/community')
            this.$router.push('/')
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-community';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 55px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-tips {
      color: $base-color-text-secondary;
    }
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
  }
</style>
