<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="viewCall" label-width="80px" :model="query">
      <el-form-item label="首页栏目" prop="client_link">
        <el-input v-model.trim="query.client_link" readonly />
        <p class="tips">调用此链接可以将该内容列表添加到首页各内容栏目</p>
      </el-form-item>
      <el-form-item label="引用链接" prop="wap_link">
        <el-input v-model.trim="query.wap_link" readonly />
        <p class="tips">调用此链接可以将该内容列表显示为独立页面</p>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'ViewCallDialog',
    data() {
      return {
        query: {
          client_link: '',
          wap_link: '',
        },
        title: '调用链接',
        url: 'bmtt://infolist?cat_id=',
        dialogFormVisible: false,
      }
    },
    methods: {
      openDialog(id) {
        this.query.client_link = this.url + id
        this.query.wap_link = this.url + id
        this.dialogFormVisible = true
      },
      close() {
        this.query = {
          client_link: '',
          wap_link: '',
        }
        this.dialogFormVisible = false
      },
    },
  }
</script>
