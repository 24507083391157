var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-show-container" },
    [
      _c("SearchForm", {
        attrs: {
          "btn-col": 0,
          "label-width": 0,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: {
          getTableData: _vm.getTableData,
          handleChangeStatus: _vm.handleChangeStatus,
          resetForm: _vm.resetForm,
        },
      }),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: { handleCurrentChange: _vm.handleCurrentChange },
        scopedSlots: _vm._u([
          {
            key: "other",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v("所在版块")]),
                _c("span", [
                  _vm._v(
                    _vm._s(row.circle.name ? row.circle.name : "后台发布")
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }