const config = {
  dialogVisible: false,
  showList: [],
  allTopicPageList: [],
  // 列表样式
  listStyleList: Object.freeze([
    {
      id: 'normal',
      label: '朋友圈样式',
    },
    {
      id: 'pintu',
      label: '拼图样式',
    },
    {
      id: 'kapian',
      label: '卡片样式/瀑布流样式',
    },
  ]),
  // 样式选择
  styleSelectionList: Object.freeze([
    {
      id: 1,
      label: '三图',
    },
    {
      id: 2,
      label: '九图',
    },
  ]),
  // 评论样式
  commentStyleList: Object.freeze([
    {
      id: 1,
      label: '神评优先',
    },
    {
      id: 2,
      label: '仅显示神评',
    },
  ]),
  // 排序方式
  sortList: Object.freeze([
    {
      id: 1,
      label: '默认排序',
    },
    {
      id: 2,
      label: '随机排序',
    },
  ]),
  // 内容范围
  contentScopeList: Object.freeze([
    {
      id: 300,
      label: '300',
    },
    {
      id: 500,
      label: '500',
    },
    {
      id: 1000,
      label: '1000',
    },
  ]),
  // 时间范围
  timeScopeList: Object.freeze([
    {
      id: 1,
      label: '过去一天',
    },
    {
      id: 7,
      label: '过去七天',
    },
    {
      id: 0,
      label: '自定义',
    },
  ]),
  // 是否
  whetherList1: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: 0,
      label: '否',
    },
  ]),
  whetherList2: Object.freeze([
    {
      id: 1,
      label: '是',
    },
    {
      id: -1,
      label: '否',
    },
  ]),
  // 保存数
  saveContentList: Object.freeze([
    {
      id: 500,
      label: '500',
    },
    {
      id: 800,
      label: '800',
    },
    {
      id: 1000,
      label: '1000',
    },
  ]),
  // 内容来源
  contentSourceList: Object.freeze([
    {
      id: 1,
      label: '轻分享圈',
    },
    {
      id: 2,
      label: '话题',
    },
  ]),
  // 时间
  timeList: Object.freeze([
    {
      id: 0,
      label: '不限',
    },
    {
      id: 1,
      label: '24小时内',
    },
    {
      id: 3,
      label: '3天内',
    },
    {
      id: 5,
      label: '5天内',
    },
    {
      id: 7,
      label: '7天内',
    },
    {
      id: 15,
      label: '15天内',
    },
    {
      id: 30,
      label: '30天内',
    },
  ]),
}

export default config
