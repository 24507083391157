<template>
  <div class="add-information-container">
    <div class="add-information-title">请选择信息流类型</div>
    <div class="add-information-info">
      新建频道，可扩展用户的活动范围，我们为您提供了以下频道类型，请选择符合您社区的类型进行创建！
    </div>
    <div v-for="(item, index) in addItemList" :key="index">
      <div class="add-information-item">
        <el-image class="add-information-item-image" :src="item.image" />
        <div class="add-information-item-content">
          <div class="add-information-item-content-first">{{ item.title }}</div>
          <div class="add-information-item-content-second">
            {{ item.desc }}
          </div>
        </div>
        <el-button size="medium" type="primary" @click="handleClickAdd(item)">
          创建
        </el-button>
      </div>
      <el-divider />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        addItemList: [
          {
            image: require('@/assets/images/information/info-icon-column.png'),
            title: '混合备选型',
            desc: '该类型可设置对接论坛版块、轻分享圈、公众号等，自动从中抓取符合设置条件的内容，放入后台进行备选显示，您可对这些内容正文、封面、标题进行二次编辑、调整显示排序',
            path: 'addcolumn',
          },
          {
            image: require('@/assets/images/information/info-icon-community.png'),
            title: '论坛聚合型',
            desc: '对接论坛版块，可多选，可设置排序规则，对这些内容正文、封面、标题进行二次编辑',
            path: 'addcommunity',
          },
          // {
          //   image: require('@/assets/images/information/info-icon-show.png'),
          //   title: '轻分享圈聚合型',
          //   desc: '对接轻分享圈，可多选，可设置排序规则，对这些内容正文、封面、标题进行二次编辑',
          //   path: 'addshow',
          // },
          // {
          //   image: require('@/assets/images/information/info-icon-portal.png'),
          //   title: '论坛文章',
          //   desc: '对接论坛门户文章',
          //   path: 'addportal',
          // },
          // {
          //   image: require('@/assets/images/information/info-icon-show-column.png'),
          //   title: '轻分享备选型',
          //   desc: '对接轻分享内容，可对内容正文、封面、标题进行二次编辑；可设置内容列表样式',
          //   path: 'addshowColumn',
          // },
        ],
      }
    },
    methods: {
      handleClickAdd(item) {
        this.$router.push({ path: `/info/${item.path}` })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.add-information';
  #{$base}-container {
    #{$base}-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-item {
      display: flex;
      align-items: center;
      padding: 24px 0;
      &-content {
        width: 100%;
      }
      &-image {
        width: 76px;
        height: 76px;
        margin-right: 20px;
        flex-shrink: 0;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
      }
    }
    .el-divider--horizontal {
      margin: 0;
    }
  }
</style>
