const config = {
  query: {
    status: 0,
    album_id: 0,
    column_id: 0,
    pay_status: 0,
    keywords: '',
    order: 'id desc',
  },
  nowTime: new Date().getTime() / 1000,
  // 表格最大高度
  maxHeight: document.body.clientHeight - 340,
  // 按钮列表
  buttonList: Object.freeze([
    {
      label: '发布内容',
      type: 'primary',
      icon: 'el-icon-plus',
      eventName: 'handleCreate',
    },
    {
      label: '移入专辑',
      type: 'primary',
      icon: 'el-icon-top-right',
      eventName: 'handleAlbum',
    },
    {
      label: '移入栏目',
      type: 'primary',
      icon: 'el-icon-top-right',
      eventName: 'handleColumn',
    },
    {
      label: '批量删除',
      type: 'danger',
      icon: 'el-icon-delete',
      eventName: 'handleRemove',
    },
  ]),
  // 状态
  statusList: Object.freeze([
    {
      id: 0,
      label: '请选择状态',
    },
    {
      id: 1,
      label: '已发布',
    },
    {
      id: 2,
      label: '已撤回',
    },
    {
      id: 3,
      label: '定时发布中',
    },
    {
      id: 4,
      label: '草稿',
    },
  ]),
  // 专辑
  albumOption: Object.freeze([
    {
      id: 0,
      name: '请选择所属专辑',
    },
    {
      id: -1,
      name: '暂未加入专辑',
    },
  ]),
  // 栏目
  columnOption: Object.freeze([
    {
      id: 0,
      name: '请选择所属栏目',
    },
    {
      id: -1,
      name: '暂未加入栏目',
    },
  ]),
  // 付费模式
  payStatusOption: Object.freeze([
    {
      id: 0,
      name: '请选择付费模式',
    },
    {
      id: 1,
      name: '免费',
    },
    {
      id: 2,
      name: '付费',
    },
  ]),
  // 排序方式
  sortOption: Object.freeze([
    {
      id: 'id desc',
      name: '全部',
    },
    {
      id: 'timing desc',
      name: '发布时间',
    },
    {
      id: 'sort_time desc',
      name: '排序时间',
    },
    {
      id: 'play_num desc',
      name: '播放数',
    },
    {
      id: 'praise_num desc',
      name: '点赞数',
    },
  ]),
}

export default config
